<template>

<div class="content profilePage" style="overflow: auto;">

    <div class="sidebar">
        <div class="profile-card">
            <div class="cover">
                <img :src="profileImage" alt="">
                <i class="far fa-plus" @click="addImage"></i>
                <input ref="fileInput" @change="handleImage" accept="image/*" type="file" style="display:none" />
            </div>
            <div class="detail">
                <h3>{{selectedSurvey.name}}</h3>
                <p>{{selectedSurvey.code}}</p>
            </div>
            <div class="menu">
                <label for="notifications">
                    <h5>Anketler</h5>
                    <label v-for="survey in surveys" :key="survey.id" @click="selectedSurvey=survey">
                        <input type="checkbox" :checked="survey.id == selectedSurvey.id" disabled>
                        <span></span>
                        <p>{{survey.name}}</p>
                    </label>
                </label>
            </div>
        </div>
    </div>
  
  <div class="main">
     
      
        <cddosonuc :cddodata="cddosonucjson" v-if="cddolength>0"  />
        <survey-group v-for="group in selectedSurvey.surveyGroups" :key="group.id" :group="group" />
 
    </div>

</div>
</template>

<script>

 
import Cddosonuc from "./Survey/Cddosonuc.vue"
import SurveyGroup from "./Survey/SurveyGroup.vue"
 

import axios from "axios";
const baseURL = process.env.NODE_ENV === 'production' ? 'https://api.uzaktanokulsistemi.com/v1/' : 'https://localhost:5001/v1/';
export default {
    components: {
        "survey-group": SurveyGroup,
        "cddosonuc": Cddosonuc
    },
    data() {
        return {
            cddosonucjson:{},
            cddolength:0,
            profileImage: null,
            surveys: [],
            isOpen: false,
            selectedSurvey: {}
        }
    },
   
    created() {
 

        this.getSurvey();
    },
    methods: {
        async getSurvey() {
            this.$center.emit('Loading', 'show');
            let config = {
                headers: {
                    'Authorization': 'Bearer ' + this.$route.query.token
                }
            }
            axios
                .get(baseURL + "Teacher/SurveyWatching/" + this.$route.params.id, config)
                .then(response => {
                    console.log(response);
                
                 
                   if(response.data.data.id=="ac8da40b-a015-42bc-ba25-ea0c6a77f728"){
                        this.selectedSurvey={}
                        this.cddosonucjson =response.data.data.surveyGroups[0]; 
                   }else{
                    this.selectedSurvey=response.data.data;
                   }     
                    this.cddolength=Object.keys(this.cddosonucjson).length;
                   
                     
                  
                   this.profileImage=this.selectedSurvey.profileUrl;
                    
                })
                .catch(error => {
                   console.log(error);
                }).finally(() => {
                    this.$center.emit('Loading', 'hide');
                }); 

        },
    }
}
</script>
