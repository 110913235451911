<template>
<div class="content reports">

    <div class="sidebar" style="left: 100%;" id="report-filter">
        <div class="reports-title">
            <i class="far fa-chevron-left" @click="openFilter()"></i>
            <h2>Tüm raporlarım</h2>
        </div>
        <div class="reports-filter">
            <ul>
                <li v-for="report in repors" :key="report.examId" v-on:click.stop="getDetails(report)">
                    {{report.name}}
                </li>
            </ul>
            <!-- <i class="fas fa-download" @click="printData()"></i> -->
        </div>
    </div>

    <div class="main" id="main">
        <div class="list-group" v-if="selectedExam!=null">
            <div class="reports-item">
                <table>
                    <tr>
                        <th>YKS PUANI</th>
                        <th>YKS KURUM SIRASI</th>
                        <th>YKS SINIF SIRASI</th>
                        <th>YKS GENEL SIRASI</th>
                    </tr>
                    <tr>
                        <td>{{selectedExam.point}}</td>
                        <td>{{selectedExam.institutionOrder}}</td>
                        <td>{{selectedExam.classOrder}}</td>
                        <td>{{selectedExam.generalOrder}}</td>
                    </tr>
                </table>
                <table>
                    <tr>
                        <th>SAYISAL PUANI</th>
                        <th>SAYISAL KURUM SIRASI</th>
                        <th>SAYISAL SINIF SIRASI</th>
                        <th>SAYISAL GENEL SIRASI</th>
                    </tr>
                    <tr>
                        <td>{{selectedExam.digitalPoint}}</td>
                        <td>{{selectedExam.digitalInstitutionOrder}}</td>
                        <td>{{selectedExam.digitalClassOrder}}</td>
                        <td>{{selectedExam.digitalGeneralOrder}}</td>
                    </tr>
                </table>
                <table>
                    <tr>
                        <th>EŞİT AĞIRLIK PUANI</th>
                        <th>EŞİT AĞIRLIK KURUM SIRASI</th>
                        <th>EŞİT AĞIRLIK SINIF SIRASI</th>
                        <th>EŞİT AĞIRLIK GENEL SIRASI</th>
                    </tr>
                    <tr>
                        <td>{{selectedExam.eaPoint}}</td>
                        <td>{{selectedExam.eaInstitutionOrder}}</td>
                        <td>{{selectedExam.eaClassOrder}}</td>
                        <td>{{selectedExam.eaGeneralOrder}}</td>
                    </tr>
                </table>
                <table>
                    <tr>
                        <th>SÖZEL PUANI</th>
                        <th>SÖZEL KURUM SIRASI</th>
                        <th>SÖZEL SINIF SIRASI</th>
                        <th>SÖZEL GENEL SIRASI</th>
                    </tr>
                    <tr>
                        <td>{{selectedExam.verbalPoint}}</td>
                        <td>{{selectedExam.verbalInstitutionOrder}}</td>
                        <td>{{selectedExam.verbalClassOrder}}</td>
                        <td>{{selectedExam.verbalGeneralOrder}}</td>
                    </tr>
                </table>
            </div>

            <div class="reports-item">
                <table>
                    <tr>
                        <th style="width: 25%;">DERS</th>
                        <th style="width: 15%;">DOĞRU</th>
                        <th style="width: 15%;">YANLIŞ</th>
                        <th style="width: 15%;">NET</th>
                    </tr>
                    <tr v-for="lesson in selectedExam.lessons" :key="lesson.name">
                        <td>{{lesson.name}}</td>
                        <td>{{lesson.correct}}</td>
                        <td>{{lesson.wrong}}</td>
                        <td>{{lesson.net}}</td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="list-group" v-else>
            <div class="reports-item">
                <table>
                    <tr>
                        <td>
                            <button>DETAYLARI GÖRMEK İÇİN YANDAN SINAV SEÇİMİ YAPINIZ</button>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="reports-item">
                <table>
                    <tr>
                        <td>
                            <button>RAPORU PDF OLARAK KAYDEDEBİLİR VEYA YAZDIRABİLİRSİNİZ</button>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>

    <div class="filter-open-button" @click="openFilter()">
        <i class="far fa-bars"></i>
    </div>

</div>
</template>

<script lang="tr">
export default {
    data() {
        return {
            repors: [],
            selectedExam: null,
            examId: null
        }
    },
    created() {
        this.getReports();
    },
    methods: {
        getReports() {
            this.$center.emit('Loading', 'show');
            this.$http.get("Student/ReportCard").then(x => {
                this.repors = x.data.data;
                this.$center.emit('Loading', 'hide');
            }).catch(error => {
                alert(error.response.data.error.message);
                this.$center.emit('Loading', 'hide');
            });
        },
        openFilter() {
            var sideBar = document.getElementById("report-filter");
            if (sideBar.style.left == "100%") {
                sideBar.style.left = "0";
            } else if (sideBar.style.left == "0px") {
                sideBar.style.left = "100%";
            }
        },
        getDetails(exam) {
            console.log(exam);
            this.selectedExam = null;
            this.$center.emit('Loading', 'show');
            this.$http.get("Student/ReportCard/" + exam.id).then(x => {
                this.examId = exam.id;
                this.selectedExam = x.data.data;
                this.$center.emit('Loading', 'hide');
            }).catch(error => {
                alert(error.response.data.error.message);
                this.$center.emit('Loading', 'hide');
            });
        },
        printData() {
            var printContents = document.getElementById("main").innerHTML;
            var a = window.open('', '', 'fullscreen=true');
            a.document.write('<html lang="tr" data-color="red" data-background="light" data-default="vertical">');
            a.document.write('<head><link href="css/normalize.css" rel="stylesheet"/> <link href="css/style.css" rel="stylesheet" /></head>');
            a.document.write('<body class="vertical mobile"><div style="width:100%"><div class="content reports">');
            a.document.write(printContents);
            a.document.write('</div></div></body></html>');
            a.document.close();
        }
    }
}
</script>

<style scoped>
button {
    width: calc(100% - 2em);
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    font-weight: 800;
    color: #ffffff;
    background-color: var(--main-color-1);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: var(--transition-15);
}
</style>
