<template>
<div class="item goals">
    <!-- <div class="title">
        <h2>🎯 Hedeflerim</h2>
    </div> -->
    <div class="con null" v-if="targets.length==0">
        <h3>{{$t("Home.Target.Title")}}</h3>
        <button @click="openAddTarget()">{{$t("Home.Target.Button")}}</button>
    </div>
    <div class="con" v-else>
        <div class="time-line-item" v-bind:class="target.isGoal?'active':null" v-for="(target,index) in targets" :key="target.title">
            <h3>{{target.isGoal?"Son Deneme Sınavı":target.title}}</h3>
            <h4>{{target.isGoal?target.title:target.definition}}</h4>
            <p>{{target.point.toFixed(2)}}</p>
            <span v-if="target.isGoal"><i class="fad fa-trophy-alt"></i></span>
            <span v-else>{{index+1}}</span>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            targets: []
        }
    },
    created() {
        this.getData();
    },
    methods: {
        openAddTarget() {
            this.$router.push({
                name: "AddTarget"
            });
        },
        async getData() {
            var targets = await this.$http.get("Student/Goals");
            this.targets = targets.data.data;
        },
    },
}
</script>
