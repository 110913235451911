<template>
<div class="content profilePage">

    <div class="sidebar">
        <div class="profile-card">
            <div class="cover">
                <img :src="profileImage" alt="">
                <i class="far fa-plus" @click="addImage"></i>
                <input ref="fileInput" @change="handleImage" accept="image/*" type="file" style="display:none" />
            </div>
            <div class="detail">
                <h3>{{selectedSurvey.name}}</h3>
                <p>{{selectedSurvey.code}}</p>
            </div>
            <div class="menu">
                <label for="notifications">
                    <h5>Anketler</h5>
                    <label v-for="survey in surveys" :key="survey.id" @click="selectedSurvey=survey">
                        <input type="checkbox" :checked="survey.id == selectedSurvey.id" disabled>
                        <span></span>
                        <p>{{survey.name}}</p>
                    </label>
                </label>
            </div>
        </div>
    </div>

    <div class="main">
        <survey-group v-for="group in selectedSurvey.surveyGroups" :key="group.id" :group="group" />
        <div class="profile-box">
            <div class="submit" style="flex-direction: row-reverse;">
                <button type="submit" style="margin-left: 1em;" @click="isOpen=true">Formu Gönder</button>
            </div>
        </div>
    </div>

    <Modal v-model="isOpen" :fullscreen="false" modal-transition="scale" :click-out="false" :disable-motion="false" max-width="500px" :remove-backdrop="false">
        <div class="theme-change">
            <div class="modal-header">
                <h3>Form Gönderme</h3>
            </div>
            <div class="con">
                <h4>Lütfen bütün alanları eksiksiz doldurduğunuzdan emin olun!</h4>
                <div class="horver">
                    <span class="active" @click="isOpen=false">Geri Dön</span>
                    <span class="active" @click="endSurvey()">Formu Bitir</span>
                </div>
            </div>
        </div>
    </Modal>

</div>
</template>

<script>
var emitter = require('tiny-emitter/instance');
import SurveyGroup from "./Survey/SurveyGroup.vue"
import { Modal } from "vue-neat-modal";
export default {
    components: {
        "survey-group": SurveyGroup,
        Modal
    },
    data() {
        return {
            profileImage: null,
            surveys: [],
            isOpen: false,
            isSelected: false,
            selectedSurvey: {}
        }
    },
    created() {
        this.getSurvey();
        this.profileImage = this.$cookies.get("UserImage");
    },
    methods: {
        addImage() {
            this.$refs.fileInput.click()
        },
        handleImage(e) {
            if (e.target.files == null)
                return;
            const selectedImage = e.target.files[0];
            var formData = new FormData();
            formData.append("file", selectedImage);
            this.$http.put("Auth/profileImage", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(x => {
                this.profileImage = x.data;
                this.isSelected = true;
                emitter.emit('ProfileImageChanged', x.data);
            })
        },
        async getSurvey() {
            this.$center.emit('Loading', 'show');
            var survey = await this.$http.get("Student/Surveys");
            this.surveys = survey.data.data;
            if (survey.data.data.length > 0) {
                this.selectedSurvey = this.surveys[0];
            }
            this.$center.emit('Loading', 'hide');
        },
        skipSurvey() {
            if (this.selectedSurvey.isMandotory) {
                this.$center.emit("alert", {
                    icon: 'fad fa-exclamation-circle',
                    title: "Anket Atlama",
                    description: "Zorunlu Anket Doldurmak Zorundasınız!",
                    type: 'danger'
                });
            }
        },
        async endSurvey() {
            if (this.isSelected == false) {
                this.$center.emit("alert", {
                    icon: 'fad fa-exclamation-circle',
                    title: "Form İşlemleri",
                    description: "Profil Resmi Yüklemek Zorundasınız.!",
                    type: 'danger'
                });
                return;
            }
            this.$center.emit('Loading', 'show');
            await this.$http.post("Student/EndSurvey", this.selectedSurvey);
            this.isOpen = false;
            this.$router.push({
                name: "Home"
            });
            this.$center.emit("alert", {
                icon: 'far fa-check',
                title: "Anket",
                description: "Bilgileriniz alındı onay verildiğinde sizleri bilgilendireceğiz!",
                type: 'success'
            });
            this.$center.emit('Loading', 'hide');
        }
    }
}
</script>
