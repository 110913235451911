<template>
<div class="question-answer-modal" style="display: flex;">
    <div class="top">
        <i class="fas fa-chevron-left"></i>
        <h5>Soru Gönder</h5>
    </div>
    <div class="conn">
        <div class="conn-item">
            <h6>Ders seçin:</h6>
            <ul>
                <li v-bind:class="addReplyRequest.lessonId==lesson.id ?'active' : null" v-for="lesson in lessons" :key="lesson.id" @click="addReplyRequest.lessonId=lesson.id">{{lesson.name}}</li>
            </ul>
        </div>
        <div class="conn-item">
            <h6>Öğretmen seçin:</h6>
            <ul>
                <li v-bind:class="addReplyRequest.teacherId==teacher.id ?'active' : null" v-for="teacher in teachers" :key="teacher.id" @click="addReplyRequest.teacherId=teacher.id">{{teacher.firstName}} {{teacher.lastName}}</li>
            </ul>
        </div>
        <div class="conn-item">
            <h6>Doğru Cevap:</h6>
            <ul>
                <li v-bind:class="addReplyRequest.answer==answer ?'active' : null" v-for="answer in answerS" :key="answer" @click="addReplyRequest.answer=answer">{{answer}}</li>
            </ul>
        </div>
        <div class="conn-item">
            <h6>Kısa açıklama (istege bağlı):</h6>
            <form>
                <textarea v-model="addReplyRequest.definition"></textarea>
            </form>
        </div>
        <div class="conn-item">
            <h6>Soru yükleyin</h6>
            <div class="conn-item-ul">
                <div class="conn-item-ul-li" @click="addImage">
                    <i class="far fa-plus"></i>
                    <input ref="fileInput" @change="handleImage" accept="image/*" type="file" style="display:none" />
                </div>
                <div class="conn-item-ul-li" v-if="imageBase64!=''">
                    <img :src="imageBase64" alt="">
                </div>
            </div>
        </div>
        <button @click="addReply()">Gönder</button>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            replys: [],
            lessons: [],
            teachers: [],
            answerS: ["A", "B", "C", "D", "E"],
            addReplyRequest: {
                lessonId: '',
                teacherId: '',
                answer: '',
                definition: '',
                file: ''
            },
            imageBase64: ''
        }
    },
    watch: {
        "addReplyRequest.lessonId": function (val) {
            this.getTeacher(val);
        }
    },
    mounted() {
        if (this.$cookies.get("Token") != null) {
            this.$http.get("Student/Lessons").then(x => {
                this.lessons = x.data.data;
            }).catch(error => {
                alert(error.response.data.error.message);
            });
        }
    },
    methods: {
        getBase64(file) {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            var scoped = this;
            reader.onload = function () {
                scoped.imageBase64 = reader.result;
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
            };
        },
        addImage() {
            this.$refs.fileInput.click()
        },
        handleImage(e) {
            if (e.target.files == null)
                return;
            this.addReplyRequest.file = e.target.files[0];
            this.getBase64(e.target.files[0]);
        },
        getTeacher(lessonId) {
            this.$http.get("Student/LessonTeacher/" + lessonId).then(x => {
                this.teachers = x.data.data;
            }).catch(error => {
                alert(error.response.data.error.message);
            });
        },
        addReply() {
            var formData = new FormData();
            this.$center.emit('Loading', 'show');
            formData.append("lessonId", this.addReplyRequest.lessonId);
            formData.append("teacherId", this.addReplyRequest.teacherId);
            formData.append("answer", this.addReplyRequest.answer);
            formData.append("definiton", this.addReplyRequest.definition);
            formData.append("file", this.addReplyRequest.file);
            this.$http.post("Student/AddReply", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(() => {
                this.$center.emit('SettingsOpen', 'close');
                this.$center.emit('ReplyAdded');
                this.$center.emit('Loading', 'hide');
            }).catch(() => {
                this.$center.emit('Loading', 'hide');
            })
        }
    }
}
</script>
