<template>
<div class="header" style="left: -300px">
    <div class="logo">
        <a href="#" v-if="appInfo.logoType=='image'">
            <img :src="appInfo.logo" alt="" />
        </a>
        <div class="title" v-if="appInfo.logoType=='text'">
            <h3>{{appInfo.name}}</h3>
        </div>
    </div>

    <div class="menu">
        <ul>
            <li v-for="navigation in navMenuItems" :key="navigation.route" v-bind:class="getCheckPage(navigation)">
                <a @click="openPage(navigation)">
                    <i v-bind:class="navigation.icon"></i>
                    <h4>{{$t(navigation.title)}}</h4>
                </a>
            </li>
        </ul>
    </div>

    <div class="profile">
        <a>
            <img :src="profileImage" alt="" />
        </a>
        <ul>
            <li id="btnLangue" @click="openPage({ route: 'Profile' })" v-if="profile">
                <i class="fas fa-address-card"></i>
                <h4>{{$t("Nav.Profile")}}</h4>
            </li>
            <li @click="$router.push({name:'Notification'})">
                <i class="fas fa-bell"></i>
                <h4>Bildirimler</h4>
            </li>
            <li @click="$router.push({name:'Badges'})" v-if="badges">
                <i class="fas fa-gem"></i>
                <h4>{{$t("Nav.Badge")}}</h4>
            </li>
            <li id="btnLangue" @click="openModal('Langue')">
                <i class="fad fa-language"></i>
                <h4>{{$t("Nav.Langue")}}</h4>
            </li>
            <li id="btnTheme" @click="openModal('Theme')">
                <i class="far fa-adjust"></i>
                <h4>{{$t("Nav.Theme")}}</h4>
            </li>
            <li @click="logOut()">
                <i class="fas fa-sign-out"></i>
                <h4>{{$t("Nav.Logout")}}</h4>
            </li>
        </ul>
    </div>
</div>
</template>

<script>
import navMenuItems from '../navigation.js'
var emitter = require('tiny-emitter/instance');
import appInfo from '@config';
export default {
    data() {
        return {
            navMenuItems,
            profileImage: '',
            userData:'',
            appInfo,
            badges:true,
            profile:true
        }
    },
    created() {
        this.profileImage = this.$cookies.get("UserImage");
        this.userData = this.$cookies.get("UserData");
        //todo if 0 
        if(typeof(this.userData.branch.studentMenus)!="undefined" &&  this.userData.branch!=""&& this.userData.branch.studentMenus!="0" && this.userData.branch.studentMenus!="" ){
            console.log("1");
            console.log(this.userData);
          
            var studentMenus= JSON.parse( this.userData.branch.studentMenus);
            this.navMenuItems = this.navMenuItems.filter(x=> studentMenus[x.route]!=false);
            this.badges=studentMenus.Badges ?? true;
            this.profile = studentMenus.Profile ?? true;
        }
         
        emitter.on('ProfileImageChanged', (url) => {
            this.changeProfileImage(url);
        });
    },
    methods: {
        changeProfileImage(url) {
            this.profileImage = url;
            this.$cookies.set("UserImage", url, "30D");
        },
        openPage(page) {
            this.$router.push({
                name: page.route
            });
            emitter.emit('MenuOpen', 'close');
        },
        getCheckPage(page) {
            if (page.route == this.$route.name) {
                return "selected";
            }
            return "";
        },
        logOut() {
            this.$cookies.remove('Token');
            this.$cookies.set('UserData');
            this.$router.push({
                name: "Login"
            });
        },
        openModal(modal) {
            emitter.emit('SettingsOpen', modal);
        }
    }
}
</script>
