<template>
<div class="auth">

    <div class="logo">
        <a href="#" v-if="appInfo.logoType=='image'">
            <img :src="appInfo.logo" alt="" />
        </a>
        <div class="title" v-if="appInfo.logoType=='text'">
            <h3>{{appInfo.name}}</h3>
        </div>
    </div>

    <div class="splash-screen">
        <img src="img/recovery.svg" alt="">
    </div>

    <div class="area">

        <div class="top">
            <h3>Şifreyi Sıfırla 🔒</h3>
            <p>Yeni şifreniz daha önce kullanılan şifrelerden farklı olmalıdır.</p>
        </div>

        <div class="box">
            <form>
                <label>
                    <h5>Yeni şifre</h5>
                    <input type="mail" placeholder="Yeni şifrenizi buraya girin">
                </label>
                <label>
                    <h5>Yeni şifre (tekrarla)</h5>
                    <input type="mail" placeholder="Yeni şifrenizi tekrar girin">
                </label>
                <button type="submit">Şifreyi Belirle</button>
            </form>
        </div>

    </div>

</div>
</template>

<script>
import appInfo from '@config';
export default {
    data() {
        return {
            appInfo
        }
    }
}
</script>
