<template>
<div class="content">
    <div class="main"  style="
 
  grid-template-columns: 50% 50%;" >
        <user-target id="v-step-0"  style="grid-column-start: 1;
  grid-column-end: 3;"/>
 
 
        <user-main   class="item working  "  style="      max-height: 600px;; height:auto;"/>
        <user-order   class="item working   "  style="    max-height: 600px;;height:auto; " />  
 
        
       
        <friends id="v-step-3"  style="  grid-column-start: 1;
  grid-column-end: 3;height:auto;" />

    </div>
    <div class="sidebar">
        <last-exam id="v-step-1" />
        <div class="item countdown" id="v-step-4">
            <div class="title">
                <h2>{{ $t("Home.Countdown.Title") }}</h2>
            </div>
            <div class="con">
                <div class="countdown-item" v-for="count in counter" :key="count.title">
                    <div class="countdown-title">
                        <h3>{{ count.title}}</h3>
                    </div>
                    <div class="countdown-time">
                        <ul>
                            <li>
                                <p>{{count.day}}</p>
                                <span>{{ $t("Home.Countdown.Day") }}</span>
                            </li>
                            <li>
                                <p>{{count.hour}}</p>
                                <span>{{ $t("Home.Countdown.Hour") }}</span>
                            </li>
                            <li>
                                <p>{{count.minute}}</p>
                                <span>{{ $t("Home.Countdown.Minute") }}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <live-lesson id="v-step-5" />

    </div>

    <v-tour name="myTour" :steps="steps" :options="{labels:{buttonSkip:'Turu Bitir',buttonNext:'İleri',buttonPrevious:'Geri',buttonStop:'Bitir'}}"></v-tour>
</div>
</template>

<script>
import Goals from "./Home/Goals"
import LiveLesson from "./Home/LiveLesson"
import LastExam from "./Home/LastExam"
import Friends from "./Home/Friends"
import Main from "./Home/Main"
import Order from "./Home/Order"
export default {
    components: {
        "user-target": Goals,
        "live-lesson": LiveLesson,
        "last-exam": LastExam,
        "friends": Friends,
        "user-main": Main,
        "user-order": Order
    },
    data() {
        return {
            counter:[],
            timer: null,
            tyt: {
                day: 0,
                hour: 0,
                minute: 0
            },
            ayt: {
                day: 0,
                hour: 0,
                minute: 0
            },
            steps: [{
                    target: '#v-step-0', // We're using document.querySelector() under the hood
                    content: `Buradan hedeflerini ekleyebilir.Hedeflerine ne kadar yaklaştığını görebilirsin!`
                },
                {
                    target: '#v-step-1',
                    content: 'Buradan son deneme sınavı\'na ait doğru,yanlış bilgilerini görebilirsin.!'
                },
                {
                    target: '#v-step-2',
                    content: 'Buradan çalışacağın dersleri görebilir,derslerdeki ilerleme durumlarına bakabilirsin!'
                },
                {
                    target: '#v-step-3',
                    content: 'Burada günlük arkadaşlarının neler yaptığını görebilirsin.'
                },
                {
                    target: '#v-step-4',
                    content: 'Buradan TYT,AYT sınavlarına kalan süreleri görüp,o büyük güne hazılanabilirsin.'
                },
                {
                    target: '#v-step-5',
                    content: 'Buradan Canlı dersleri görebilir,zamanı gelen canlı derse katılabilirsin.'
                }
            ]
        }
    },
    methods: {
        async getCountDown() {
            var timer = await this.$http.get("Student/CountDown");
            var data = timer.data.data;

            this.counter= data;

            this.tyt = {
                day: parseInt(data.tytday),
                hour: parseInt(data.tythour),
                minute: parseInt(data.tytminute)
            };
            this.ayt = {
                day: parseInt(data.aytday),
                hour: parseInt(data.aythour),
                minute: parseInt(data.aytminute)
            };
        }
    },
    mounted() {
        var scoped = this;
        scoped.getCountDown();
        this.timer = setInterval(function () {
            scoped.getCountDown();
        }, 60000);
        //this.$tours['myTour'].start()
    },
    unmounted() {
        clearInterval(this.timer);
    }
}
</script>
<style scoped>
@media screen and (min-width:1200px) {
    .main{
        display: grid  ;
        
    }
 
}
</style>