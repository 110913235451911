<script>
import { defineComponent } from 'vue'
import { Bar  } from 'vue3-chart-v2'

export default defineComponent({
    props: ["label", "otherData", "myData", "text", "successColor", "errorColor"],
    name: 'MonthlyChart',
    extends: Bar,
    watch: {
        reRender: function () {
            this.renderData();
        }
    },
    mounted() {
        var options = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                title: {
                    display: true,
                    text: 'Chart.js Line Chart'
                },
            },
            legend: {
                display: true,
                position: 'bottom'
            },
            scales: {
                xAxes: [{
                    display: false,
                    stacked: true
                }]
            }
        };

        this.renderChart({
            labels: this.label,
            datasets: [{
                label: 'Genel ' + this.text + ' Ortalaması',
                data: this.otherData,
                fill: false,
                backgroundColor: this.successColor,
                tension: 0.1,
            }, {
                label: this.text + ' Sayın',
                data: this.myData,
                fill: false,
                backgroundColor: this.errorColor,
                tension: 0.1,
            }]
        }, options);

    },
})
</script>
