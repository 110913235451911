<template>
  <div class="auth">
    <div class="logo">
      <a href="#" v-if="appInfo.logoType == 'image'">
        <img :src="appInfo.logo" alt="" />
      </a>
      <div class="title" v-if="appInfo.logoType == 'text'">
        <h3>{{ appInfo.name }}</h3>
      </div>
    </div>

    <div class="splash-screen">
      <img src="img/login.svg" alt="" />
    </div>

    <div class="area">
      <div class="top">
        <h3>{{ appInfo.name }} Hoş geldiniz 👋</h3>
        <p>Lütfen kurum tarafından verilen bilgilerinizle oturum açın!</p>
      </div>

      <div class="box">
        <form @submit="onLogin">
          <label>
            <h5>Kullanıcı Adı</h5>
            <input
              type="text"
              v-model="logReq.loginKey"
              placeholder="Kullanıcı adınızı girin"
            />
          </label>
          <label>
            <h5>Şifre</h5>
            <input
              type="password"
              v-model="logReq.password"
              placeholder="Şifrenizi girin"
            />
          </label>
          <!-- <label>
            <p>
                        <router-link to="forgot-password" tag="a">Şifremi Unuttum</router-link>
                    </p>
          </label> -->
          <button type="submit">Oturum Aç</button>
        </form>
      </div>

      <!-- <div class="bottom">
            <p>
                Hesabınız yoksa
                <a href="register.html">buradan</a> oluşturabilirsiniz.
            </p>
        </div> -->
    </div>
  </div>
</template>

<script>
var emitter = require("tiny-emitter/instance");
import appInfo from "@config";

import firebase from "firebase";
import "firebase/messaging";

export default {
  data() {
    return {
      logReq: {
        loginKey: "",
        password: "",
      },
      appInfo,
    };
  },
  methods: {
    onLogin(e) {
      this.$center.emit("Loading", "show");
      this.$http
        .post("Auth/login", this.logReq)
        .then((x) => {
          this.$cookies.set("Token", x.data.data.token, "30D");
          delete x.data.data.branch.users;
          x.data.data.user.branch = x.data.data.branch;
          this.$cookies.set("UserData", x.data.data.user, "30D");
          this.$cookies.set("UserImage", x.data.data.user.profileImage, "30D");

          console.log(firebase.messaging.isSupported())
          if (firebase.messaging.isSupported()) {
            firebase
              .messaging()
              .requestPermission()
              .then(() => {
                return firebase
                  .messaging()
                  .getToken()
                  .then(async (token) => {
                    await this.$http.put("Auth/Device", {
                      token: token,
                      osType: "Web",
                    });
                    fetch(
                      "https://iid.googleapis.com/iid/v1/" +
                        token +
                        "/rel/topics/general",
                      {
                        method: "POST",
                        headers: new Headers({
                          Authorization:
                            "key=AAAAw4j1o9M:APA91bEakyfnPpcA8ew8AXSktJKgyp40HHB3tRw4cPRWozMiXVdbWcjKGOiP6J3sqvzzLc_LuToO8kQLyClmSbDolbmI2jsgwMpgjvvcCgsj1vdk85sqGXmWUKKJKrSO25Jg7vD8wg2_",
                        }),
                      }
                    )
                      .then((response) => {
                        if (response.status < 200 || response.status >= 400) {
                          throw (
                            "Error subscribing to topic: " +
                            response.status +
                            " - " +
                            response.text()
                          );
                        }
                      })
                      .catch((error) => {
                        console.error(error);
                      });
                  })
                  .catch((err) => {
                    console.log("Error", err);
                  });
              });
          }

          window.ym(75526678, "userParams", {
            userId: x.data.data.user.id,
            firstName: x.data.data.user.firstName,
            lastName: x.data.data.user.lastName,
            email: x.data.data.user.email,
          });

          this.$analytics.setUserId(x.data.data.user.email);
          this.$analytics.setUserProperties(x.data.data.user);
          this.$center.emit("Loading", "hide");
          // this.$router.push({ name: "Home" });
          this.$center.emit("LangueChanged");
          this.$center.emit("ThemeChanged");
          window.location="/";
          // location.reload();
        })
        .catch((error) => {
          this.$center.emit("Loading", "hide");
          if (error.response) {
            emitter.emit("alert", {
              icon: "fas fa-exclamation-circle",
              title: "Hatalı Giriş",
              description: error.response.data.error.message,
              type: "danger",
            });
          }
        });
      e.preventDefault();
    },
  },
};
</script>
