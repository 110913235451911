<template>
<div v-bind:class="css_class">
    <h5>{{$t("Exam.FilterTitle")}}</h5>
    <h5>Dersler</h5>
    <select v-model="selectedData.lesson" @change="getChildLesson">
        <option :value="0">Tümü</option>
        <option :value="lesson.id" v-for="lesson in lessons" :key="lesson.id">{{lesson.name}}</option>
    </select>
    <h5>Alt Dersler</h5>
    <select v-model="selectedData.childLesson" @change="getTopic">
        <option :value="0">Tümü</option>
        <option :value="lesson.id" v-for="lesson in childLessons" :key="lesson.id">{{lesson.name}}</option>
    </select>
    <h5>Konular</h5>
    <select v-model="selectedData.topic" @change="getVideoGroup">
        <option :value="0">Tümü</option>
        <option :value="topic.id" v-for="topic in topics" :key="topic.id">{{topic.name}}</option>
    </select>
    <h5>Video Grupları</h5>
    <select v-model="selectedData.videoGroup">
        <option :value="0">Tümü</option>
        <option :value="vdGrp.id" v-for="vdGrp in videoGroups" :key="vdGrp.id">{{vdGrp.name}}</option>
    </select>
</div>
<button type="submit" @click="filterExecute('url')">{{$t("Exam.FilterButton")}}</button>
</template>

<script>
export default {
    props: ['css_class', 'changeUrl'],
    data() {
        return {
            lessons: [],
            childLessons: [],
            topics: [],
            videoGroups: [],
            selectedData: {
                lesson: 0,
                childLesson: 0,
                topic: 0,
                videoGroup: 0
            }
        }
    },
    created() {
        this.getLessons();
    },
    methods: {
        executeFilterUrl() {
            console.log("Data Data");
            this.getFilterDataCallMethod(this.lessons, "lesson", "getChildLesson");
        },
        getLessons() {
            this.$http.get("Student/Lessons").then(x => {
                this.lessons = x.data.data;
            }).catch(error => {
                alert(error.response.data.error.message);
            });
        },
        getChildLesson() {
            this.clearChildLesson();
            if (this.selectedData.lesson != 0) {
                this.$http.get("Student/ChildLessons/" + this.selectedData.lesson).then(x => {
                    this.childLessons = x.data.data;
                    this.getFilterDataCallMethod(this.childLessons, "childLesson", "getTopic");
                }).catch(error => {
                    //alert(error);
                });
            }
        },
        getTopic() {
            this.clearTopic();
            if (this.selectedData.childLesson != 0) {
                this.$http.get("Student/Topics/" + this.selectedData.childLesson).then(x => {
                    this.topics = x.data.data;
                    this.getFilterDataCallMethod(this.topics, "topic", "getVideoGroup");
                }).catch(error => {
                    //alert(error);
                });
            }
        },
        getVideoGroup() {
            this.clearVideoGroup();
            if (this.selectedData.topic != 0) {
                this.$http.get("Student/VideoGroup/" + this.selectedData.topic).then(x => {
                    this.videoGroups = x.data.data;
                    this.getFilterDataCallMethod(this.videoGroups, "videoGroup", "filterExecute");
                }).catch(error => {
                    //alert(error);
                });
            }
        },
        getFilterDataCallMethod(data, filterKey, method) {
            var queryFilter = this.$route.query[filterKey];
            console.log(filterKey, queryFilter);
            if (queryFilter != undefined) {
                var filterData = data.filter(x => x.id == queryFilter);
                if (filterData.length > 0) {
                    this.selectedData[filterKey] = filterData[0].id;
                    if (method != "filterExecute")
                        this.filterExecute('change');
                    this[method]();
                }
            }
        },
        filterExecute(type) {
            this.$emit("FilterExecute", this.selectedData);
            if (type != "change")
                this.urlReplace();
        },
        urlReplace() {
            if (this.changeUrl != false)
                this.$router.replace({ query: this.selectedData });
        },
        clearChildLesson() {
            this.selectedData.childLesson = 0;
            this.childLessons = [];
            this.clearTopic();
        },
        clearTopic() {
            this.selectedData.topic = 0;
            this.topics = [];
            this.clearVideoGroup();
        },
        clearVideoGroup() {
            this.selectedData.videoGroup = 0;
            this.videoGroups = [];
        }
    }
}
</script>
