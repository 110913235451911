<template>
<div class="content quizzes missing">
    <div class="sidebar" style="left: 100%;" id="missing-filter">
        <div class="quizzes-title">
            <i class="far fa-chevron-left" @click="openFilter()"></i>
            <h2>Eksiklerime dön</h2>
        </div>
        <div class="quizzes-filter">
            <filter-area ref="filterComp" css_class="select-box" @FilterExecute="filterExecute" />
        </div>
    </div>

    <div class="main">
        <div class="list-group">

            <div class="missing-item" v-for="lack in lacks" :key="lack.lackId" @click="openLack(lack)">
                <button>{{lack.text}}</button>
            </div>

        </div>
    </div>

    <div class="filter-open-button">
        <i class="far fa-filter" @click="openFilter()"></i>
    </div>

</div>
</template>

<script>
import FilterComponent from "../components/FilterComponent.vue"
export default {
    components: {
        "filter-area": FilterComponent
    },
    data() {
        return {
            lacks: [],
            oldLacks: []
        }
    },
    created() {
        this.getData();
    },
    methods: {
        async getData() {
            this.$center.emit('Loading', 'show');
            var lacks = await this.$http.get("Student/Lacks");
            this.lacks = lacks.data.data;
            this.oldLacks = lacks.data.data;
            this.$refs.filterComp.executeFilterUrl();
            this.$center.emit('Loading', 'hide');
        },
        async openLack(lack) {
            var hms = lack.time;
            var a = hms.split(':');
            var seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
            await this.$http.put("Student/EndLack/" + lack.lackId);
            this.$router.push('/watching/' + lack.topicId + '/' + lack.videoGroupId + '/video/' + lack.videoId + '?time=' + seconds);
        },
        openFilter() {
            var sideBar = document.getElementById("missing-filter");
            console.log(sideBar.style.left);
            if (sideBar.style.left == "100%") {
                sideBar.style.left = "0";
            } else if (sideBar.style.left == "0px") {
                sideBar.style.left = "100%";
            }
        },
        filterExecute(filter) {
            this.$center.emit('Loading', 'show');
            var filteredData = this.oldLacks;
            if (filter.lesson != 0)
                filteredData = filteredData.filter(x => x.lessonId == filter.lesson);
            if (filter.childLesson != 0)
                filteredData = filteredData.filter(x => x.childLessonId == filter.childLesson);
            if (filter.topic != 0)
                filteredData = filteredData.filter(x => x.topicId == filter.topic);
            if (filter.videoGroup != 0)
                filteredData = filteredData.filter(x => x.videoGroupId == filter.videoGroup);
            this.lacks = filteredData;
            this.$center.emit('Loading', 'hide');
        },
    }
}
</script>
