<template>
<div class="content profilePage">

    <div class="sidebar">
        <div class="profile-card">
            <div class="cover">
                <img :src="profileImage" alt="">
                <i class="far fa-plus" @click="addImage" v-if="isProfileChange"></i>
                <input ref="fileInput" @change="handleImage" accept="image/*" type="file" style="display:none" />
            </div>
            <div class="detail">
                <h3>{{user.firstName}} {{user.lastName}}</h3>
                <p>{{ companyName }}</p>
                <p>{{ userClass.parentClass?.name }} | {{ userClass.name }}</p>
            </div>
            <div class="menu">
                <label for="notifications">
                    <h5>Bildirim Seçenekleri</h5>
                    <label for="men">
                        <input type="checkbox" id="men" name="notifications" v-model="user.campaignPhone">
                        <span></span>
                        <p>Telefon Bildirimleri</p>
                    </label>
                    <label for="woman">
                        <input type="checkbox" id="woman" name="notifications" v-model="user.campaignSms">
                        <span></span>
                        <p>SMS Bildirimleri</p>
                    </label>
                    <label for="none">
                        <input type="checkbox" id="none" name="notifications" v-model="user.campaignEmail">
                        <span></span>
                        <p>E-posta Bildirimleri</p>
                    </label>
                </label>
            </div>

        </div>
    </div>

    <div class="main">
        <div class="profile-box">
            <div class="title">
                <h3>Kişisel Bilgiler</h3>
            </div>
            <form action="">
                <label>
                    <h5>TC Kimlik Numarası</h5>
                    <input type="text" placeholder="TC kimlik numaranızı girin" v-model="user.idNumber">
                </label>
                <label>
                    <h5>İsim</h5>
                    <input type="text" placeholder="Adınızı girin" v-model="user.firstName">
                </label>
                <label>
                    <h5>Soyisim</h5>
                    <input type="text" placeholder="Soyadınızı girin" v-model="user.lastName">
                </label>
                <label>
                    <h5>E-posta Adresi</h5>
                    <input type="email" placeholder="E-posta adresinizi girin" v-model="user.email">
                </label>
                <label>
                    <h5>Telefon Numarası</h5>
                    <input type="tel" placehoWlder="Telefon numaranızı girin 0 olmadan" v-model="user.gsmNumber">
                </label>
            </form>
            <div class="submit">
                <button type="submit">Ayarları Kaydet</button>
            </div>
        </div>
        <div class="profile-box">
            <div class="title">
                <h3>Şifre Güncelle</h3>
            </div>
            <form action="">
                <label>
                    <h5>Şifre</h5>
                    <input type="password" placeholder="Şifrenizi girin" v-model="passwordRequest.password">
                </label>
                <label>
                    <h5>Şifre Tekrar</h5>
                    <input type="password" placeholder="Şifrenizi tekrar girin" v-model="passwordRequest.rePassword">
                </label>
            </form>
            <div class="submit">
                <button type="submit" @click="updatePassword">Ayarları Kaydet</button>
            </div>
        </div>
        <div class="profile-box">
            <div class="title">
                <h3>Adres Bilgileri</h3>
            </div>
            <form action="">
                <label>
                    <h5>Şehir</h5>
                    <input type="text" placeholder="İkametgah şehrinizi girin" v-model="address.city">
                </label>
                <label>
                    <h5>İlçe</h5>
                    <input type="text" placeholder="İkametgah ilçenizi girin" v-model="address.district">
                </label>
                <label>
                    <h5>Mahalle</h5>
                    <input type="text" placeholder="İkametgah mahallenizi girin" v-model="address.neighborhood">
                </label>
                <label>
                    <h5>Sokak</h5>
                    <input type="text" placeholder="İkametgah sokağınızı girin" v-model="address.street">
                </label>
                <label>
                    <h5>Dışkapı No</h5>
                    <input type="number" placeholder="İkametgah dışkapı numaranızı girin" v-model="address.outNumber">
                </label>
                <label>
                    <h5>İçkapı No</h5>
                    <input type="number" placeholder="İkametgah içkapı numaranızı girin" v-model="address.inNumber">
                </label>
            </form>
            <div class="submit">
                <button type="submit" @click="saveAddress">Ayarları Kaydet</button>
            </div>
        </div>
        <div class="profile-box">
            <div class="title">
                <h3>Okul Bilgileri</h3>
            </div>
            <form action="">
                <label>
                    <h5>İl</h5>
                    <input v-model="currentSchool.city" type="text" placeholder="Okulun bulunduğu şehri girin">
                </label>
                <label>
                    <h5>İlçe</h5>
                    <input v-model="currentSchool.state" type="text" placeholder="Okulun bulunduğu ilçeyi girin">
                </label>
                <label>
                    <h5>Adı</h5>
                    <input v-model="currentSchool.name" type="text" placeholder="Okulun adını girin">
                </label>
                <label>
                    <h5>Sınıfı</h5>
                    <input v-model="currentSchool.class" type="text" placeholder="Sınıfınızı girin">
                </label>
                <label>
                    <h5>Bölümü</h5>
                    <input v-model="currentSchool.section" type="text" placeholder="Bölümünüzü girin">
                </label>
                <label>
                    <h5>Okul Puanı</h5>
                    <input v-model="currentSchool.point" type="number" placeholder="Okul puanınızı girin">
                </label>
            </form>
            <div class="submit">
                <button type="submit" @click="saveSchool">Ayarları Kaydet</button>
            </div>
        </div>
        <!-- <div class="profile-box">
            <div class="title">
                <h3>Bildirim Ayarları</h3>
            </div>
            <form class="notifications-settings" action="">
                <label class="notifications-settingss" for="notifications">
                    <label for="men">
                        <input type="checkbox" id="men" name="notifications" checked>
                        <span></span>
                        <p>Yeni Sınav Bildirimleri</p>
                    </label>
                    <label for="woman">
                        <input type="checkbox" id="woman" name="notifications">
                        <span></span>
                        <p>Canlı Ders Bildirimleri</p>
                    </label>
                    <label for="none">
                        <input type="checkbox" id="none" name="notifications">
                        <span></span>
                        <p>Sınav Sonucu Bildirimleri</p>
                    </label>
                    <label for="none">
                        <input type="checkbox" id="none" name="notifications">
                        <span></span>
                        <p>Takvim Değişiklik Bildirimleri</p>
                    </label>
                    <label for="none">
                        <input type="checkbox" id="none" name="notifications">
                        <span></span>
                        <p>Rehberlik Bildirimleri</p>
                    </label>
                </label>
            </form>
        </div> -->
    </div>

</div>
</template>

<script>
var emitter = require('tiny-emitter/instance');
import appInfo from '@config';
export default {
    data() {
        return {
            profileImage: 'https://api.uzaktanokulsistemi.com/User/default.jpg',
            user: {},
            userClass:{},
            address: {
                city: '',
                district: '',
                neighborhood: '',
                street: '',
                outNumber: 0,
                inNumber: 0
            },
            currentSchool:{
                city:"",
                state:"",
                name:"",
                class:"",
                section:"",
                point:0
            },
            passwordRequest: {
                password: '',
                rePassword: ''
            },
            companyName : appInfo.name,
            isProfileChange: true
        }
    },
    created() {
        this.getProfile();

         const userData = this.$cookies.get("UserData");
          if(typeof(userData.branch.studentMenus)!="undefined" &&  userData.branch!=""&& userData.branch.studentMenus!="0" && userData.branch.studentMenus!="" )
          {
            var studentMenus= JSON.parse(userData.branch.studentMenus);
            console.log("Menus",studentMenus);
            this.isProfileChange = studentMenus.ChangeProfile ?? true;
        }
    },
    methods: {
        addImage() {
            this.$refs.fileInput.click()
        },
        handleImage(e) {
            if (e.target.files == null)
                return;
            const selectedImage = e.target.files[0];
            var formData = new FormData();
            formData.append("file", selectedImage);
            this.$http.put("Auth/profileImage", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(x => {
                this.profileImage = x.data;
                emitter.emit('ProfileImageChanged', x.data);
            })
        },
        async getProfile() {
            this.$center.emit('Loading', 'show');
            var profile = await this.$http.get("Auth/profile");
            var profileResponse = profile.data.data;
            this.user = profileResponse.user;
            this.userClass = profileResponse.class;
            if(this.user.profileImage!=null)
                this.profileImage = this.user.profileImage;
            this.address = profileResponse.address == null ? {
                city: '',
                district: '',
                neighborhood: '',
                street: '',
                outNumber: null,
                inNumber: null
            } : profileResponse.address;

            if(profileResponse.currentSchool!=null)
                this.currentSchool=profileResponse.currentSchool;
            this.$center.emit('Loading', 'hide');
        },
        saveAddress() {
            if(this.address.outNumber==null||this.address.city==""||this.address.district==""||this.address.inNumber==null){
                this.$center.emit('alert', {
                    icon: "AlertIcon",
                    title: "İşlem Hatalı",
                    description: "Adres Bilgisi Eksiksiz Girilmelidir!",
                    type: 'error'
                });
                this.$center.emit('Loading', 'hide');
                return;
            }
            this.address.outNumber = parseInt(this.address.outNumber);
            this.address.inNumber = parseInt(this.address.inNumber);
            this.$center.emit('Loading', 'show');
            this.$http.put("Auth/address", this.address).then(() => {
                this.$center.emit('alert', {
                    icon: "AlertIcon",
                    title: "İşlem Başarılı",
                    description: "Adres Bilgisi Güncellendi.",
                    type: 'success'
                });
                this.$center.emit('Loading', 'hide');
            });
        },
        saveSchool() {
            this.currentSchool.point = parseFloat(this.currentSchool.point);
            this.$center.emit('Loading', 'show');
            this.$http.put("Auth/current_school", this.currentSchool).then(() => {
                this.$center.emit('alert', {
                    icon: "AlertIcon",
                    title: "İşlem Başarılı",
                    description: "Okul Bilgisi Güncellendi.",
                    type: 'success'
                });
                this.$center.emit('Loading', 'hide');
            });
        },
        updatePassword() {
            // if (this.passwordRequest.password != this.passwordRequest.rePassword) {
            //     this.$center.emit("alert", {
            //         icon: 'fas fa-exclamation-triangle',
            //         title: "Şifre İşlemleri",
            //         description: "Şifreler eşleşmiyor",
            //         type: 'danger'
            //     });
            // }
            this.$center.emit('Loading', 'show');
            this.$http.put("Auth/RePassword", this.passwordRequest).then(() => {
                this.$center.emit('alert', {
                    icon: "fas fa-check-circle",
                    title: "İşlem Başarılı",
                    description: "Şifre Güncellendi.",
                    type: 'success'
                });
                this.$center.emit('Loading', 'hide');
            }).catch(error => {
                this.$center.emit('Loading', 'hide');
                if (error.response) {
                    this.$center.emit('alert', {
                        icon: 'fas fa-exclamation-circle',
                        title: "İşlem Başarısız",
                        description: error.response.data.error.message,
                        type: 'danger'
                    });
                }
            });
        }
    }
}
</script>
