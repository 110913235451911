import axios from "axios";

//Local And Live Server Init
//const baseURL = process.env.NODE_ENV === 'production' ? 'https://api.uzaktanokulsistemi.com/v1/' : 'https://localhost:5001/v1/';
const baseURL = 'https://api.uzaktanokulsistemi.com/v1/';
//const baseURL = 'https://api.lmszoom.com/v1/';

//v-tooltip.tooltip="getText(mn.number, n)" 
export default axios.create({
  baseURL
});
 