<template>
<canvas id="my-canvas" style="canvasBg" v-if="isShow"></canvas>
<div class="wrapper" v-if="isShow">
    <div class="modal">
        <span class="emoji round">🏆</span>
        <h1>Tebrikler {{userName}} Yeni Rozet Kazandın</h1>
    </div>
</div>
</template>

<script>
//Confeti
import ConfettiGenerator from "confetti-js";
const confettiSettings = { target: 'my-canvas' };
export default {
    data() {
        return {
            isShow: false,
            userName: ""
        }
    },
    created() {
        var userData = this.$cookies.get("UserData");
        this.userName = userData.firstName + " " + userData.lastName;
        this.isShow = true;
        var scoped = this;
        this.$nextTick(() => {
            const confetti = new ConfettiGenerator(confettiSettings);
            confetti.render();
            var interval = setInterval(function () {
                scoped.isShow = false;
                confetti.clear();
                clearInterval(interval);
            }, 5000);

        });
    }
}
</script>

<style scoped>
:root {
    --background-color-1-rgb: 255, 255, 255;
    --alpha-75: 0.75;
}

.canvasBg {
    background-color: rgba(var(--background-color-1-rgb), var(--alpha-75));
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
}

.wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10000;
    justify-content: center;
    align-self: center;
}

.modal {
    margin: 0 auto;
    border: 1px solid var(--border-color);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.16);
    border-radius: 0.25rem;
    padding: 2rem;
    z-index: 1;
}

.emoji {
    display: block;
    text-align: center;
    font-size: 5rem;
    line-height: 5rem;
    transform: scale(0.5);
    animation: scaleCup 2s infinite alternate;
}

@keyframes scaleCup {
    0% {
        transform: scale(0.6);
    }

    100% {
        transform: scale(1);
    }
}

h1 {
    text-align: center;
    font-size: 1em;
    margin-top: 20px;
    margin-bottom: 20px;
}

@keyframes confettiRain {
    0% {
        opacity: 1;
        margin-top: -100vh;
        margin-left: -200px;
    }

    100% {
        opacity: 1;
        margin-top: 100vh;
        margin-left: 200px;
    }
}

.confetti {
    opacity: 0;
    position: absolute;
    width: 1rem;
    height: 1.5rem;
    transition: 500ms ease;
    animation: confettiRain 5s infinite;
}

#confetti-wrapper {
    overflow: hidden !important;
}
</style>
