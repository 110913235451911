<template>
<div class=" item working" id="v-step-2">
    <div class="title">
        <h2 style="font-size: 1em;
    font-weight: 700;">Ortalama Puan Sıralaması</h2>
    </div>
    <div class="con">

        <div :class="history.isCurrentUser ?'working-item active' :'working-item'" v-for="history in histories" :key="history.userId">
            <div class="top">
                <div class="icon">
                    <span class="order">{{ history.order}}</span>
                </div>
                <div class="detail">
                    <h3>{{history.userName}}</h3>
                    <p>{{history.point}}</p>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            histories: []
        }
    },
    created() {
        this.getData();
    },
    methods: {
        async getData() {
            var history = await this.$http.get("Student/GetStudentsExamHistory");
            this.histories = history.data.data;
        },
        openPage(data) {
            if (data.type == 'VIDEO') {
                this.$router.push('/watching/' + data.topicId + '/' + data.videoGroupId + '/video/' + data.refId);
            } else {
                this.$router.push({
                    name: "ExamPlayer",
                    params: {
                        examId: data.refId
                    }
                });
            }
        }
    }
}
</script>

<style scoped>
.order {
    background-color: var(--background-color-3);
    border-color: var(--main-color-1);
    transform: scale(1.5);
    border-radius: 1em;
    width: 2em;
    height: 2em;
    text-align: center;
    line-height: 2em;
    color: var(--text-color-2);
}

.active {
 background-color: var(--main-color-1) !important;
}
</style>
