<template>
<div class="profile-box">
    <div class="title">
        <h3>{{group.name}}</h3>
    </div>
    <form action="">
        <template v-for="(question) in group.surveyQuestions" :key="question.id">
            <empty-type v-if="question.type=='Empty'"   :value="question" />
            <single-type v-if="question.type=='Single'"   :value="question" />
            <multi-type v-if="question.type=='Multi'"  :value="question" />
            <file-type v-if="question.type=='File'"   :value="question" />
        </template>
    </form>
</div>
</template>

<script>
import EmptyType from "./EmptyType.vue"
import SingleType from "./SingleType.vue"
import MultiType from "./MultiType.vue"
import FileType from "./FileType.vue"
export default {
    
    components: {
        "empty-type": EmptyType,
        "single-type": SingleType,
        "multi-type": MultiType,
        "file-type": FileType
    },
    
    props: {
        group: {
            type: Object,
            required: true
        }
    }
}
</script>
