<template>
<div class="theme-change" style="display: none">
    <div class="modal-header">
        <h3>{{ $t("Modal.Theme.Title") }}</h3>
    </div>
    <div class="con">
        <h4>{{ $t("Modal.Theme.MainColor") }}</h4>
        <ul>
            <li :style="getColor(color)" :data-color="color.key" v-for="color in colors" :key="color.key" @click="changeColor(color)">
                <i class="fas fa-check" v-if="color.key == themeConfig.color"></i>
            </li>
        </ul>
        <h4>{{ $t("Modal.Theme.Background") }}</h4>
        <ul class="bg">
            <li :style="getColor(theme)" class="active" :data-background="theme.key" v-for="theme in themes" :key="theme.key" @click="changeTheme(theme)">
                <i class="fas fa-check" v-if="theme.key == themeConfig.theme"></i>
            </li>
        </ul>
        <h4>{{ $t("Modal.Theme.Menu") }}</h4>
        <div class="horver">
            <span v-bind:class="menu.key == themeConfig.menu ? 'active' : null" :data-menu="menu.key" onclick="changeMenu(this)" v-for="menu in menus" :key="menu.key" @click="changeMenu(menu)">{{ $t(menu.lang) }}</span>
        </div>
        <button @click="saveTheme()">{{ $t("Modal.Theme.SaveButton") }}</button>
    </div>
</div>
</template>

<script>
const html = document.getElementsByTagName("html")[0];
const body = document.getElementsByTagName("body")[0];
var emitter = require('tiny-emitter/instance');
export default {
    data() {
        return {
            colors: [{
                    key: 'red',
                    color: '#ff003a'
                },
                {
                    key: 'blue',
                    color: '#3333ff'
                },
                {
                    key: 'yellow',
                    color: '#ffad1f'
                },
                {
                    key: 'purple',
                    color: '#794bc4'
                },
                {
                    key: 'orange',
                    color: '#f45d22'
                },
                {
                    key: 'green',
                    color: '#17bf63'
                }
            ],
            themes: [{
                    color: '#ffffff',
                    key: 'light'
                },
                {
                    color: '#11151c',
                    key: 'loess'
                },
                {
                    color: '#121212',
                    key: 'dark'
                }
            ],
            menus: [{
                    lang: 'Modal.Theme.Vertical',
                    key: 'vertical',
                    title: 'Dikey'
                },
                {
                    lang: 'Modal.Theme.Horizontal',
                    key: 'horizontal',
                    title: 'Yatay'
                }
            ],
            themeConfig: {
                color: 'red',
                theme: 'light',
                menu: 'horizontal'
            }
        }
    },
    methods: {
        getColor(color) {
            return 'background-color:' + color.color + ';';
        },
        changeColor(color) {
            this.themeConfig.color = color.key;
        },
        changeTheme(theme) {
            this.themeConfig.theme = theme.key;
        },
        changeMenu(menu) {
            this.themeConfig.menu = menu.key;
        },
        async saveTheme() {
            if (this.$cookies.get("Token") != null) {
                await this.$http.post("Auth/theme", this.themeConfig);
                this.loadTheme();
            }
        },
        async loadTheme() {
            if (this.$cookies.get("Token") != null) {
                this.$center.emit('Loading', 'show');
                var themeData = null;
                if (this.$cookies.get("Token") != null) {
                    var theme = await this.$http.get("Auth/theme");
                    themeData = theme.data.data;
                }

                if (themeData != null)
                    this.themeConfig = themeData;

                if (this.themeConfig.menu == "vertical") {
                    body.classList.remove("horizontal");
                    body.classList.add("vertical");
                }

                if (this.themeConfig.menu == "horizontal") {
                    var width = document.body.clientWidth;
                    if (width <= 1200) {
                        body.classList.remove("vertical");
                        body.classList.remove("horizontal");
                        body.classList.add("vertical");
                    } else {
                        body.classList.remove("vertical");
                        body.classList.remove("horizontal");
                        body.classList.add(html.dataset.default);
                    }
                }

                html.dataset.default = this.themeConfig.menu;
                html.dataset.color = this.themeConfig.color;
                html.dataset.background = this.themeConfig.theme;
                this.$center.emit('Loading', 'hide');
            }
        }
    },
    mounted() {
        this.loadTheme();
        var scoped = this;
        emitter.on('ThemeChanged', () => {
            scoped.loadTheme();
        });
    }
}
</script>
