import { createI18n } from "vue3-i18n";

import en from '../src/locales/en.json'
import tr from '../src/locales/tr.json'
const i18n = createI18n({
  locale: "tr",
  fallbackLocale: 'tr',
  messages: {
    en:en,
    tr:tr
  }
});

export default i18n;
