<script>
import { defineComponent } from 'vue'
import { Bar } from 'vue3-chart-v2'

export default defineComponent({
    props: ["chartData"],
    name: 'MonthlyChart',
    extends: Bar,
    mounted() {
        var options = {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: true,
                position: 'bottom'
            },
        };
        this.renderChart({
            labels: [this.chartData.label],
            datasets: [{
                    label: 'Genel Doğru Ortalaması',
                    backgroundColor: '#6900FF',
                    data: [this.chartData.otherCorrect]
                },
                {
                    label: 'Senin Doğru Sayın',
                    backgroundColor: '#85EB4E',
                    data: [this.chartData.myCorrect]
                },
                {
                    label: 'Genel Yanlış Ortalaması',
                    backgroundColor: '#C84B31',
                    data: [this.chartData.otherWrong]
                },
                {
                    label: 'Senin Yanlış Sayın',
                    backgroundColor: '#DA0037',
                    data: [this.chartData.myWrong]
                },
                {
                    label: 'Genel Boş Ortalaması',
                    backgroundColor: '#090088',
                    data: [this.chartData.otherEmpty]
                },
                {
                    label: 'Senin Boş Sayın',
                    backgroundColor: '#00BCD4',
                    data: [this.chartData.myEmpty]
                }
            ]
        }, options)
    },
})
</script>
