<template>
<!-- Latest compiled and minified CSS -

 --->

<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">




<table style="width:100%">
  <tr style="width:100%">
    <td align="right"  style="width:100%"> 
       <div  ref="chartdiv" class="chardiv" style="  ">
  </div>
 
    </td>
    </tr>
    <tr>
      <td>
    <div ref="sonuclar"  class="sonuclar" style="width:100%;">

 </div>
    </td>
      </tr>
</table>







 
</template>
<style>
  /* === card component ====== 
 * Variation of the panel component
 * version 2018.10.30
 * https://codepen.io/jstneg/pen/EVKYZj
 */
 .chardiv{
   height:80vh; width:100%;
   position: relative;  
 }

 /* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  .chardiv{
    height:50vh; width:100%;
    position: relative;  
  }
  .sonuclar{
    font-size: 3vw;
  }
  
/* CSS KODLARI BURAYA */
 
} 
/* iPads (portrait and landscape) ----------- */
 
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
/* CSS KODLARI BURAYA */
  .sonuclar{
    font-size: 3vw;
  }
}
  
/* Desktops and laptops ----------- */
 
@media only screen and (min-width : 1224px) {
/* CSS KODLARI BURAYA */
 .content{
   padding-right:40px;padding-left:40px;
 }
}
/* Large screens ----------- */
 
@media only screen and (min-width : 1824px) {
  
/* CSS KODLARI BURAYA */
}
  
/* iPhone 5 (portrait & landscape)----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 568px) {
  
 .sonuclar{
    font-size: 3vw;
  }
}
  
 

 
 .bg-light{
   color:black !important;
  
 }
 .card{
   color:white ;
 }


:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
}


.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}




.card{ background-color: #fff; border: 1px solid transparent; border-radius: 6px; }
.card > .card-link{ color: #333; }
.card > .card-link:hover{  text-decoration: none; }
.card > .card-link .card-img img{ border-radius: 6px 6px 0 0; }
.card .card-img{ position: relative; padding: 0; display: table; }
.card .card-img .card-caption{
  position: absolute;
  right: 0;
  bottom: 16px;
  left: 0;
}
.card .card-body{ display: table; width: 100%; padding: 12px; }
.card .card-header{ border-radius: 6px 6px 0 0; padding: 8px; }
.card .card-footer{ border-radius: 0 0 6px 6px; padding: 8px; }
.card .card-left{ position: relative; float: left; padding: 0 0 8px 0; }
.card .card-right{ position: relative; float: left; padding: 8px 0 0 0; }
.card .card-body h1:first-child,
.card .card-body h2:first-child,
.card .card-body h3:first-child, 
.card .card-body h4:first-child,
.card .card-body .h1,
.card .card-body .h2,
.card .card-body .h3, 
.card .card-body .h4{ margin-top: 0; }
.card .card-body .heading{ display: block;  }
.card .card-body .heading:last-child{ margin-bottom: 0; }

.card .card-body .lead{ text-align: center; }

@media( min-width: 768px ){
  .card .card-left{ float: left; padding: 0 8px 0 0; }
  .card .card-right{ float: left; padding: 0 0 0 8px; }
    
  .card .card-4-8 .card-left{ width: 33.33333333%; }
  .card .card-4-8 .card-right{ width: 66.66666667%; }

  .card .card-5-7 .card-left{ width: 41.66666667%; }
  .card .card-5-7 .card-right{ width: 58.33333333%; }
  
  .card .card-6-6 .card-left{ width: 50%; }
  .card .card-6-6 .card-right{ width: 50%; }
  
  .card .card-7-5 .card-left{ width: 58.33333333%; }
  .card .card-7-5 .card-right{ width: 41.66666667%; }
  
  .card .card-8-4 .card-left{ width: 66.66666667%; }
  .card .card-8-4 .card-right{ width: 33.33333333%; }
}

/* -- default theme ------ */
.card-default{ 
  border-color: #ddd;
  background-color: #fff;
  margin-bottom: 24px;
}
.card-default > .card-header,
.card-default > .card-footer{ color: #333; background-color: #ddd; }
.card-default > .card-header{ border-bottom: 1px solid #ddd; padding: 8px; }
.card-default > .card-footer{ border-top: 1px solid #ddd; padding: 8px; }
.card-default > .card-body{  }
.card-default > .card-img:first-child img{ border-radius: 6px 6px 0 0; }
.card-default > .card-left{ padding-right: 4px; }
.card-default > .card-right{ padding-left: 4px; }
.card-default p:last-child{ margin-bottom: 0; }
.card-default .card-caption { color: #fff; text-align: center; text-transform: uppercase; }


/* -- price theme ------ */
.card-price{ border-color: #999; background-color: #ededed; margin-bottom: 24px; }
.card-price > .card-heading,
.card-price > .card-footer{ color: #333; background-color: #fdfdfd; }
.card-price > .card-heading{ border-bottom: 1px solid #ddd; padding: 8px; }
.card-price > .card-footer{ border-top: 1px solid #ddd; padding: 8px; }
.card-price > .card-img:first-child img{ border-radius: 6px 6px 0 0; }
.card-price > .card-left{ padding-right: 4px; }
.card-price > .card-right{ padding-left: 4px; }
.card-price .card-caption { color: #fff; text-align: center; text-transform: uppercase; }
.card-price p:last-child{ margin-bottom: 0; }

.card-price .price{ 
  text-align: center; 
  color: #337ab7; 
  font-size: 3em; 
  text-transform: uppercase;
  line-height: 0.7em; 
  margin: 24px 0 16px;
}
.card-price .price small{ font-size: 0.4em; color: #66a5da; }
.card-price .details{ list-style: none; margin-bottom: 24px; padding: 0 18px; }
.card-price .details li{ text-align: center; margin-bottom: 8px; }
.card-price .buy-now{ text-transform: uppercase; }
.card-price table .price{ font-size: 1.2em; font-weight: 700; text-align: left; }
.card-price table .note{ color: #666; font-size: 0.8em; }
</style>
<script>


import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

 
export default {
  data(){
    return {
        chartdata:[],
        eklenecek_sonuc:"",
        cddobilgi : { 
          "A": {
            "max_puan": 16, 
            "uzun_isim": "Çalışmaya başlamak ve sürdürmek",
            "olumsuz": "Ders çalışmaya başlamak ve zamanınızdan etkin bir şekilde yararlanmak konusunda ciddi problemleriniz olduğu anlaşılmaktadır. Değerli  zamanınızın  önemli bir bölümünü ders  çalışmanız gerektiğini  düşünerek veya ders başında,  ancak  çalışmadan geçirdiğiniz  görülmektedir. Eğitim  başarınızı yükseltebilmek için mutlaka ders çalışmaya başlamak ve sürdürmek konusundaki teknikleri ve iç disiplininizi kurmayı öğrenmeniz gerekmektedir.",
            "normal":"Ders çalışmaya başlamak ve sürdürmek  konusunda bazı  güçlüklerinizin olduğu anlaşılmaktadır. Eğlenmeye ve  dinlenmeye de  vakit ayırabilmek için derse ayırdığınız zamandan en etkin şekilde  yararlanmanız gerekmektedir. Kendi üzerinizde denetim kuracak yöntemleri öğrenirseniz hem sosyalleşmeye zaman ayırabilir hem de başarınızı yükseltebilirsiniz.",
            "olumlu": "Ders çalışmaya  başlamak ve  sürdürmek konusunda  önemli bir güçlüğünüzün olmadığı anlaşılmaktadır. Böylece hem ders çalışmaya ayırdığınız zamandan en üst düzeyde  yararlanmanız  mümkün olmakta, hem de özel  hayatınıza ve zevklerinize zaman ayırabilmektesiniz. Kendi kendinizi iyi denetleyebilen bir insan olduğunuz için  sizi kutlarız."
          },
          
          "B": {
            "max_puan": 9, 
            "uzun_isim": "Bilinçli çalışma ve öğrendiğini kullanmak",
            "olumsuz": "Bilinçli çalışmak ve öğrendiğini kullanmak konusunda önemli  eksikleriniz olduğu görülmektedir. Neyi niçin öğrendiğinizi bilmediğiniz, düzenli tekrar yapmadığınız için büyük  bir olasılıkla  okul hayatı size oldukça güç gelmektedir. Düzenli tekrar ve verimli ders çalışma yöntemlerini öğrenmeniz okul başarınızı yükseltmek için büyük önem taşımaktadır.",
            "normal":"Bilinçli çalışmak ve  öğrendiğinizi kullanmak konusunda bazı eksiklerinizin olduğu görülmektedir. Öğreneceğiniz malzemenin nerede kullanılacağını bilmek ve düzenli tekrar yapma  tekniğini  geliştirmek  başarınızda köklü değişiklikler yapacaktır.",
            "olumlu": "Bilinçli çalışan ve  öğrendiğini kullanan, böylece bilginizi geliştirerek unutmayı önleyen bir öğrenci  olduğunuz görülmektedir. Yaptığınız düzenli tekrarların başarınızdaki payı büyüktür."
          },
          
          "C": {
            "max_puan": 10, 
            "uzun_isim": "Not tutmak ve dersi dinlemek",
            "olumsuz": "Not tutmanın ve  dersi dinlemenin  başarı  üzerindeki  etkisini yeterince bilmediğiniz anlaşılmaktadır. Not tutmak konusunda tekniğinizi geliştirir ve bu konuda gayret gösterirseniz, bunun karşılığını en kısa  zamanda göreceğinizden emin olabilirsiniz.",
            "normal":"Not tutmak ve dersi dinlemek  konusunda bazı  hatalarınızın  olduğu anlaşılmaktadır.  Not tutma  becerinizi geliştirir  ve bu konudaki teknikleri öğrenirseniz verdiğiniz emeğin karşılığını fazlasıyla alırsınız.",
            "olumlu": "Not tutmak ve dersi dinlemek konusunda başarılı olduğunuz anlaşılmaktadır. Öğretmenin söylediklerini  iyi dinlediğiniz,  önemli ve önemsiz noktaları birbirinden ayırdığınız,  notlarınızı yeniden gözden geçirip düzenlediğiniz için okul başarınız yükselmektedir."
          },
          
          "D": {
            "max_puan": 12, 
            "uzun_isim": "Okuma alışkanlığı",
            "olumsuz": "Okumaya çok fazla  zaman ayırdığınız, buna  rağmen daha sonra  oldukça az şey hatırlayabildiğiniz  anlaşılmaktadır.  Önemli  olanla önemli olmayanı ayırmakta  güçlük  çektiğiniz metin içinde size gerekli olmayan  yerlerde zaman kaybettiğiniz  görülmektedir.  Okul başarınızı  yükseltebilmek için okuma becerinizi geliştirmeye özel önem vermek zorundasınız.",
            "normal":"Okurken önemli olanla olmayanı ayırmakta zaman zaman güçlük çektiğiniz ve bu nedenle değerli vaktinizden yeterince yararlanamadığınız anlaşılmaktadır. Okuma hızınızı yükseltip, seçiciliğinizi arttırabilirseniz okul barınızda önemli değişmeler olacaktır.",
            "olumlu": "Okuduğunuz metin içinde gerekli olanları ayırabildiğiniz ve gereksiz okumalarla zaman kaybetmediğiniz anlaşılmaktadır. Bu hiç şüphesiz okul başarınızı olumlu yönde etkilemektedir."
          },
          
          "E": {
            "max_puan": 7, 
            "uzun_isim": "Ödev hazırlamak",
            "olumsuz": "Günlük veya dönem ödevi  hazırlamanın, konunun özünü kavramak için ne kadar önemli olduğunun farkında değilsiniz. Ödevlerden bir an önce kurtulma eğilimi okul başarınızı tehdit  eden önemli bir engeldir.  Ödevlerin, gelişmeniz için bir adım olduğunu kabullenir ve öğrenirseniz başarınız yükselecektir.",
            "normal":"Ödevlerinizi gereği gibi  hazırlamak ve  düzenlemekte  zaman zaman güçlük çektiğiniz anlaşılmaktadır. Ödevlerinizi zamanında ve yeterle  çalışmayla yapmanız okul başarınızı arttıracaktır.",
            "olumlu": "Ödevlerin eğitim hayatı içindeki önemini kavramış olduğunuz anlaşılmaktadır. Çeşitli kişi ve  kaynaklardan yararlanarak ve  belirli bir plana dayandırarak zamanında hazırladığınız ödevler bütün eğitim hayatınız boyunca başarınızın önemli nedenlerinden biri olacaktır."
          },
          
          "F": {
            "max_puan": 8, 
            "uzun_isim": "Okula karşı tutum",
            "olumsuz": "Okula karşı tutumunuzun çalışmayı öğrenmeyi ve başarılı olmayı güçleştirdiği görülmektedir. Sadece okulda değil hiçbir konuda olumsuz bir tutumla olumlu bir sonuç elde edilmesi mümkün değildir. Okul, eğitim ve öğretmenlerle ilgili temel düşünce ve yaklaşımlarınızı gözden geçirmeniz, eğitime verdiğiniz yılların  karşılığını  alabilmeniz açısından hayati önem taşımaktadır.",
            "normal":"Okula karşı olumsuz  duygu ve düşünceler  içinde olduğunuz görülmektedir. Okula karşı  zaman zaman  gelişen bu olumsuz  tavrınızın okul  başarınızı etkilememesi  için bunları yeniden  ele almanızda ve  gözden geçirmenizde yarar vardır.",
            "olumlu": "Okula karşı olumlu bir tavır içinde  olduğunuz görülmektedir. Oynadığınız okul oyununun  ilginç yönlerini  bulup ondan keyif  aldığınız ve bunun da okul başarınızı yükselttiği, öğretmenleriniz ve  arkadaşlarınızla ilişkinizi geliştirdiği kesindir."
          },
          
          "G": {
            "max_puan": 11, 
            "uzun_isim": "Sınavlara hazırlanmak ve sınava girmek",
            "olumsuz": "Sınavlarda başarılı olmanın, sınav öncesinde başlayan ve sınavda da devam eden bir işlemler  dizisi olduğunun  farkında değilsiniz. Zaman zaman çalıştığınız ölçüde başarılı olamadığınızdan yakınıyorsanız, muhtemelen başarısızlığınızın  arkasındaki nedenlerin başında sınava hazırlanma teknik ve sınav taktiklerini  yeterince bilmemek ve uygulamamak  yatmaktadır. Bu eksikliğinizi  gidermeniz,  çalışmanızın  karşılığını  alabilmek  için ön şarttır.",
            "normal":"Bu konularda bilgi ve tecrübe sahibi  olmanıza rağmen  bazı eksikleriniz olduğu  görülmektedir. Bu eksiklerinizi giderirseniz başarınız daha da yükselecektir.",
            "olumlu": "Sınavlarla ilgili teknik ve taktikleri oldukça iyi bildiğiniz ve  uyguladığınız görülmektedir. Okul başarınızın arkasındaki en önemli nedenlerden biri hiç şüphesiz budur."
          }
          
    } 
        
    }
  },
  methods: {
      cddohesap: function () {
        
                            var CDDOPUAN={};
                            var CDDOETIKET={};
                            CDDOPUAN["A"] = 0;
                            CDDOPUAN["B"] = 0;
                            CDDOPUAN["C"] = 0;
                            CDDOPUAN["D"] = 0;
                            CDDOPUAN["E"] = 0;
                            CDDOPUAN["F"] = 0;  
                            CDDOPUAN["G"] = 0;
                            console.log(this.cddodata);console.log("111");
                      for (let i = 0; i < this.cddodata.surveyQuestions.length; i++) {
                            var soru = (i+1);
                            const q =   this.cddodata.surveyQuestions[i];
                            //choices 0 hayır 1 evet
                            console.log("soru-" +  soru +":" + q.choices[1].isUserSelected);
                            var cevap =  q.choices[1].isUserSelected;
                           
                            //A- Çalışmaya başlamak ve sürdürmek
                            //13Y 30Y 40Y 49Y
                            //15Y 32Y 43Y 55Y
                            //17Y 37Y 44Y 67Y
                            //l8Y 39D 48Y 70Y

                            if(soru==13 && (cevap==false) )  CDDOPUAN["A"]++ ;
                            if(soru==30 && (cevap==false) )  CDDOPUAN["A"]++ ;
                            if(soru==40 && (cevap==false) )  CDDOPUAN["A"]++ ;
                            if(soru==49 && (cevap==false) )  CDDOPUAN["A"]++ ;
                            if(soru==15 && (cevap==false) )  CDDOPUAN["A"]++ ;
                            if(soru==32 && (cevap==false) )  CDDOPUAN["A"]++ ;
                            if(soru==43 && (cevap==false) )  CDDOPUAN["A"]++ ;
                            if(soru==55 && (cevap==false) )  CDDOPUAN["A"]++ ;
                            if(soru==17 && (cevap==false) )  CDDOPUAN["A"]++ ;
                            if(soru==37 && (cevap==false) )  CDDOPUAN["A"]++ ;
                            if(soru==44 && (cevap==false) )  CDDOPUAN["A"]++ ;
                            if(soru==67 && (cevap==false) )  CDDOPUAN["A"]++ ;
                            if(soru==18 && (cevap==false) )  CDDOPUAN["A"]++ ;
                            if(soru==39 && (cevap==true) )   CDDOPUAN["A"]++ ;
                            if(soru==48 && (cevap==false) )  CDDOPUAN["G"]++ ;
                            if(soru==70 && (cevap==false) )  CDDOPUAN["G"]++ ;
                            //B- Bilinçli çalışmak ve öğrendiğini kullanmak
                            //12Y  19D  47D
                            //14D  38D  50D
                            //16Y  42Y  51D
                            if(soru==12 && (cevap==false) )  CDDOPUAN["B"]++ ;
                            if(soru==14 && (cevap==true ) ) CDDOPUAN["B"]++ ;
                            if(soru==16 && (cevap==false) )  CDDOPUAN["B"]++ ;
                            if(soru==19 && (cevap==true) )  CDDOPUAN["B"]++ ;
                            if(soru==38 && (cevap==true) )  CDDOPUAN["B"]++ ;
                            if(soru==42 && (cevap==false) )  CDDOPUAN["B"]++ ;
                            if(soru==47 && (cevap==true) )  CDDOPUAN["B"]++ ;
                            if(soru==50 && (cevap==true) )  CDDOPUAN["B"]++ ;
                            if(soru==51 && (cevap==true) )  CDDOPUAN["B"]++ ;
                            //C- Not tutmak ve dersi dinlemek
                            //8D  22Y 61D 72Y
                            //10Y 24D 62Y
                            //20D 31D 71Y
                            if(soru==8 && (cevap==true) )  CDDOPUAN["C"]++ ;
                            if(soru==10 && (cevap==false) )  CDDOPUAN["C"]++ ;
                            if(soru==20 && (cevap==true) )  CDDOPUAN["C"]++ ;
                            if(soru==22 && (cevap==false) )  CDDOPUAN["C"]++ ;
                            if(soru==24 && (cevap==true) )  CDDOPUAN["C"]++ ;
                            if(soru==31 && (cevap==true) )  CDDOPUAN["C"]++ ;
                            if(soru==61 && (cevap==true) )  CDDOPUAN["C"]++ ;
                            if(soru==62 && (cevap==false) )  CDDOPUAN["C"]++ ;
                            if(soru==71 && (cevap==false) )  CDDOPUAN["C"]++ ;
                            if(soru==72 && (cevap==false) )  CDDOPUAN["G"]++ ;
                            /*
                            D- Okuma alışkanlıkları ve teknikleri
                            4Y 11D 34D 56D
                            5Y 28Y 45D 60D
                            7D 29Y 46D 73Y
                            */
                            if(soru==4 && (cevap==false) )  CDDOPUAN["D"]++ ;
                            if(soru==5 && (cevap==false) )  CDDOPUAN["D"]++ ;
                            if(soru==7 && (cevap==true) )  CDDOPUAN["D"]++ ;
                            if(soru==11 && (cevap==true) )  CDDOPUAN["D"]++ ;
                            if(soru==28 && (cevap==false) )  CDDOPUAN["D"]++ ;
                            if(soru==29 && (cevap==false) )  CDDOPUAN["D"]++ ;
                            if(soru==34 && (cevap==true) )  CDDOPUAN["D"]++ ;
                            if(soru==45 && (cevap==true) )  CDDOPUAN["D"]++ ;
                            if(soru==46 && (cevap==true) )  CDDOPUAN["D"]++ ;
                            if(soru==56 && (cevap==true) )  CDDOPUAN["D"]++ ;
                            if(soru==60 && (cevap==true) )  CDDOPUAN["D"]++ ;
                            if(soru==73 && (cevap==false) )  CDDOPUAN["D"]++ ;
                            /*
                            E- Ödev hazırlamak
                            3Y  25D 52Y 63D
                            23D 26Y 53Y
                            */
                            if(soru==3 && (cevap==false) )  CDDOPUAN["E"]++ ;
                            if(soru==23 && (cevap==true) )  CDDOPUAN["E"]++ ;
                            if(soru==25 && (cevap==true) )  CDDOPUAN["E"]++ ;
                            if(soru==26 && (cevap==false) )  CDDOPUAN["E"]++ ;
                            if(soru==52 && (cevap==false) )  CDDOPUAN["E"]++ ;
                            if(soru==53 && (cevap==false) )  CDDOPUAN["E"]++ ;
                            if(soru==63 && (cevap==true) )  CDDOPUAN["E"]++ ;
                            /*
                            F- Okula karşı tutum
                            27Y 35Y 57D 68Y
                            33D 36Y 64D 69Y
                            */
                            if(soru==27 && (cevap==false) )  CDDOPUAN["F"]++ ;
                            if(soru==33 && (cevap==true) )  CDDOPUAN["F"]++ ;
                            if(soru==35 && (cevap==false) )  CDDOPUAN["F"]++ ;
                            if(soru==36 && (cevap==false) )  CDDOPUAN["F"]++ ;
                            if(soru==57 && (cevap==true) )  CDDOPUAN["F"]++ ;
                            if(soru==64 && (cevap==true) )  CDDOPUAN["F"]++ ;
                            if(soru==68 && (cevap==false) )  CDDOPUAN["F"]++ ;
                            if(soru==69 && (cevap==false) )  CDDOPUAN["F"]++ ;
                            /*
                            G- Sınavlara hazırlanmak ve sınava girmek
                            1Y 9Y 54Y 65D
                            2Y 21Y 58Y 66Y
                            6D 41D 59Y
                            */
                            if(soru==1 && (cevap==false) )  CDDOPUAN["G"]++ ;
                            if(soru==2 && (cevap==false) )  CDDOPUAN["G"]++ ;
                            if(soru==6 && (cevap==true) )  CDDOPUAN["G"]++ ;
                            if(soru==9 && (cevap==false) )  CDDOPUAN["G"]++ ;
                            if(soru==21 && (cevap==false) )  CDDOPUAN["G"]++ ;
                            if(soru==41 && (cevap==true) )  CDDOPUAN["G"]++ ;
                            if(soru==54 && (cevap==false) )  CDDOPUAN["G"]++ ;
                            if(soru==58 && (cevap==false) )  CDDOPUAN["G"]++ ;
                            if(soru==59 && (cevap==false) )  CDDOPUAN["G"]++ ;
                            if(soru==65 && (cevap==true) )  CDDOPUAN["G"]++ ;
                            if(soru==66 && (cevap==false) )  CDDOPUAN["G"]++ ;
 
                          


                     

                            /*
                                        surveyQuestions: [{id: "3a6d7451-b300-439c-b486-bfa723c9ab3b", code: "1",…},…]
                                        0: {id: "3a6d7451-b300-439c-b486-bfa723c9ab3b", code: "1",…}
                                        choices: [{id: "36347a58-b400-4956-8cf7-e5d96fc1daff", text: "Hayır", isUserSelected: true, viewOrder: 0},…]
                                        0: {id: "36347a58-b400-4956-8cf7-e5d96fc1daff", text: "Hayır", isUserSelected: true, viewOrder: 0}
                                        id: "36347a58-b400-4956-8cf7-e5d96fc1daff"
                                        isUserSelected: true
                                        text: "Hayır"
                                        viewOrder: 0
                                        1: {id: "ea62ca67-c900-4ed0-b2e9-882b096cc8a7", text: "Evet", isUserSelected: false, viewOrder: 1}
                                        code: "1"
                                        id: "3a6d7451-b300-439c-b486-bfa723c9ab3b"
                                        text: "Derslerle ilgili tekrarlarımın çoğunu sınavdan önceki gece yaparım."
                                        type: "Single"
                                        viewOrder: 0

                            */
                      }
                      var vuethis=this;
                     Object.keys(CDDOPUAN).forEach(function(k){ 
                       var puan= CDDOPUAN[k];        
                                
                       var    uzun_isim = vuethis.cddobilgi[k]["uzun_isim"];
                       var    max_puan = vuethis.cddobilgi[k]["max_puan"];
                       var    yuzde = parseInt(puan / max_puan * 100);
                       
                       var durum="";
                       var card_class="";
                       var icon="";
                       if (yuzde >= 61){
                        durum = "olumlu"
                        card_class = "text-white bg-success"
                        icon = "<span class='fa fa-thumbs-o-up'></span>".repeat(2)
                      } 
                      else if (31 <= yuzde && yuzde <= 60){
                        durum = "normal"
                        card_class = "text-white bg-info"
                        icon = "<span class='fa fa-hand-o-right'></span>"
                      }
                      else if (yuzde <= 30){
                        durum = "olumsuz"
                        card_class = "bg-light"
                        icon = "<span class='fa fa-thumbs-o-down'></span>"
                      }
                     var  aciklama = vuethis.cddobilgi[k][durum];

                      
                     var  eklenecek_sonuc = '<div id="sonuc_kalibi">		<div class="card %sonuc_kalibi_card_class%">		\
                        <div class="card-header">			%baslik% \
                        	  </div>		  <div class="card-body">			  %aciklama%\
                        </div>		</div>	</div>'
                      eklenecek_sonuc = eklenecek_sonuc.replace("%baslik%", icon + " " + uzun_isim + "<span class='pull-right'>" + durum.charAt(0).toUpperCase() + durum.slice(1) + " %" + yuzde + "</span>")
                      eklenecek_sonuc = eklenecek_sonuc.replace("%sonuc_kalibi_card_class%", card_class)
                      eklenecek_sonuc = eklenecek_sonuc.replace("%aciklama%", aciklama)
                      eklenecek_sonuc += "<br />"
                      
                      vuethis.eklenecek_sonuc=vuethis.eklenecek_sonuc + eklenecek_sonuc;

                      
                       vuethis.chartdata.push({
                        "category": uzun_isim + " %" + yuzde,
                        "value": yuzde,
                        "full": 100
                      })
                      
                      });
 
                        console.log(CDDOPUAN);
    }
  },

   mounted(){

         let chart = am4core.create(this.$refs.chartdiv, am4charts.RadarChart);

      chart.data = this.chartdata ;

      // Make chart not full circle
      chart.startAngle = -90;
      chart.endAngle = 180;
      chart.innerRadius = am4core.percent(20);

      // Set number format
      chart.numberFormatter.numberFormat = "#.#'%'";

      // Create axes
      var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "category";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.grid.template.strokeOpacity = 0;
      categoryAxis.renderer.labels.template.horizontalCenter = "right";
      
     console.log(window.innerWidth);
       if(window.innerWidth<321){//iphone5
           categoryAxis.renderer.labels.template.fontWeight = 100;
           categoryAxis.renderer.labels.template.fontSize = 7;
      }else if(window.innerWidth<600){
           categoryAxis.renderer.labels.template.fontWeight = 300;
           categoryAxis.renderer.labels.template.fontSize = 8;
      }else  if(window.innerWidth<820){
           categoryAxis.renderer.labels.template.fontWeight = 500;
           categoryAxis.renderer.labels.template.fontSize = 15;
      }else  if(window.innerWidth<1025){//tablet
           categoryAxis.renderer.labels.template.fontWeight = 500;
           categoryAxis.renderer.labels.template.fontSize = 18;
      }else{//pc
           categoryAxis.renderer.labels.template.fontSize = 23;
           categoryAxis.renderer.labels.template.fontWeight = 500;
          
      }
      
      categoryAxis.renderer.labels.template.adapter.add("fill", function(fill, target) {
        return (target.dataItem.index >= 0) ? chart.colors.getIndex(target.dataItem.index) : fill;
      });
      categoryAxis.renderer.minGridDistance = 7;

      var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
      valueAxis.renderer.grid.template.strokeOpacity = 0;
      valueAxis.min = 0;
      valueAxis.max = 100;
      valueAxis.strictMinMax = true;

      // Create series
      var series1 = chart.series.push(new am4charts.RadarColumnSeries());
      series1.dataFields.valueX = "full";
      series1.dataFields.categoryY = "category";
      series1.clustered = false;
      series1.columns.template.fill = new am4core.InterfaceColorSet().getFor("alternativeBackground");
      series1.columns.template.fillOpacity = 0.08;
      series1.columns.template.cornerRadiusTopLeft = 20;
      series1.columns.template.strokeWidth = 0;

      series1.columns.template.radarColumn.cornerRadius = 20;

      var series2 = chart.series.push(new am4charts.RadarColumnSeries());
      series2.dataFields.valueX = "value";
      series2.dataFields.categoryY = "category";
      series2.clustered = false;
      series2.columns.template.strokeWidth = 0;
      series2.columns.template.tooltipText = "{category}: [bold]{value}[/]";
      series2.columns.template.radarColumn.cornerRadius = 20;

      series2.columns.template.adapter.add("fill", function(fill, target) {
        return chart.colors.getIndex(target.dataItem.index);
      });

      // Add cursor
      chart.cursor = new am4charts.RadarCursor();
      this.$refs.sonuclar.innerHTML +=this.eklenecek_sonuc ;

    },

  created:function (){
         
    this.cddohesap(); 
  },
    props: {
        cddodata: {
            type: Object,
            required: true
          }
    }
}
</script>
