<template>
  <header-bar />
  <nav-bar />
</template>

<script>
import HeaderBar from "./Header/HeaderBar"
import NavBar from "./Header/NavBar"
/* eslint-disable */
import "../flowise"
export default {
  components: {
    "header-bar": HeaderBar,
    "nav-bar": NavBar
  }
}

</script>
