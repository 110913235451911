<template>
  <div class="content messages">
    <div class="con">
      <div class="message-list">
        <button
          class="message-list-item"
          v-for="group in groups"
          :key="group.id"
          @click="selectGroup(group)"
        >
          <div class="cover">
            <img :src="group.imageUrl" alt="" />
          </div>
          <div class="info">
            <h4>{{ group.name }}</h4>
            <p>Bu gruba mesaj atabilirsiniz.</p>
          </div>
        </button>
      </div>

      <div id="msg-content" class="message-content" style="right: -100%">
        <div class="top">
          <i class="fas fa-chevron-left" @click="getBack()"></i>
          <img
            :src="
              selectedGroup.imageUrl ??
              'https://www.vhv.rs/dpng/d/601-6016613_info-icon-png-transparent-png.png'
            "
            alt=""
          />
          <h4>
            {{ selectedGroup.name ?? "Mesaj Atmak İstediğiniz Grubu Seçin" }}
          </h4>
        </div>
        <div class="main" id="message-manin">
          <div
            class="message-box"
            v-bind:class="msg.clientId == userId ? 'message-box-me' : null"
            v-for="(msg, index) in messages"
            :key="msg"
            @click="showImage(msg.messageImage)"
          >
            <div class="cover">
              <img :src="msg.profileImage" alt="" />
            </div>
            <div class="info" :class="validation == msg ? 'playing' : null">
              <span>{{ msg.messageDate }}</span>
              <h5>{{ msg.userName }}</h5>
              <p v-katex>
                {{ msg.messageText }}
              </p>
              <img :src="msg.messageImage" style="max-width: 100%" />
            </div>
          </div>
        </div>
        <div class="bot">
          <div class="img-add" v-if="addMessageRequest.imageUrl != ''">
            <img :src="addMessageRequest.imageUrl" alt="" />
            <span>Resim Seçildi</span>
            <i
              class="far fa-times"
              style="font-size: 24px; margin-left: auto"
              @click="clearImage"
            ></i>
          </div>
          <div class="bot" style="flex-wrap: nowrap">
            <i class="fad fa-camera-alt" @click="addImage"></i>
            <input
              ref="fileInput"
              @change="handleImage"
              accept="image/*"
              type="file"
              style="display: none"
            />
            <input
              type="text"
              v-model="addMessageRequest.text"
              placeholder="Mesaj yazın"
              v-on:keyup.enter="addMessage"
            />
            <i class="fad fa-paper-plane" @click="addMessage"></i>
          </div>
        </div>
      </div>
       <Modal
      v-model="visible"
      :fullscreen="false"
      modal-transition="scale"
      :click-out="true"
      :disable-motion="false"
      max-width="800px"
      :remove-backdrop="false"
    >
      <div class="video-questions-modal">
        <div class="question-box">
          <div class="video">
            <img
              :src="imgs"
              style="
                height: auto;
                width: 100%;
                aspect-ratio: 1/1;
                object-fit: contain;
              "
            />
          </div>
        </div>
      </div>
    </Modal>
    </div>
  </div>
</template>

<script>
import {
  HubConnectionBuilder,
  LogLevel,
  HttpTransportType,
} from "@aspnet/signalr";
var emitter = require("tiny-emitter/instance");
import { Modal } from "vue-neat-modal";
export default {
  components: {
    Modal,
  },
  data() {
    return {
      groups: [],
      messages: [],
      selectedGroup: {},
      addMessageRequest: {
        messageGroupId: "",
        text: "",
        imageUrl: "",
      },
      connection: null,
      userId: this.$cookies.get("UserData").id,
      pitch: 1,
      rate: 1,
      synth: window.speechSynthesis,
      validation: null,
      imgs: null,
      visible: false,
    };
  },
  created() {
    const baseURL =
      process.env.NODE_ENV === "production"
        ? "https://api.uzaktanokulsistemi.com/chatHub"
        : "https://api.uzaktanokulsistemi.com/chatHub";
    this.connection = new HubConnectionBuilder()
      .configureLogging(LogLevel.Information)
      .withUrl(baseURL, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      })
      .build();

    this.connection.start();
    this.getData();
  },
  unmounted() {
    this.connection.stop();
  },
  updated() {
    this.$nextTick(() => {
      var container = this.$el.querySelector("#message-manin");
      container.scrollTop = container.scrollHeight + 10;

      var playButtons = document.getElementsByClassName("play-button");
      for (let index = 0; index < playButtons.length; index++) {
        const playButton = playButtons[index];
        playButton.addEventListener("click", getPlayer, false);
      }

      function getPlayer(e) {
        var videoPlayer = document.getElementById("player-" + e.srcElement.id);
        if (videoPlayer.paused) {
          videoPlayer.play();
        } else {
          videoPlayer.pause();
        }
      }
    });
  },
  methods: {
    showImage(image) {
        if(image == null) return;
      this.imgs = image;
      this.visible = true;
    },
    speak(message) {
      if (this.validation == message) {
        this.synth.cancel();
        return;
      }

      if (this.validation != null) {
        this.synth.pause();
      }

      this.validation = message;
      let sInstance = new SpeechSynthesisUtterance(message.messageText);
      var self = this;
      sInstance.onend = function (event) {
        self.validation = null;
        console.log("SpeechSynthesisUtterance.onend");
      };
      sInstance.onerror = function (event) {
        self.validation = null;
        console.error("SpeechSynthesisUtterance.onerror");
      };
      sInstance.pitch = this.pitch;
      sInstance.rate = this.rate;
      this.synth.speak(sInstance);
    },
    clearImage() {
      this.addMessageRequest.imageUrl = "";
      this.$refs.fileInput.value = null;
    },
    addImage() {
      this.$refs.fileInput.click();
    },
    handleImage(e) {
      const selectedImage = e.target.files[0];
      this.getBase64(selectedImage);
    },
    getBase64(file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      var scoped = this;
      reader.onload = function () {
        scoped.addMessageRequest.imageUrl = reader.result;
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    },
    async getData() {
      var groups = await this.$http.get("Message/Groups");
      this.groups = groups.data.data;
      if (this.groups.length > 0) {
        this.selectedGroup = this.groups[0];
        this.getMessages();
      }
      this.groups.forEach((element) => {
        this.listener(element.id);
      });
    },
    getBack() {
      var content = document.getElementById("msg-content");
      content.style.right = "-100%";
    },
    selectGroup(data) {
      this.selectedGroup = data;
      this.getMessages();
      var content = document.getElementById("msg-content");
      content.style.right = "0px";
    },
    async getMessages() {
      var messages = await this.$http.get(
        "Message/Messages/" + this.selectedGroup.id
      );
      this.messages = messages.data.data;
    },
    async addMessage() {
      if (
        this.addMessageRequest.text == "" &&
        this.addMessageRequest.imageUrl == ""
      )
        return;

      this.addMessageRequest.messageGroupId = this.selectedGroup.id;
      this.$http.post("Message/AddMessage", this.addMessageRequest).then(() => {
        this.addMessageRequest.text = "";
        this.addMessageRequest.imageUrl = "";
        this.$refs.fileInput.value = null;
        this.getMessages();
      });
    },
    listener(Id) {
      this.connection.on(Id, (messageData) => {
        if (messageData.channelId == this.selectedGroup.id) {
          this.messages.push(messageData);
        } else {
          var groups = this.groups.filter(
            (x) => x.id == messageData.channelId
          )[0];
          console.log(groups);
          emitter.emit("alert", {
            icon: messageData.profileImage,
            title: messageData.userName + "-" + groups.name,
            description:
              messageData.messageText == ""
                ? "Resim gönderdi"
                : messageData.messageText,
            type: "success",
          });
        }
        if (messageData.clientId != this.userId) {
          var audio = new Audio("https://dosya.turkiyeakademi.net/message.mp3");
          audio.play();
        }
      });
    },
    messageParser(msg, index) {
      var data = msg.messageText;
      if (data.startsWith("[video=")) {
        var url = data.replaceAll("[video=", "").replaceAll("]", "");
        return (
          '<div class="player"><span class="play-button" id="' +
          msg.clientId +
          index +
          '" style="position: absolute;top: 0;width: 100%;height: 100%;display: flex;align-items: flex-end;justify-content: flex-start;padding-left: 13px;padding-bottom: 38px;font-size: 25px;color: white;z-index:9999;"><i class="fad fa-play" onClick="playVideo(' +
          msg.clientId +
          index +
          ')"></i></span><video src="' +
          url +
          '" id="player-' +
          msg.clientId +
          index +
          '" style="width: 100%;"/><div>'
        );
      }
      const rows = data.split("\n");
      let html = "<div>";
      var isHead = true;
      var header = [];
      var rowsData = [];
      rows.forEach((row) => {
        if (row.startsWith("|")) {
          html += "#";
          if (isHead) {
            const columnNames = row.split("|").map((name) => name.trim());
            columnNames.forEach((name) => {
              header.push(name);
            });
            isHead = false;
          } else {
            var rows = [];
            const cells = row.split("|").map((cell) => cell.trim());
            cells.forEach((cell) => {
              rows.push(cell);
            });

            rowsData.push(rows);
          }
        } else {
          html += "<p>" + row + "</p>";
        }
      });

      if (header.length > 0) {
        var tableHtml = '<table class="custom_table"><thead><tr>';
        header.forEach((head) => {
          tableHtml += "<th>" + head + "</th>";
        });
        tableHtml += "</tr></thead><tbody>";
        rowsData.forEach((row) => {
          tableHtml += "<tr>";
          row.forEach((rq) => {
            var text = "";
            if (rq.startsWith("-")) {
              text = "";
            } else {
              if (rq.startsWith("http")) {
                text = `<a href="${rq}" target=_blank>İzle</a>`;
              } else {
                text = rq;
              }
            }
            tableHtml += "<td>" + text + "</td>";
          });
          tableHtml += "</tr>";
        });
        tableHtml += "</tbody></table>";
        html = html.replaceAll(/#+/g, tableHtml);
      }

      html += "</div>";
      return html;
    },
  },
};
</script>

<style>
.custom_table {
  border-collapse: separate;
  width: 100%;
  border-spacing: 1px 1px;
  font-size: 0.875em;
  line-height: 1.7142857;
  margin-bottom: 2em;
  margin-top: 2em;
  table-layout: auto;
  text-align: left;
}

.custom_table thead {
  border-bottom-color: var(--main-color-3);
  border-bottom-width: 1px;
}

.custom_table th {
  background-color: rgba(236, 236, 241, 0.2);
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-top-width: 1px;
  padding: 0.25rem 0.75rem;
}

.custom_table td {
  border-bottom-color: var(--bs-gray-300);
  border-bottom-width: 1px;
}

.playing {
  border-color: #00ffea !important;
  border-width: 5px;
  border: solid;
}
</style>
