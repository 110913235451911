<template>
<div class="content targets">
    <div class="top">
        <h3>{{$t("AddTarget.Title")}}</h3>
    </div>

    <div class="new-add-filter-box">
        <form @submit="(e)=>e.preventDefault()">
            <label>
                <p>{{$t("AddTarget.FilterPanel.SchoolType")}}</p>
                <select v-model="filter.schoolType" @change="changeFilter">
                <!--    <option value="">Hepsi</option>-->
                    <option  v-for="scholarOption in scholarOptions" v-bind:key="scholarOption.text">
                        {{scholarOption.text}}
                    </option>
                   
                </select>
            </label>
            <label>
                <p>{{$t("AddTarget.FilterPanel.City")}}</p>
                <select v-model="filter.city" @change="changeFilter">
                    <option value="">Hepsi</option>
                    <option :value="city" v-for="city in cities" :key="city">{{city}}</option>
                </select>
            </label>
            <label>
                <p>{{$t("AddTarget.FilterPanel.Special")}}</p>
                <input type="text" v-model="filter.search" :placeholder="$t('AddTarget.FilterPanel.SpecialPlaceHolder')" @keydown="(e)=>{if(e.keyCode==13){changeFilter();}}" />
            </label>
        </form>
    </div>

    <table>
        <tr>
            <th>#</th>
            <th>{{$t("AddTarget.TableColumn.SchoolName")}}</th>
            <th>{{$t("AddTarget.TableColumn.Faculty")}}</th>
            <th>{{$t("AddTarget.TableColumn.Langue")}}</th>
            <th>{{$t("AddTarget.TableColumn.City")}}</th>
            <th>{{$t("AddTarget.TableColumn.Scholarship")}}</th>
            <th>{{$t("AddTarget.TableColumn.Base")}}</th>
            <th>{{$t("AddTarget.TableColumn.Top")}}</th>
            <th>Kontenjan</th>
            <th>{{$t("AddTarget.TableColumn.Transactions")}}</th>
        </tr>

        <tr v-for="(school, index) in schools" :key="school.id">
            <td>#{{ index + 1 }}</td>
            <td>{{ school.name }}</td>
            <td>{{ school.faculty }}</td>
            <td>{{ school.langues }}</td>
            <td>{{ school.cities }}</td>
            <td>{{ school.scholarship }}</td>
            <td>{{ school.basePoint }}</td>
            <td>{{ school.topPoint }}</td>
            <td>{{ school.orderOfSuccess }}</td>
            <td>
                <i class="fas fa-info" @click="info(school)"></i>
                <i class="fad fa-plus" @click="addTarget(school.id)"></i>
            </td>
        </tr>
    </table>

    <div class="pagination">
        <button v-if="page > 1" @click="prevPage()">
            <i class="fas fa-chevron-left"></i>
            <span>{{$t("AddTarget.PrevButton")}}</span>
        </button>
        <p>{{ page }}</p>
        <button @click="nextPage()">
            <span>{{$t("AddTarget.NextButton")}}</span>
            <i class="fas fa-chevron-right"></i>
        </button>
    </div>
    <Modal v-model="isOpen" :fullscreen="false" modal-transition="scale" :click-out="true" :disable-motion="false" max-width="480px" :remove-backdrop="false">
        <div class="target-detail">
            <div class="title">
                <i class="far fa-chevron-left"></i>
                <h3>{{selectedSchool.name}}</h3>
            </div>
            <ul>
                <li>
                    <h4>Fakülte:</h4>
                    <p>{{selectedSchool.faculty}}</p>
                </li>
                <li>
                    <h4>Eğitim Dili:</h4>
                    <p>{{selectedSchool.langues}}</p>
                </li>
                <li>
                    <h4>Şehir:</h4>
                    <p>{{selectedSchool.cities}}</p>
                </li>
                <li>
                    <h4>Burs Durumu:</h4>
                    <p>{{selectedSchool.scholarship}}</p>
                </li>
                <li>
                    <h4>Taban Puanı:</h4>
                    <p>{{selectedSchool.topPoint}}</p>
                </li>
                <li>
                    <h4>Tavan Puanı:</h4>
                    <p>{{selectedSchool.basePoint}}</p>
                </li>
                <li>
                    <h4>Kontenjan:</h4>
                    <p>{{selectedSchool.orderOfSuccess}}</p>
                </li>
            </ul>
        </div>
    </Modal>
</div>
</template>

<script>
import { Modal } from "vue-neat-modal";
export default {
    components: {
        Modal
    },
    data() {
        return {
            schools: [],
            page: 1,
            selectedSchool: {},
            isOpen: false,
            scholarOptions:[
                {                text:"Devlet Üniversitesi"            },
                {                text:"Vakıf Üniversitesi"            },
                {                text:"KKTC Üniversite"            },
                {                text:"Yabancı Üniversite"            },
                {                text:"Anadolu Lisesi"            }],
            cities: ['Adana', 'Adıyaman', 'Afyon', 'Ağrı', 'Amasya', 'Ankara', 'Antalya', 'Artvin',
                'Aydın', 'Balıkesir', 'Bilecik', 'Bingöl', 'Bitlis', 'Bolu', 'Burdur', 'Bursa', 'Çanakkale',
                'Çankırı', 'Çorum', 'Denizli', 'Diyarbakır', 'Edirne', 'Elazığ', 'Erzincan', 'Erzurum', 'Eskişehir',
                'Gaziantep', 'Giresun', 'Gümüşhane', 'Hakkari', 'Hatay', 'Isparta', 'Mersin', 'İstanbul', 'İzmir',
                'Kars', 'Kastamonu', 'Kayseri', 'Kırklareli', 'Kırşehir', 'Kocaeli', 'Konya', 'Kütahya', 'Malatya',
                'Manisa', 'Kahramanmaraş', 'Mardin', 'Muğla', 'Muş', 'Nevşehir', 'Niğde', 'Ordu', 'Rize', 'Sakarya',
                'Samsun', 'Siirt', 'Sinop', 'Sivas', 'Tekirdağ', 'Tokat', 'Trabzon', 'Tunceli', 'Şanlıurfa', 'Uşak',
                'Van', 'Yozgat', 'Zonguldak', 'Aksaray', 'Bayburt', 'Karaman', 'Kırıkkale', 'Batman', 'Şırnak',
                'Bartın', 'Ardahan', 'Iğdır', 'Yalova', 'Karabük', 'Kilis', 'Osmaniye', 'Düzce'
            ],
            filter: {
                schoolType: "",
                city: "",
                search: ""
            }
        }
    },
    created() {
          var userData=   this.$cookies.get("UserData");
          if(userData.branch.branchType==1){
             this. scholarOptions=[
                {                text:"Fen Lisesi"            },
                {                text:"Anadolu Lisesi"            },
                {                text:"Anadolu İmam Hatip Lisesi"  },
                {                text:"Sosyal Bilimler Lisesi"         },
                {                text:"Anadolu Teknik Programı"     },
                {                text:"Anadolu Meslek Programı"            }]
        }else{
            this. scholarOptions=[
                {                text:"Devlet Üniversitesi"            },
                {                text:"Vakıf Üniversitesi"            },
                {                text:"KKTC Üniversite"            },
                {                text:"Yabancı Üniversite"            }]
        }
        this.filter.schoolType=this.scholarOptions[0].text;

        this.getSchools();
    },
    methods: {
        param(object) {
            
            var parameters = [];

            for (var property in object) {
                parameters.push(encodeURI(property + '=' + object[property]));
            }

            return parameters.join('&');
        },
        changeFilter() {
            this.page = 1;
            this.getSchools();
            
        },
        info(school) {
            this.selectedSchool = school;
            this.isOpen = true;
        },
        async getSchools() {
            this.$center.emit('Loading', 'show');
            var schools = await this.$http.get("Student/Schools/" + (this.page - 1) + "?" + this.param(this.filter));
            this.schools = schools.data.data;
            console.log(this.schools);
            var userData=   this.$cookies.get("UserData");
            if(userData.branch.branchType==1){
                this.schools=this.schools.filter(x=>x.schoolType=="1")
            }

       
          
            this.$center.emit('Loading', 'hide');
        },
        nextPage() {
            this.page++;
            this.getSchools();
        },
        prevPage() {
            if (this.page != 1) {
                this.page--;
                this.getSchools();
            }
        },
        async addTarget(schoolId) {
            await this.$http.post("Student/AddTarget", {
                schoolId
            });
            this.$center.emit("alert", {
                icon: 'far fa-check',
                title: this.$t("AddTarget.Message.Title"),
                description: this.$t("AddTarget.Message.SuccessAdd"),
                type: 'success'
            });
        }
    }
}
</script>
