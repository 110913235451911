<template>
<label>
    <h5>{{question.text}}</h5>
    <button type="button" style="width: 70%;" @click="addImage"> {{question.userText!=null?'Dosya Seçildi':'Dosya Seç'}}</button>
    <button type="button" style="width: 25%;margin-left:5%" v-if="question.userText!=null" @click="openDocument()"> Göster</button>
    <input ref="fileInput" @change="handleImage" type="file" style="display:none" />
</label>
</template>

<script>
export default {
    props: {
        value: {
            type: Array,
            required: true
        }
    },
    computed: {
        question() {
            return this.value
        }
    },
    methods: {
        openDocument() {
            window.open(this.question.userText, '_blank');
        },
        changeEvent() {
            this.$emit('update', this.question);
        },
        addImage() {
            this.$refs.fileInput.click()
        },
        handleImage(e) {
            if (e.target.files == null)
                return;
            const selectedImage = e.target.files[0];
            var formData = new FormData();
            formData.append("file", selectedImage);
            this.$http.put("Student/FileUpload", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(x => {
                this.question.userText = x.data;
                this.changeEvent();
            })
        },
    }
}
</script>

<style scoped>
button {
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    font-weight: 800;
    color: var(--text-color-2);
    background-color: var(--background-color-4);
    border: none;
    border-radius: 4px;
    padding-left: 2em;
    padding-right: 2em;
    cursor: pointer;
    transition: 0.15s ease-in-out;
}
</style>
