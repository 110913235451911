<template>
<label for="men">
    <h5>{{question.text}}</h5>
    <label class="notifications-settingss" style="display: flex;min-width: auto;width: auto;" v-for="choice in question.choices" :key="choice.id">
        <input type="checkbox" v-bind:checked="choice.isUserSelected" @click="changeEvent(choice.id)">
        <span></span>
        <p>{{choice.text}}</p>
    </label>
</label>
</template>

<script>
export default {
    props: {
        value: {
            type: Array,
            required: true
        }
    },
    computed: {
        question() {
            return this.value
        }
    },
    methods: {
        changeEvent(value) {
            var isChoice = this.question.choices.filter(x => x.id == value)[0];
            isChoice.isUserSelected = !isChoice.isUserSelected;
            this.$emit('update', this.question);
        }
    }
}
</script>
