<template>
<label for="men">
    <h5> {{question.text}}</h5>
    <label class="notifications-settingss" style="display: flex;min-width: auto;width: auto;" v-for="choice in question.choices" :key="choice.id">
        <input type="radio" :name="choice.id" :id="choice.id" v-bind:checked="choice.isUserSelected" @click="changeEvent(choice.id)">
        <span></span>
        <p>{{choice.text}}</p>
    </label>
</label>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            required: true
        }
    },
    computed: {
        question() {
            return this.value
        }
    },
    methods: {
        changeEvent(value) {
            this.question.choices.forEach(element => {
                if (element.id == value)
                    element.isUserSelected = true;
                else
                    element.isUserSelected = false;
            });
            this.$emit('update', this.question);
        }
    }
}
</script>
