<template>
<div class="content quiz-detail-content" style="width:100%">

    <div class="main" @mouseenter="()=>{isFocus=true}" @mouseleave="()=>{isFocus=false}">
        <img :src="selectedQuestion.questionFile" alt="">
        <div class="options" v-if="selectedQuestion!=null && isFocus">
            <i class="fas fa-chevron-left" @click="prevQuestion" v-if="index>0"></i>
            <ul v-bind:style="{backgroundColor:getEmptyStatus(selectedQuestion)}">
                <li v-bind:style="{backgroundColor:getStatusColor(answer)}" v-for="answer in selectedQuestion.answers" :key="answer.id" @click="addExamAnswer(selectedQuestion.id,answer.id)">{{answer.text}}</li>
            </ul>
            <i class="fas fa-chevron-right" @click="nextQuestion" v-if="index < test.questions?.length - 1"></i>
        </div>
    </div>

    <div class="sidebar">
        <ul class="lessons-collapse">
            <li class="active">{{ $t("ExamPlayer.QuestionTitle") }}</li>
        </ul>
        <ul class="content">
            <li :class="selectedQuestion==question?'active':null" v-bind:style="{backgroundColor:getEmptyStatus(question)}" v-for="(question,index) in test.questions" :key="question.id" @click="selectQuestion(index,question)">
                <p>{{(index+1)}}</p>
                <span v-bind:style="{backgroundColor:getStatusColor(answer)}" v-for="answer in question.answers" :key="answer.id" @click="addExamAnswer(question.id,answer.id)">{{answer.text}}</span>
            </li>
        </ul>
        <div class="close" @click="isOpen=true">
            <h3>{{ $t("ExamPlayer.EndExamButton") }}</h3>
        </div>
    </div>

    <Modal v-model="isOpen" :fullscreen="false" modal-transition="scale" :click-out="false" :disable-motion="false" max-width="500px" :remove-backdrop="false">
        <div class="theme-change">
            <div class="modal-header">
                <h3>{{ $t("ExamPlayer.Modal.Title") }}</h3>
            </div>
            <div class="con">
                <h4>{{ $t("ExamPlayer.Modal.Description") }}</h4>
                <div class="horver">
                    <span class="active" @click="isOpen=false">{{ $t("ExamPlayer.Modal.ContinueButton") }}</span>
                    <span class="active" @click="endExam()">{{ $t("ExamPlayer.Modal.EndExamButton") }}</span>
                </div>
            </div>
        </div>
    </Modal>

    <Modal v-model="isSolution" :fullscreen="false" modal-transition="scale" :click-out="true" max-width="960px" :disable-motion="false" :remove-backdrop="false">
        <div class="video-questions-modal">
            <ul>
                <li class="active">Videolu Cevap</li>
            </ul>
            <div class="question-box">
                <div class="video">
                    <vimeo-player ref="questionPlayer" v-if="isSolution" :video-url="selectedQuestion.solutionFile" />
                    <!--<iframe v-if="isSolution" :src="selectedQuestion.solutionFile" frameborder="0" allow=" fullscreen; picture-in-picture" allowfullscreen></iframe> -->
                </div>
            </div>
        </div>
    </Modal>

</div>
</template>

<style>
iframe {
    width: 100% !important;
    height: 100% !important;
    min-height: 500px;
}
</style>

<script>
import { Modal } from "vue-neat-modal";
var emitter = require('tiny-emitter/instance');
export default {
    components: {
        Modal,
    },
    data() {
        return {
            test: [],
            selectedQuestion: {},
            index: 0,
            isOpen: false,
            isSolution: false,
            isFocus: false
        }
    },
    created() {
        this.getData();
        var scoped = this;
        emitter.on('ExamSolutionOpen', function () {
            scoped.isSolution = true;
        });
    },
    methods: {
        branchType() {
            var branchData = this.$cookies.get("UserData");
            if (branchData.branch != null && branchData.branch.branchType == 1)
                return false;
            return true;
        },
        filterQuestionAnswer(test) {
            console.log(test, this.branchType());
            if (!this.branchType()) {
                if (test.questions.length > 0) {
                    test.questions.forEach(qu => {
                        qu.answers = qu.answers.filter(q => q.text != 'E');
                    });
                }
            }
            return test;
        },
        getEmptyStatus(question) {
            if (this.test.status == "HasResulted") {
                var userAnswer = question.answers.filter(x => x.isSelected == true);
                if (userAnswer == null || userAnswer.length == 0)
                    return "#1597bb";
            }
        },
        getStatusColor(answer) {
            if (this.test.status == "HasResulted") {
                if (answer.isTrue == true)
                    return "#17bf63";
                if (answer.isSelected == true && answer.isTrue == false)
                    return "#ff003a";
            } else {
                if (answer.isSelected == true)
                    return "#17bf63";
            }
        },
        async getData() {
            this.$center.emit('Loading', 'show');
            this.$http.get("Student/Test/" + this.$route.params.examId).then(x => {
                this.test = this.filterQuestionAnswer(x.data.data);
                if (this.test.questions != []) {
                    this.selectedQuestion = this.test.questions[0];
                }
                this.getTestType();
                var examData = {
                    title: this.test.title,
                    time: this.test.time,
                    status: this.test.status,
                    isIndefinite: this.test.isIndefinite
                };
                this.$center.emit('ExamDataChanged', examData);
                this.$center.emit('Loading', 'hide');
            }).catch(error => {
                //alert(error);
                this.$center.emit('Loading', 'hide');
            });
        },
        getTestType() {
            if (this.test.status == 'Closed') {
                this.$router.go(-1);
                this.$center.emit("alert", {
                    icon: 'far fa-check',
                    title: this.$t("ExamPlayer.ClosedNotifiTitle"),
                    description: this.$t("ExamPlayer.ClosedNotifiDescription"),
                    type: 'error'
                });
            }
        },
        selectQuestion(index, question) {
            this.index = index;
            this.selectedQuestion = question;
        },
        nextQuestion() {
            this.index++;
            this.selectedQuestion = this.test.questions[this.index];
        },
        prevQuestion() {
            this.index--;
            this.selectedQuestion = this.test.questions[this.index];
        },
        async addExamAnswer(questionId, answerId) {
            if (this.test.status == 'Waiting') {
                var request = {
                    testId: this.test.testId,
                    questionId: questionId,
                    answerId: answerId
                };
                var addAnswer = await this.$http.post("Student/AddTestAnswer", request);
                if (addAnswer.data.data == true) {
                    var oldAnswer = this.test.questions.filter(x => x.id == questionId)[0].answers.filter(x => x.id == answerId)[0];
                    if (oldAnswer.isSelected == true) {
                        oldAnswer.isSelected = false;
                    } else {
                        this.test.questions.filter(x => x.id == questionId)[0].answers.forEach(element => {
                            if (element.id == answerId)
                                element.isSelected = true;
                            else
                                element.isSelected = false;
                        });
                    }
                }
            }
        },
        async endExam() {
            this.isOpen = false;
            var endExam = await this.$http.get("Student/EndTest/" + this.$route.params.examId);
            if (endExam.data.data == true) {
                this.$router.go(-1);
                this.$center.emit("alert", {
                    icon: 'far fa-check',
                    title: "Sınavınız Tamamlandı",
                    description: "Sonuçlarınız işlendiğinde haber verilecek",
                    type: 'success'
                });
            }
        }
    }
}
</script>

<style scoped>
.card {
    background-color: #fff;
    border-radius: 4px;
    padding: 24px;
}

.card h1 {
    margin-top: 0;
}
</style>
