<template>
  <div class="nav">
    <div class="header-btn" @click="openDraw()">
      <i class="fal fa-bars"></i>
    </div>
    <div class="title">
      <h3>{{appInfo.name}}</h3>
    </div>
  </div>
</template>

<script>
var emitter = require('tiny-emitter/instance');
import appInfo from '@config';
export default {
  data() {
    return {
      appInfo
    }
  },
  methods: {
    openDraw() {
      emitter.emit('MenuOpen', 'open');
    }
  }
}
</script>
