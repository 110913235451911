<template>
<div class="lessons-watch-header">
    <div class="title">
        <i class="far fa-angle-left" @click="backExams()"></i>
        <h1>{{title}}</h1>
    </div>
    <div class="more">
        <span @click="openSolutionFile">{{formatterTime}}</span>
        <i class="far fa-flag-alt" @click="addReport"></i>
        <i class="fas fa-expand" @click="screen()"></i>
    </div>
</div>
</template>

<script>
var emitter = require('tiny-emitter/instance');

export default {
    data() {
        return {
            isFullScreen: false,
            isIndefinite: false,
            title: '',
            status: '',
            time: '',
            hour: 0,
            minute: 0,
            second: 0,
            formatterTime: '00:00:00'
        }
    },
    created() {
        var scoped = this;
        emitter.on('ExamDataChanged', function (arg1) {
            scoped.title = arg1.title;
            scoped.time = arg1.time;
            scoped.status = arg1.status;
            scoped.isIndefinite = arg1.isIndefinite;
            scoped.statusChanged();
        });
    },
    methods: {
        screen() {
            if (!document.fullscreenElement && // alternative standard method
                !document.mozFullScreenElement && !document.webkitFullscreenElement) { // current working methods
                if (document.documentElement.requestFullscreen) {
                    document.documentElement.requestFullscreen();
                } else if (document.documentElement.mozRequestFullScreen) {
                    document.documentElement.mozRequestFullScreen();
                } else if (document.documentElement.webkitRequestFullscreen) {
                    document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
                }
            } else {
                if (document.cancelFullScreen) {
                    document.cancelFullScreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.webkitCancelFullScreen) {
                    document.webkitCancelFullScreen();
                }
            }
        },
        backExams() {
            this.$router.go(-1);
        },
        openSolutionFile() {
            if (this.status == "HasResulted") {
                this.$center.emit('ExamSolutionOpen');
            }
        },
        fullScreen() {
            this.isFullScreen = !this.isFullScreen;
            this.$center.emit('FullScreen', this.isFullScreen);
        },
        cropped() {
            if (this.isIndefinite == true) {
                this.formatterTime = "Süre Sınırı Yok";
            } else {
                this.hour = parseInt(this.time.split(':')[0]);
                this.minute = parseInt(this.time.split(':')[1]);
                this.second = parseInt(this.time.split(':')[2].split('.')[0]);
                this.setTimer();
            }
            console.log(this.time);
        },
        statusChanged() {
            if (this.status == "Waiting") {
                this.cropped();
            }
            if (this.status == "Processing") {
                this.formatterTime = "Sonuçlarınız İşleniyor";
            }
            if (this.status == "HasResulted") {
                this.formatterTime = "Videolu Çözüm";
            }
        },
        addReport() {
            this.$center.emit('SettingsOpen', "AddReport");
        },
        setTimer() {
            var scoped = this;
            var hour = this.hour;
            var minute = this.minute;
            var second = this.second;
            var x = setInterval(function () {

                if (second == 0 && minute == 0 && hour == 0) {
                    clearInterval(x);
                    scoped.formatterTime = "Süreniz Bitti";
                    return;
                }

                second--;
                if (second == 0) {
                    if (minute != 0) {
                        minute--;
                        second = 59;
                    }
                }
                if (minute == 0) {
                    if (hour != 0) {
                        hour--;
                        minute = 59;
                    }
                }

                var hourFormatted = (hour > 9) ? hour : '0' + hour;
                var minuteFormatted = (minute > 9) ? minute : '0' + minute;
                var secondFormatted = (second > 9) ? second : '0' + second;
                scoped.formatterTime = hourFormatted + ':' + minuteFormatted + ':' + secondFormatted;

            }, 1000);
        }
    },
}
</script>
