<template>
<div class="language-change" style="display: none">
    <div class="modal-header">
        <h3>{{$t("Modal.Langue.Title")}}</h3>
    </div>
    <div class="con">
        <ul>
            <li @click="setLangue('tr')">
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background: new 0 0 512 512" xml:space="preserve">
                    <rect y="85.337" style="fill: #d80027" width="512" height="341.326" />
                    <g>
                        <polygon style="fill: #f0f0f0" points="247.213,216.787 264.807,241.033 293.3,231.794 275.679,256.018 293.271,280.262 
  264.787,270.988 247.166,295.213 247.184,265.258 218.7,255.983 247.194,246.744 	" />
                        <path style="fill: #f0f0f0" d="M199.202,316.602c-33.469,0-60.602-27.133-60.602-60.602s27.133-60.602,60.602-60.602
  c10.436,0,20.254,2.639,28.827,7.284c-13.448-13.152-31.84-21.269-52.135-21.269c-41.193,0-74.586,33.394-74.586,74.586
  s33.394,74.586,74.586,74.586c20.295,0,38.687-8.117,52.135-21.269C219.457,313.963,209.638,316.602,199.202,316.602z" />
                    </g>
                </svg>
                <h4>{{$t("Modal.Langue.Turkish")}}</h4>
                <i class="fas fa-check" v-if="langue == 'tr'"></i>
            </li>
            <li @click="setLangue('en')">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512.001 512.001" style="enable-background: new 0 0 512.001 512.001" xml:space="preserve">
                    <path style="fill: #f5f5f5" d="M503.172,423.725H8.828c-4.875,0-8.828-3.953-8.828-8.828V97.104c0-4.875,3.953-8.828,8.828-8.828
h494.345c4.875,0,8.828,3.953,8.828,8.828v317.793C512,419.773,508.047,423.725,503.172,423.725z" />
                    <g>
                        <path style="fill: #ff4b55" d="M512,114.081H0V97.104c0-4.875,3.953-8.828,8.828-8.828h494.345c4.875,0,8.828,3.953,8.828,8.828
  L512,114.081L512,114.081z" />
                        <rect y="191.491" style="fill: #ff4b55" width="512" height="25.804" />
                        <rect y="139.881" style="fill: #ff4b55" width="512" height="25.804" />
                        <path style="fill: #ff4b55" d="M512,268.903H8.828c-4.875,0-8.828-3.953-8.828-8.828v-16.977h512V268.903z" />
                        <rect y="346.321" style="fill: #ff4b55" width="512" height="25.804" />
                        <path style="fill: #ff4b55" d="M503.172,423.725H8.828c-4.875,0-8.828-3.953-8.828-8.828v-16.976h512v16.977
  C512,419.773,508.047,423.725,503.172,423.725z" />
                        <rect y="294.711" style="fill: #ff4b55" width="512" height="25.804" />
                    </g>
                    <path style="fill: #41479b" d="M229.517,88.277H8.828C3.953,88.277,0,92.229,0,97.104v162.97c0,4.875,3.953,8.828,8.828,8.828
h220.69c4.875,0,8.828-3.953,8.828-8.828V97.104C238.345,92.229,234.392,88.277,229.517,88.277z" />
                    <g>
                        <path style="fill: #f5f5f5" d="M24.789,108.538l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67l1.864,5.889
  c0.242,0.762-0.627,1.394-1.278,0.928L24,122.843l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889l-4.969-3.67
  c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C23.463,107.779,24.535,107.779,24.789,108.538z" />
                        <path style="fill: #f5f5f5" d="M24.789,139.192l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67l1.864,5.889
  c0.242,0.762-0.627,1.394-1.278,0.928L24,153.497l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889l-4.969-3.67
  c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C23.463,138.434,24.535,138.434,24.789,139.192z" />
                        <path style="fill: #f5f5f5" d="M24.789,169.846l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67l1.864,5.889
  c0.242,0.762-0.627,1.394-1.278,0.928L24,184.151l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889l-4.969-3.67
  c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C23.463,169.088,24.535,169.088,24.789,169.846z" />
                        <path style="fill: #f5f5f5" d="M24.789,200.501l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67l1.864,5.889
  c0.242,0.762-0.627,1.394-1.278,0.928L24,214.806l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889l-4.969-3.67
  c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C23.463,199.742,24.535,199.742,24.789,200.501z" />
                        <path style="fill: #f5f5f5" d="M24.789,231.155l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67l1.864,5.889
  c0.242,0.762-0.627,1.394-1.278,0.928L24,245.46l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889l-4.969-3.67
  c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C23.463,230.395,24.535,230.395,24.789,231.155z" />
                        <path style="fill: #f5f5f5" d="M48.582,123.567l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67l1.864,5.889
  c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889l-4.969-3.67
  c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C47.255,122.809,48.329,122.809,48.582,123.567z" />
                        <path style="fill: #f5f5f5" d="M48.582,154.222l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67l1.864,5.889
  c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.165-1.278-0.928l1.864-5.889l-4.969-3.67
  c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C47.255,153.463,48.329,153.463,48.582,154.222z" />
                        <path style="fill: #f5f5f5" d="M48.582,184.876l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67l1.864,5.889
  c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889l-4.969-3.67
  c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C47.255,184.117,48.329,184.117,48.582,184.876z" />
                        <path style="fill: #f5f5f5" d="M48.582,215.53l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67l1.864,5.889
  c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889l-4.969-3.67
  c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C47.255,214.772,48.329,214.772,48.582,215.53z" />
                        <path style="fill: #f5f5f5" d="M72.375,108.538l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67l1.864,5.889
  c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889l-4.969-3.67
  c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C71.049,107.779,72.122,107.779,72.375,108.538z" />
                        <path style="fill: #f5f5f5" d="M72.375,139.192l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67l1.864,5.889
  c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889l-4.969-3.67
  c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C71.049,138.434,72.122,138.434,72.375,139.192z" />
                        <path style="fill: #f5f5f5" d="M72.375,169.846l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67l1.864,5.889
  c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889l-4.969-3.67
  c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C71.049,169.088,72.122,169.088,72.375,169.846z" />
                        <path style="fill: #f5f5f5" d="M72.375,200.501l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67l1.864,5.889
  c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889l-4.969-3.67
  c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C71.049,199.742,72.122,199.742,72.375,200.501z" />
                        <path style="fill: #f5f5f5" d="M72.375,231.155l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67l1.864,5.889
  c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889l-4.969-3.67
  c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C71.049,230.395,72.122,230.395,72.375,231.155z" />
                        <path style="fill: #f5f5f5" d="M96.169,123.567l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67l1.864,5.889
  c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889l-4.969-3.67
  c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C94.842,122.809,95.916,122.809,96.169,123.567z" />
                        <path style="fill: #f5f5f5" d="M96.169,154.222l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67l1.864,5.889
  c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.165-1.278-0.928l1.864-5.889l-4.969-3.67
  c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C94.842,153.463,95.916,153.463,96.169,154.222z" />
                        <path style="fill: #f5f5f5" d="M96.169,184.876l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67l1.864,5.889
  c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889l-4.969-3.67
  c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C94.842,184.117,95.916,184.117,96.169,184.876z" />
                        <path style="fill: #f5f5f5" d="M96.169,215.53l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67l1.864,5.889
  c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889l-4.969-3.67
  c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C94.842,214.772,95.916,214.772,96.169,215.53z" />
                        <path style="fill: #f5f5f5" d="M119.962,108.538l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67
  l1.864,5.889c0.242,0.762-0.627,1.394-1.278,0.928l-5.026-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889
  l-4.969-3.67c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C118.636,107.779,119.709,107.779,119.962,108.538z" />
                        <path style="fill: #f5f5f5" d="M119.962,139.192l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67
  l1.864,5.889c0.242,0.762-0.627,1.394-1.278,0.928l-5.026-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889
  l-4.969-3.67c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C118.636,138.434,119.709,138.434,119.962,139.192z" />
                        <path style="fill: #f5f5f5" d="M119.962,169.846l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67
  l1.864,5.889c0.242,0.762-0.627,1.394-1.278,0.928l-5.026-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889
  l-4.969-3.67c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C118.636,169.088,119.709,169.088,119.962,169.846z" />
                        <path style="fill: #f5f5f5" d="M119.962,200.501l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67
  l1.864,5.889c0.242,0.762-0.627,1.394-1.278,0.928l-5.026-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889
  l-4.969-3.67c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C118.636,199.742,119.709,199.742,119.962,200.501z" />
                        <path style="fill: #f5f5f5" d="M119.962,231.155l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67
  l1.864,5.889c0.242,0.762-0.627,1.394-1.278,0.928l-5.026-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889
  l-4.969-3.67c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C118.636,230.395,119.709,230.395,119.962,231.155z" />
                        <path style="fill: #f5f5f5" d="M143.755,123.567l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67
  l1.864,5.889c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889
  l-4.969-3.67c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C142.43,122.809,143.502,122.809,143.755,123.567z" />
                        <path style="fill: #f5f5f5" d="M143.755,154.222l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67
  l1.864,5.889c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.165-1.278-0.928l1.864-5.889
  l-4.969-3.67c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C142.43,153.463,143.502,153.463,143.755,154.222z" />
                        <path style="fill: #f5f5f5" d="M143.755,184.876l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67
  l1.864,5.889c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889
  l-4.969-3.67c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C142.43,184.117,143.502,184.117,143.755,184.876z" />
                        <path style="fill: #f5f5f5" d="M143.755,215.53l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67l1.864,5.889
  c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889l-4.969-3.67
  c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C142.43,214.772,143.502,214.772,143.755,215.53z" />
                        <path style="fill: #f5f5f5" d="M167.549,108.538l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67
  l1.864,5.889c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889
  l-4.969-3.67c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C166.222,107.779,167.296,107.779,167.549,108.538z" />
                        <path style="fill: #f5f5f5" d="M167.549,139.192l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67
  l1.864,5.889c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889
  l-4.969-3.67c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C166.222,138.434,167.296,138.434,167.549,139.192z" />
                        <path style="fill: #f5f5f5" d="M167.549,169.846l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67
  l1.864,5.889c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889
  l-4.969-3.67c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C166.222,169.088,167.296,169.088,167.549,169.846z" />
                        <path style="fill: #f5f5f5" d="M167.549,200.501l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67
  l1.864,5.889c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889
  l-4.969-3.67c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C166.222,199.742,167.296,199.742,167.549,200.501z" />
                        <path style="fill: #f5f5f5" d="M167.549,231.155l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67
  l1.864,5.889c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889
  l-4.969-3.67c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C166.222,230.395,167.296,230.395,167.549,231.155z" />
                        <path style="fill: #f5f5f5" d="M191.342,123.567l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67
  l1.864,5.889c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889
  l-4.969-3.67c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C190.016,122.809,191.09,122.809,191.342,123.567z" />
                        <path style="fill: #f5f5f5" d="M191.342,154.222l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67
  l1.864,5.889c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.165-1.278-0.928l1.864-5.889
  l-4.969-3.67c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C190.016,153.463,191.09,153.463,191.342,154.222z" />
                        <path style="fill: #f5f5f5" d="M191.342,184.876l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67
  l1.864,5.889c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889
  l-4.969-3.67c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C190.016,184.117,191.09,184.117,191.342,184.876z" />
                        <path style="fill: #f5f5f5" d="M191.342,215.53l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67l1.864,5.889
  c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889l-4.969-3.67
  c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C190.016,214.772,191.09,214.772,191.342,215.53z" />
                        <path style="fill: #f5f5f5" d="M215.136,108.538l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67
  l1.864,5.889c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889
  l-4.969-3.67c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C213.81,107.779,214.882,107.779,215.136,108.538z" />
                        <path style="fill: #f5f5f5" d="M215.136,139.192l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67
  l1.864,5.889c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889
  l-4.969-3.67c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C213.81,138.434,214.882,138.434,215.136,139.192z" />
                        <path style="fill: #f5f5f5" d="M215.136,169.846l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67
  l1.864,5.889c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889
  l-4.969-3.67c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C213.81,169.088,214.882,169.088,215.136,169.846z" />
                        <path style="fill: #f5f5f5" d="M215.136,200.501l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67
  l1.864,5.889c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889
  l-4.969-3.67c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C213.81,199.742,214.882,199.742,215.136,200.501z" />
                        <path style="fill: #f5f5f5" d="M215.136,231.155l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67
  l1.864,5.889c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889
  l-4.969-3.67c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C213.81,230.395,214.882,230.395,215.136,231.155z" />
                    </g>
                </svg>

                <h4>{{$t("Modal.Langue.English")}}</h4>
                <i class="fas fa-check" v-if="langue == 'en'"></i>
            </li>
        </ul>
        <button @click="saveLangue">{{$t("Modal.Langue.SaveButton")}}</button>
    </div>
</div>
</template>

<script>
var emitter = require('tiny-emitter/instance');
export default {
    data() {
        return {
            langue: "tr"
        }
    },
    methods: {
        setLangue(lang) {
            this.langue = lang;
        },
        async saveLangue() {
            if (this.$cookies.get("Token") != null) {
                await this.$http.post("Auth/langue", {
                    langue: this.langue
                });
                this.loadLangue();
            }
        },
        async loadLangue() {
            if (this.$cookies.get("Token") != null) {
                this.$center.emit('Loading', 'show');
                var langue = await this.$http.get("Auth/langue");
                var langueData = langue.data.data;
                if (langueData != null)
                    this.langue = langueData.langue;

                this.$center.emit('Loading', 'hide');

                //i18n Reject
                this.$i18n.setLocale(this.langue);
            }
        }
    },
    mounted() {
        this.loadLangue();
        var scoped = this;
        emitter.on('LangueChanged', () => {
            scoped.loadLangue();
        });
    },
}
</script>
