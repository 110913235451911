<template>
<div > 
    <div class="title">
        <h2>{{ $t("Home.Working.Title") }}</h2>
    </div>
    <div class="con">

        <div class="working-item" v-for="mn in main" :key="mn.refId">
            <div class="top">
                <div class="icon">
                    <i class="fad fa-play" v-if="mn.type=='VIDEO'"></i>
                    <i class="fad fa-clipboard -list" v-else></i>
                </div>
                <div class="detail">
                    <h3>{{mn.refName.substring(0,35)}}...</h3>
                    <p>{{mn.topicName}}</p>
                </div>
            </div>
            <div class="bottom">
                <div class="progress-bar">
                    <span v-bind:style="{width:mn.percent+'%'}"></span>
                </div>
                <div class="more">
                    <button @click="openPage(mn)">{{mn.type=='VIDEO'?'İzlemeye Devam Et':'Test\'e Devam Et'}}</button>
                </div>
            </div>
        </div>

    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            main: []
        }
    },
    created() {
        this.getData();
    },
    methods: {
        async getData() {
            var main = await this.$http.get("Student/Main");
            this.main = main.data.data;
        },
        openPage(data) {
            if (data.type == 'VIDEO') {
                this.$router.push('/watching/' + data.topicId + '/' + data.videoGroupId + '/video/' + data.refId);
            } else {
                this.$router.push({
                    name: "ExamPlayer",
                    params: {
                        examId: data.refId
                    }
                });
            }
        }
    }
}
</script>
