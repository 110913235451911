<template>
<div class="item friends">
    <div class="title">
        <h2>{{ $t("Home.Friends.Title") }}</h2>
    </div>
    <div class="con" v-if="isLoading">
        <div class="grid-item">
            <i class="fad fa-eye"></i>
            <div>{{friends.videoCount}}</div>
            <p>{{ $t("Home.Friends.Video") }}</p>
        </div>
        <div class="grid-item">
            <i class="fad fa-eye"></i>
            <div>{{friends.questionCount}}</div>
            <p>{{ $t("Home.Friends.Question") }}</p>
        </div>
        <div class="grid-item">
            <i class="fad fa-eye"></i>
            <div>{{friends.examCount}}</div>
            <p>{{ $t("Home.Friends.Exam") }}</p>
        </div>
        <div class="grid-item">
            <i class="fad fa-eye"></i>
            <div>{{friends.lackCount}}</div>
            <p>{{ $t("Home.Friends.Gain") }}</p>
        </div>
        <div class="grid-item">
            <i class="fad fa-eye"></i>
            <div>{{friends.badgeCount}}</div>
            <p>{{ $t("Home.Friends.Badge") }}</p>
        </div>
    </div>
    <div class="con null" v-else>
        <h3>Veriler Yükleniyor...</h3>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            friends: [],
            isLoading: false
        }
    },
    created() {
        this.getData();
    },
    methods: {
        async getData() {
            this.isLoading = false;
            var friends = await this.$http.get("Student/Friends");
            this.friends = friends.data.data;
            this.isLoading = true;
        },
    }
}
</script>
 