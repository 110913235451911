<template>
<div class="content quizzes">
    <div class="sidebar" style="left: 100%" id="exam-filter">
        <div class="quizzes-title">
            <i class="far fa-chevron-left" @click="openFilter()"></i>
            <h2>{{$t("Exam.Title")}}</h2>
        </div>
        <div class="quizzes-filter">
            <div class="select-box">
                <h5>{{$t("Exam.ExamType")}}</h5>
                <label for="men">
                    <input type="radio" id="men" name="gender" :checked="type==0" @change="selectedType(0)" />
                    <span></span>
                    <p> {{$t("Exam.TopicExam")}}</p>
                </label>
                <label for="woman">
                    <input type="radio" id="woman" name="gender" :checked="type==1" @change="selectedType(1)" />
                    <span></span>
                    <p>{{$t("Exam.SmartExam")}}</p>
                </label>
                <label for="none">
                    <input type="radio" id="none" name="gender" :checked="type==2" @change="selectedType(2)" />
                    <span></span>
                    <p>Tüm Deneme Sınavları</p>
                </label>
                <!-- <label for="none">
                    <input type="radio" id="none" name="gender" :checked="type==3" @change="selectedType(3)" />
                    <span></span>
                    <p>Kurum Deneme Sınavları</p>
                </label>
                <label for="none">
                    <input type="radio" id="none" name="gender" :checked="type==4" @change="selectedType(4)" />
                    <span></span>
                    <p>Şube Deneme Sınavları</p>
                </label> -->
            </div>
            <filter-area ref="filterComp" css_class="select-box" @FilterExecute="filterExecute" />
        </div>
    </div>

    <div class="main">
        <div class="list-group">
            <div class="quizzes-item" v-for="exam in data" :key="exam.id">

                <div class="info">
                    <h3 class="title">{{exam.title}}</h3>
                    <p class="date">{{formatterDate(exam.startDate,0)}}<span>{{formatterDate(exam.startDate,1)}}</span></p>
                    <p class="date">{{formatterDate(exam.endDate,0)}}<span>{{formatterDate(exam.endDate,1)}}</span></p>
                    <button class="button" v-bind:style="{backgroundColor:getExamStatus(exam.status)}" @click="openExam(exam)">
                        {{getExamStatusText(exam.status)}}
                    </button>
                </div>

                <div class="chart" v-if="exam.status=='HasResulted'">
                     <button class="button" style="background-color: #334756">
                        Puan : {{parseFloat(exam.point).toFixed(2)}}
                    </button>
                    <button class="button success">
                        Doğru : {{exam.correct}}
                    </button>
                    <button class="button empty">
                        Boş : {{exam.empty}}
                    </button>
                    <button class="button wrong">
                        Yanlış : {{exam.wrong}}
                    </button>
                </div>

            </div>
        </div>
    </div>

    <div class="filter-open-button" @click="openFilter()">
        <i class="far fa-filter"></i>
    </div>

</div>
</template>

<script>
import FilterComponent from "../components/FilterComponent.vue"
export default {
    components: {
        "filter-area": FilterComponent
    },
    data() {
        return {
            data: [],
            oldData: [],
            type: 2
        }
    },
    created() {
        if (this.$route.query.type == null)
            this.getAllExam("all");
        else {
            this.type = parseInt(this.$route.query.type);
            this.selectedType(parseInt(this.$route.query.type));
        }
    },
    methods: {
        formatterDate(date, index) {
            date = date.replaceAll('-', '.');
            date = date.split(' ');
            return date[index];
        },
        getSeries(exam) {
            return [exam.correct, exam.wrong, exam.empty];
        },
        openExam(exam) {
            if (exam.status != "Closed") {
                if (exam.isTrialExam == true) {
                    this.$router.push({
                        name: "TrialExamPlayer",
                        params: {
                            examId: exam.testId
                        }
                    });
                } else {
                    this.$router.push({
                        name: "ExamPlayer",
                        params: {
                            examId: exam.testId
                        }
                    });
                }

            }
        },
        selectedType(type) {
            this.data = [];
            switch (type) {
                case 0:
                    this.getTopicExam();
                    break;
                case 1:
                    this.getSmartExam();
                    break;
                case 2:
                    this.getAllExam("all");
                    break;
                case 3:
                    this.getAllExam("company");
                    break;
                case 4:
                    this.getAllExam("branch");
                    break;
                default:
                    break;
            }
            this.changeUrl(type);
        },
        getTopicExam() {
            this.$center.emit('Loading', 'show');
            this.$http.get("Student/Test").then(x => {
                this.data = x.data.data;
                this.oldData = x.data.data;
                this.$nextTick(function () {
                    this.$refs.filterComp.executeFilterUrl();
                    this.$center.emit('Loading', 'hide');
                });
            }).catch(()=> {
                //alert(error);
                this.$center.emit('Loading', 'hide');
            });
        },
        getSmartExam() {
            this.$center.emit('Loading', 'show');
            this.$http.get("Student/SmartTest").then(x => {
                this.data = x.data.data;
                this.oldData = x.data.data;
                this.$center.emit('Loading', 'hide');
            }).catch(() => {
                //alert(error);
                this.$center.emit('Loading', 'hide');
            });
        },
        getAllExam(query) {
            this.$center.emit('Loading', 'show');
            this.$http.get("Exam/StudentExams?query=" + query).then(x => {
                this.data = x.data.data;
                this.oldData = x.data.data;
                this.$center.emit('Loading', 'hide');
            }).catch(() => {
                //alert(error);
                this.$center.emit('Loading', 'hide');
            });
        },
        openFilter() {
            var sideBar = document.getElementById("exam-filter");
            if (sideBar.style.left == "100%") {
                sideBar.style.left = "0";
            } else if (sideBar.style.left == "0px") {
                sideBar.style.left = "100%";
            }
        },
        filterExecute(filter) {
            console.log("Deneme Deneme");
            this.$center.emit('Loading', 'show');
            var filteredData = this.oldData;
            if (filter.lesson != 0)
                filteredData = filteredData.filter(x => x.lessonId == filter.lesson);
            if (filter.childLesson != 0)
                filteredData = filteredData.filter(x => x.childLessonId == filter.childLesson);
            if (filter.topic != 0)
                filteredData = filteredData.filter(x => x.topicId == filter.topic);
            if (filter.videoGroup != 0)
                filteredData = filteredData.filter(x => x.videoGroupId == filter.videoGroup);
            this.data = filteredData;
            this.$center.emit('Loading', 'hide');
        },
        getExamStatus(status) {
            switch (status) {
                case 'Open':
                    return '#17bf63'
                case 'Waiting':
                    return '#6666ff'
                case 'Closed':
                    return '#aaaaaa'
                case 'Processing':
                    return '#ffad1f'
                case 'HasResulted':
                    return '#f45d22'
            }
        },
        getExamStatusText(status) {
            switch (status) {
                case 'Open':
                    return this.$t("Exam.StarButton");
                case 'Waiting':
                    return this.$t("Exam.ContinueButton");
                case 'Closed':
                    return this.$t("Exam.ClosedButton");
                case 'Processing':
                    return this.$t("Exam.ProcessingButton");
                case 'HasResulted':
                    return this.$t("Exam.ResultButton");
            }
        },
        changeUrl(type) {
            this.$router.replace({ query: { type: type } });
        }
    }
}
</script>
