<template>
<div class="content profilePage">
    <div class="main" style="width:100%">
        <div class="profile-box" v-for="notice in notices" :key="notice.id">
            <div class="title">
                <h3>{{notice.title}}</h3>
                <h6>{{notice.sendTime}}</h6>
            </div>
            <form class="notification">
                <label>
                    <h5>
                        {{notice.definition}}
                    </h5>
                </label>
            </form>
        </div>
    </div>

</div>
</template>

<script>
import appInfo from '@config';
export default {
    data() {
        return {
            notices: {},
            appInfo
        }
    },
    created() {
        this.getProfile();
    },
    methods: {
        async getProfile() {
            this.$center.emit('Loading', 'show');
            var notices = await this.$http.get("Auth/notices");
            this.notices = notices.data.data;
            this.$center.emit('Loading', 'hide');
        },
    }
}
</script>
