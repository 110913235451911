<template>
<div class="item last-exam">
    <div class="con null" v-if="lastExam.length==0">
        <h3>İlk deneme sınavına hemen gir</h3>
        <button @click="openExam()">Sınava Gir</button>
    </div>
    <div class="con" v-else>
        <div class="detail">
            <h4>{{lastExam.title}}</h4>
            <p class="point">
                <span>{{ $t("Home.LastExam.PointType") }}</span>
                <span>{{lastExam.mergedPoint}}</span>
            </p>
            <p v-if="lastExam.firstExam">{{lastExam.firstExam.correct+lastExam.firstExam.wrong+lastExam.firstExam.empty}} Soruda {{lastExam.firstExam.correct}} Doğrun {{lastExam.firstExam.wrong}} Yanlışın {{lastExam.firstExam.empty}} Boş'un var</p>
            <p>{{lastExam.correct+lastExam.wrong+lastExam.empty}} Soruda {{lastExam.correct}} Doğrun {{lastExam.wrong}} Yanlışın {{lastExam.empty}} Boş'un var</p>
        </div>
        <div class="graphic">
            <apexchart height="120" :options="chartOptions" :series="getSeries(lastExam)" />
        </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            lastExam: [],
            series: [],
            chartOptions: {
                chart: {
                    type: 'pie',
                    toolbar: {
                        show: false,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                legend: { show: false },
                comparedResult: [2, -3, 8],
                labels: ['Doğru', 'Yanlış', 'Boş'],
                stroke: { width: 0 },
                colors: ['#00E396', '#FF4560', '#008FFB'],
                grid: {
                    padding: {
                        right: -30,
                        bottom: -10,
                        left: -30,
                    },
                },
                responsive: [{
                        breakpoint: 1325,
                        options: {
                            chart: {
                                height: 100,
                            },
                        },
                    },
                    {
                        breakpoint: 1200,
                        options: {
                            chart: {
                                height: 120,
                            },
                        },
                    },
                    {
                        breakpoint: 1045,
                        options: {
                            chart: {
                                height: 100,
                            },
                        },
                    },
                    {
                        breakpoint: 992,
                        options: {
                            chart: {
                                height: 120,
                            },
                        },
                    },
                ],
            },
        }
    },
    created() {
        this.getData();
    },
    methods: {
        async getData() {
            var lastExam = await this.$http.get("Exam/LastPoint");
            if (lastExam.data.data != null) {
                this.lastExam = lastExam.data.data;
                this.lastExam.mergedPoint=this.lastExam.point.toFixed(2);
                /*if(this.lastExam.firstExam==null){
                    lastExam.firstExam=false;
                    this.lastExam.mergedPoint=this.lastExam.point;

                }else{

                    this.lastExam.mergedPoint=parseFloat((this.lastExam.firstExam.point *0.4 + this.lastExam.point)+100).toFixed(2);
                }*/
              
            }
        },
        openExam() {
            this.$router.push({
                name: "Exams",
                query: {
                    type: 2
                }
            });
        },
        getSeries(exam) {
            return [exam.correct, exam.wrong, exam.empty];
        },
    },
}
</script>
