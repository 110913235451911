<template>
<label>
    <h5>{{question.text}}</h5>
    <input type="text" placeholder="Lütfen bu alanı doldurun" v-model="question.userText" @input="changeEvent">
</label>
</template>

<script>
export default {
    props: {
        value: {
            type: Array,
            required: true
        }
    },
    computed: {
        question() {
            return this.value
        }
    },
    methods: {
        changeEvent() {
            this.$emit('update', this.question);
        }
    }
}
</script>
