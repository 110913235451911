<template>
<div class="content lessons-watch-content" style="width:100vw;margin-top:64px">

    <div class="sidebar" v-if="!isFullScreen">
        <div class="road-map">
            <div class="road-map-item" v-for="vgroup in videoGroups" :key="vgroup.id">
                <div class="road-map-item-title" @click="changeVideoGroup(vgroup)">
                    <h3>{{vgroup.name}}</h3>
                    <i class="fas" v-bind:class="activeVideoGroup==vgroup?'fa-chevron-up':'fa-chevron-down'"></i>
                </div>
                <ul class="road-map-item-content" v-if="activeVideoGroup==vgroup">
                    <li class="road-map-item-content-item" v-for="docuement in getChildData(vgroup)" :key="docuement.referanceId" @click="setQuery(vgroup.id,docuement.referanceId,docuement.type)">
                        <i :class="getDocuemntType(docuement.type)"></i>
                        <h4>{{docuement.name}}</h4>
                        <span style="background-color: #17bf63;" v-if="docuement.type == 'Test'">{{docuement.data!=null?docuement.data.correct:0}}</span>
                        <span style="background-color: #ff003a;" v-if="docuement.type == 'Test'">{{docuement.data!=null?docuement.data.wrong:0}}</span>
                        <span style="background-color: #888888;" v-if="docuement.type == 'Test'">{{docuement.data!=null?docuement.data.empty:0}}</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="main">
        <router-view />
    </div>
    <!-- <detail-area /> -->
</div>
</template>

<script>
// import Area from "./Watching/Area.vue"
var emitter = require('tiny-emitter/instance');
export default {
    // components: {
    //     "detail-area": Area
    // },
    data() {
        return {
            isFullScreen: false,
            videoGroups: [],
            activeVideoGroup: {},
            topicId: 0,
            videoGroupId: 0,
            elementId: 0,
            elementType: 0,
        }
    },
    created() {
        this.init();

        if(window.innerWidth<900){
            this.isFullScreen = true;
        }

        var scoped = this;
        emitter.on('FullScreen', function (arg1) {
            scoped.isFullScreen = arg1;
        });
    },
    methods: {
        init() {
            this.topicId = this.$route.params.topicId;
            this.videoGroupId = this.$route.params.videoGroupId;
            this.getData();
        },
        changeVideoGroup(vdGroup) {
            if (this.activeVideoGroup == vdGroup)
                this.activeVideoGroup = null;
            else
                this.activeVideoGroup = vdGroup;
        },
        getData() {
            this.$center.emit('Loading', 'show');
            this.$http.get("Student/VideoGroup/" + this.topicId).then(x => {
                this.videoGroups = x.data.data;
                //console.log(this.videoGroups.filter(x => x.id == this.videoGroupId)[0]);
                if (this.activeVideoGroup != this.videoGroups.filter(x => x.id == this.videoGroupId)[0])
                    this.changeVideoGroup(this.videoGroups.filter(x => x.id == this.videoGroupId)[0]);
                this.$center.emit('Loading', 'hide');
            }).catch(error => {
                //alert(error);
                this.$center.emit('Loading', 'hide');
            });
        },
        getChildData(vdGroup) {
            if (vdGroup.documents != null)
                return vdGroup.documents;

            this.$http.get("Student/Priview/" + vdGroup.id).then(x => {
                //console.log(x.data.data);
                vdGroup.documents = x.data.data;
                return x.data.data;
            }).catch(error => {
                //alert(error);
                this.$center.emit('Loading', 'hide');
            });
        },
        getDocuemntType(type) {
            switch (type) {
                case "Extension":
                    return "far fa-abacus";
                case "Video":
                    return "far fa-play";
                case "Document":
                    return "far fa-file-alt";
                case "Test":
                    return "far fa-calculator-alt";
            }
        },
        setQuery(videoGroupId, elementId, elementType) {
       
            if (elementType != 'Test') {
                console.log('/watching/' + this.topicId + '/' + videoGroupId + '/' + elementType.toLowerCase() + '/' + elementId)
              //  this.$router.push('/watching/' + this.topicId + '/' + videoGroupId + '/' + elementType.toLowerCase() + '/' + elementId);
               window.location.href =('/watching/' + this.topicId + '/' + videoGroupId + '/' + elementType.toLowerCase() + '/' + elementId);
            } else {
                this.$router.push({
                    name: "ExamPlayer",
                    params: {
                        examId: elementId
                    }
                });
            }
        }
    }
}
</script>
