<script>
import { defineComponent } from 'vue'
import { Pie } from 'vue3-chart-v2'

export default defineComponent({
    props: ["label", "otherData", "myData"],
    name: 'MonthlyChart',
    extends: Pie,
    async mounted() {
        var options = {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: true,
                position: 'bottom'
            },
            scales: {
                xAxes: [{
                    display: false,
                    stacked: true
                }]
            }
        };

        var otherSum = this.otherData != null ? this.otherData.reduce((a, b) => a + b, 0) : 0;
        var mySum = this.myData != null ? this.myData.reduce((a, b) => a + b, 0) : 0;
        this.renderChart({
            labels: this.label,
            datasets: [{
                data: [otherSum, mySum],
                backgroundColor: ['#00E396', '#FF4560'],
                fill: true,
            }]
        }, options)
    }
})
</script>
