<template>
<div class="modal" style="display: none;">
    <theme-modal />
    <langue-modal />
    <add-reply-modal v-if="selectedModal==3" />
    <add-report-modal v-if="selectedModal==4" />
    <add-guidance-modal v-if="selectedModal==5" />
    <div class="overlay" id="modal-overlay"></div>
</div>
</template>

<script>
import ThemeModal from "./Modal/ThemeModal"
import LangueModal from "./Modal/LangueModal"
import AddReplyModal from "./Modal/AddReplyModal"
import AddReportModal from "./Modal/AddReportModal"
import AddGuidanceModal from './Modal/AddGuidanceModal.vue'
var emitter = require('tiny-emitter/instance');

export default {
    components: {
        "theme-modal": ThemeModal,
        "langue-modal": LangueModal,
        "add-reply-modal": AddReplyModal,
        "add-report-modal": AddReportModal,
        "add-guidance-modal": AddGuidanceModal
    },
    data() {
        return {
            selectedModal: 0
        }
    },
    methods: {
        closeModal() {
            emitter.emit('SettingsOpen', 'close');
        }
    },
    mounted() {
        var modalBody = document.getElementsByClassName("modal")[0];
        var themeModal = document.getElementsByClassName("theme-change")[0];
        var langueModal = document.getElementsByClassName("language-change")[0];
        //var addReplyModal = document.getElementsByClassName("question-answer-modal")[0];
        //var addReportModal = document.getElementsByClassName("report-modal")[0];
        //var addGuidanceModal = document.getElementsByClassName("add-guidance")[0];
        var overlay_modal = document.getElementById("modal-overlay");
        overlay_modal.addEventListener("click", this.closeModal);
        var scoped = this;
        emitter.on('SettingsOpen', function (arg1) {
            modalBody.style.display = 'none';
            themeModal.style.display = 'none';
            langueModal.style.display = 'none';
            scoped.selectedModal = 0;
            //addReplyModal.style.display = 'none';
            //addReportModal.style.display = 'none';
            //addGuidanceModal.style.display = 'none';
            overlay_modal.style.display = 'none';
            if (arg1 == "Theme") {
                modalBody.style.display = 'flex';
                themeModal.style.display = 'flex';
                overlay_modal.style.display = 'block';
            }
            if (arg1 == "Langue") {
                modalBody.style.display = 'flex';
                langueModal.style.display = 'flex';
                overlay_modal.style.display = 'block';
            }
            if (arg1 == "AddReply") {
                modalBody.style.display = 'flex';
                scoped.selectedModal = 3;
                //addReplyModal.style.display = 'flex';
                overlay_modal.style.display = 'block';
            }
            if (arg1 == "AddReport") {
                modalBody.style.display = 'flex';
                //addReportModal.style.display = 'flex';
                scoped.selectedModal = 4;
                overlay_modal.style.display = 'block';
            }
            if (arg1 == "AddGuidance") {
                modalBody.style.display = 'flex';
                //addGuidanceModal.style.display = 'flex';
                scoped.selectedModal = 5;
                overlay_modal.style.display = 'block';
            }
        });
    }
}
</script>
