<template>
<div class="content statistics">
    <div class="sidebar" style="left: 100%" id="statistics-filter">
        <div class="statistics-title">
            <i class="far fa-chevron-left" @click="openFilter()"></i>
            <h2>İstatistik Filtrele</h2>
        </div>
        <div class="statistics-filter">
            <div class="select-box">
                <h5>İstatistik Filtrele</h5>
                <label for="womanc">
                    <input type="radio" id="womanc" name="gender" :checked="type=='TURKEY'" @change="selectedType('TURKEY')" />
                    <span></span>
                    <p>Tüm Kurumlar</p>
                </label>
                <label for="woman">
                    <input type="radio" id="woman" name="gender" :checked="type=='COMPANY'" @change="selectedType('COMPANY')" />
                    <span></span>
                    <p>Kurum Geneli</p>
                </label>
                <label for="none">
                    <input type="radio" id="none" name="gender" :checked="type=='BRANCH'" @change="selectedType('BRANCH')" />
                    <span></span>
                    <p>Şube Geneli</p>
                </label>
                <label for="none">
                    <input type="radio" id="none" name="gender" :checked="type=='CLASS'" @change="selectedType('CLASS')" />
                    <span></span>
                    <p>Sınıf Geneli</p>
                </label>
            </div>
            <filter-area ref="filterComp" css_class="select-box" :changeUrl="false" @FilterExecute="filterExecute" />
        </div>
    </div>
    <div class="statistics-box" v-if="topicId=='00000000-0000-0000-0000-000000000000'">

        <div class="statistics-box-item sbi-xxl">
            <div class="statistics-box-item-box">
                <exams-chart style="width:100%" text="Doğru" successColor="#6900FF" errorColor="#85EB4E" v-if="!isLoading" :label="statisticsData.labels" :myData="statisticsData.myCorrectData" :otherData="statisticsData.otherCorrectData" />
            </div>
        </div>

        <!-- <div class="statistics-box-item sbi-l">
            <div class="statistics-box-item-box">
                <sum-exam-chart style="width:100%" :label="['Genel Doğru Ortalama Toplamı','Senin Doğru Toplamın']" v-if="!isLoading" :myData="statisticsData.myCorrectData" :otherData="statisticsData.otherCorrectData" />
            </div>
        </div>

        <div class="statistics-box-item sbi-l">
            <div class="statistics-box-item-box">
                <sum-exam-chart style="width:100%" :label="['Genel Yanlış Ortalama Toplamı','Senin Yanlış Toplamın']" v-if="!isLoading" :myData="statisticsData.myWrongData" :otherData="statisticsData.otherWrongData" />
            </div>
        </div> -->

        <div class="statistics-box-item sbi-xxl">
            <div class="statistics-box-item-box">
                <exams-chart style="width:100%" text="Yanlış" successColor="#6900FF" errorColor="#FF4560" v-if="!isLoading" :label="statisticsData.labels" :myData="statisticsData.myWrongData" :otherData="statisticsData.otherWrongData" />
            </div>
        </div>

        <div class="statistics-box-item sbi-xxl">
            <div class="statistics-box-item-box">
                <exams-chart style="width:100%" text="Boş" successColor="#6900FF" errorColor="#00BBF0" v-if="!isLoading" :label="statisticsData.labels" :myData="statisticsData.myEmptyData" :otherData="statisticsData.otherEmptyData" />
            </div>
        </div>

        <!-- <div class="statistics-box-item sbi-l">
            <div class="statistics-box-item-box">
                <sum-exam-chart style="width:100%" :label="['Genel Boş Ortalama Toplamı','Senin Boş Toplamın']" v-if="!isLoading" :myData="statisticsData.myEmptyData" :otherData="statisticsData.otherEmptyData" />
            </div>
        </div> -->

    </div>
    <div class="statistics-box" v-else>
        <div class="statistics-box-item sbi-xxl">
            <div class="statistics-box-item-box">
                <button type="submit" v-bind:class="selectedTest==item?'active':null" v-for="item in statisticsData.labels" :key="item" @click="testFilteredData(item)">{{item}}</button>
            </div>
        </div>
        <div class="statistics-box-item sbi-xxl">
            <div class="statistics-box-item-box">
                <topic-chart style="width:100%" v-if="!isLoading" :chartData="requestChartData" />
            </div>
        </div>
    </div>
    <div class="filter-open-button" @click="openFilter()">
        <i class="far fa-filter"></i>
    </div>
</div>
</template>

<script>
import ExamsChart from "./Statistics/ExamsChart"
import SumExamChart from "./Statistics/SumExamChart"
import TopicFilteredExamChart from "./Statistics/TopicFilteredExamChart"
import FilterComponent from "../components/FilterComponent.vue"
export default {
    components: {
        "exams-chart": ExamsChart,
        "sum-exam-chart": SumExamChart,
        "topic-chart": TopicFilteredExamChart,
        "filter-area": FilterComponent
    },
    data() {
        return {
            type: "COMPANY",
            lessonId: "00000000-0000-0000-0000-000000000000",
            childLessonId: "00000000-0000-0000-0000-000000000000",
            topicId: "00000000-0000-0000-0000-000000000000",
            videoGroupId: "00000000-0000-0000-0000-000000000000",
            statisticsData: [],
            isLoading: false,
            selectedTest: '',
            requestChartData: {}
        }
    },
    methods: {
        selectedType(type) {
            this.type = type;
        },
        openFilter() {
            var sideBar = document.getElementById("statistics-filter");
            if (sideBar.style.left == "100%") {
                sideBar.style.left = "0";
            } else if (sideBar.style.left == "0px") {
                sideBar.style.left = "100%";
            }
        },
        filterExecute(filter) {
            this.lessonId = "00000000-0000-0000-0000-000000000000";
            this.childLessonId = "00000000-0000-0000-0000-000000000000";
            this.topicId = "00000000-0000-0000-0000-000000000000";
            this.videoGroupId = "00000000-0000-0000-0000-000000000000";

            if (filter.lesson != 0)
                this.lessonId = filter.lesson;
            if (filter.childLesson != 0)
                this.childLessonId = filter.childLesson;
            if (filter.topic != 0)
                this.topicId = filter.topic;
            if (filter.videoGroup != 0)
                this.videoGroupId = filter.videoGroup;

            this.getFilteredData();

        },
        async getFilteredData() {
            this.$center.emit('Loading', 'show');
            this.isLoading = true;
            var reques = {
                "type": this.type,
                "lessonId": this.lessonId,
                "childLessonId": this.childLessonId,
                "topicId": this.topicId,
                "videoGroupId": this.videoGroupId
            };
            var statistics = await this.$http.post("Student/ExamStatistics", reques);
            this.statisticsData = statistics.data.data;
            this.isLoading = false;
            this.$center.emit('Loading', 'hide');
        },
        testFilteredData(text) {
            this.isLoading = true;
            this.$nextTick(function () {
                var index = this.statisticsData.labels.indexOf(text);
                this.selectedTest = text;
                this.requestChartData = {
                    label: text,
                    otherCorrect: this.statisticsData.otherCorrectData[index],
                    myCorrect: this.statisticsData.myCorrectData[index],
                    otherWrong: this.statisticsData.otherWrongData[index],
                    myWrong: this.statisticsData.myWrongData[index],
                    otherEmpty: this.statisticsData.otherEmptyData[index],
                    myEmpty: this.statisticsData.myEmptyData[index],
                };
                this.isLoading = false;
            });
        }
    }
}
</script>
