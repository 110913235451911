<template>
<div class="content">
    <div class="lessons">
        <div class="lesson-box lesson-box-1" id="lesson-view">
            <div class="lesson-box-pad">
                <div class="lesson-box-title">
                    <h2>{{$t("Lesson.LessonTitle")}}</h2>
                </div>
                <ul class="lesson-box-content">
                    <li v-for="lesson in lessons" :key="lesson.id" v-bind:class="activeLesson == lesson ? 'active' : null" @click="()=>{clearQuery();clearOtherPanel('lesson');getChildLesson(lesson);}">
                        <p>{{ lesson.name }}</p>
                        <span>
                            <span style="width: 58%"></span>
                        </span>
                    </li>
                </ul>
            </div>
        </div>

        <div class="lesson-box lesson-box-2" id="child-lesson-view">
            <div class="lesson-box-pad">
                <div class="lesson-box-title">
                    <i class="far fa-chevron-left" @click="closePanel('child')"></i>
                    <h2>{{ activeLesson.name || $t("Lesson.ChildTitle") }}</h2>
                </div>
                <ul class="lesson-box-content">
                    <li v-for="lesson in childLessons" :key="lesson.id" v-bind:class="activeChildLesson == lesson ? 'active' : null" @click="()=>{clearOtherPanel('childLesson');getTopic(lesson)}">
                        <p>{{ lesson.name }}</p>
                    </li>
                </ul>
            </div>
        </div>

        <div class="lesson-box lesson-box-3" id="topic-view">
            <div class="lesson-box-pad">
                <div class="lesson-box-title">
                    <i class="far fa-chevron-left" @click="closePanel('topic')"></i>
                    <h2>{{ activeChildLesson.name || $t("Lesson.TopicTitle") }}</h2>
                </div>
                <ul class="lesson-box-content">
                    <li v-for="topic in topics" :key="topic.id" v-bind:class="activeTopic.id == topic.id ? 'active' : null" @click="()=>{clearOtherPanel('topic');getVideoGroup(topic)}">
                        <p>{{ topic.name }}</p>
                    </li>
                </ul>
            </div>

            <!-- <div class="lesson-box-pad pad-2">
                <div class="lesson-box-title">
                    <h2>{{$t("Lesson.ComplateTopicTitle")}}</h2>
                </div>
                <ul class="lesson-box-content">
                    <li v-for="topic in lacksTopic" :key="topic.id" v-bind:class="activeTopic.id == topic.id ? 'active' : null" @click="()=>{clearOtherPanel('topic');getVideoGroup(topic)}">
                        <p>{{ topic.name }}</p>
                    </li>
                </ul>
            </div> -->
        </div>

        <div class="lesson-box lesson-box-4" id="video-view">
            <div class="lesson-box-pad">
                <div class="lesson-box-title">
                    <i class="far fa-chevron-left" @click="closePanel('video')"></i>
                    <h2>{{ activeTopic.name || $t("Lesson.VideoGroupTitle") }}</h2>
                </div>
                <ul class="lesson-box-content">
                    <li v-for="videoGroup in videoGroups" :key="videoGroup.id" v-bind:class="activeVideoGroup == videoGroup ? 'active' : null" @click="()=>{clearOtherPanel('videoGroup');getPriview(videoGroup)}">
                        <p>{{ videoGroup.name }}</p>
                    </li>
                </ul>
            </div>
        </div>

        <div class="lesson-box lesson-box-large lesson-box-5" id="preview-view">
            <div class="lesson-box-pad">
                <div class="lesson-box-title">
                    <i class="far fa-chevron-left" @click="closePanel('preview')"></i>
                    <h2>{{ activeVideoGroup.name || $t("Lesson.PreviewTitle")  }}</h2>
                </div>
                <ul class="lesson-box-content">
                    <li v-for="document in documents" :key="document.referanceId" @click="openWatching(document.type,document.referanceId)">
                        <i v-bind:class="getDocuemntType(document.type)" v-bind:style="{color: document.data!=null?'#17bf63':'#ff003a'}"></i>
                        <p>{{ document.name }}</p>
                        <div class="more" v-if="document.type == 'Test'">
                            <b title="Doğru Sayısı" style="color: #17bf63">{{document.data!=null?document.data.correct:0}}</b>
                            <b title="Yanlış Sayısı" style="color: #ff003a">{{document.data!=null?document.data.wrong:0}}</b>
                            <b title="Boş Sayısı" style="color: #888888">{{document.data!=null?document.data.empty:0}}</b>
                        </div>
                    </li>
                </ul>
            </div>

            <!-- <div class="lesson-box-pad pad-2">
                <div class="lesson-box-title">
                    <h2>{{$t("Lesson.CourseTopicTitle")}}</h2>
                </div>
                <ul class="lesson-box-content">
                    <li v-for="video in lacksVideo" :key="video.id" @click="(openWatching('Video',video.id))">
                        <p>{{video.name}}</p>
                    </li>
                </ul>
            </div> -->
        </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            lessons: [],
            activeLesson: {},
            childLessons: [],
            activeChildLesson: {},
            topics: [],
            activeTopic: [],
            videoGroups: [],
            activeVideoGroup: {},
            documents: [],
            lacksTopic: [],
            lacksVideo: []
        }
    },
    created() {
        this.getLessons();
    },
    methods: {
        clearOtherPanel(key) {
            if (key == "lesson") {
                this.childLessons = [];
                this.activeChildLesson = {};
            }
            if (key == "lesson" || key == "childLesson") {
                this.topics = [];
                this.activeTopic = {};
                this.lacksTopic = [];
            }
            if (key == "lesson" || key == "childLesson" || key == "topic") {
                this.videoGroups = [];
                this.activeVideoGroup = {};
            }
            if (key == "lesson" || key == "childLesson" || key == "topic" || key == "videoGroups") {
                this.documents = [];
            }
        },
        getLessons() {
            this.$center.emit('Loading', 'show');
            this.$http.get("Student/Lessons").then(x => {
                this.lessons = x.data.data;
                this.$center.emit('Loading', 'hide');
                this.getFilterDataCallMethod(this.lessons, "lesson", "getChildLesson");
            }).catch(error => {
                alert(error.response.data.error.message);
                this.$center.emit('Loading', 'hide');
            });
        },
        getChildLesson(lesson) {
            this.activeLesson = lesson;
            this.openPanel("child");
            this.childLessons = [];
            this.activeChildLesson = {};
            this.$center.emit('Loading', 'show');
            this.$http.get("Student/ChildLessons/" + lesson.id).then(x => {
                this.childLessons = x.data.data;
                this.$center.emit('Loading', 'hide');
                this.getFilterDataCallMethod(this.childLessons, "childLesson", "getTopic");
            }).catch(error => {
                //alert(error);
                this.$center.emit('Loading', 'hide');
            });
        },
        getTopic(childLesson) {
            this.activeChildLesson = childLesson;
            this.openPanel("topic");
            this.topics = [];
            this.activeTopic = {};
            this.$center.emit('Loading', 'show');
            this.$http.get("Student/Topics/" + childLesson.id).then(x => {
                this.topics = x.data.data;
                //this.getTopicLacks(childLesson);
                this.$center.emit('Loading', 'hide');
                this.getFilterDataCallMethod(this.topics, "topic", "getVideoGroup");
            }).catch(error => {
                //alert(error);
                this.$center.emit('Loading', 'hide');
            });
        },
        getTopicLacks(childLesson) {
            this.lacksTopic = [];
            this.$center.emit('Loading', 'show');
            this.$http.get("Student/LackTopic/" + childLesson.id).then(x => {
                this.lacksTopic = x.data.data;
                this.$center.emit('Loading', 'hide');
            }).catch(error => {
                //alert(error);
                this.$center.emit('Loading', 'hide');
            });
        },
        getVideoGroup(topic) {
            this.activeTopic = topic;
            this.openPanel("video");
            this.videoGroups = [];
            this.activeVideoGroup = {};
            this.$center.emit('Loading', 'show');
            this.$http.get("Student/VideoGroup/" + topic.id).then(x => {
                this.videoGroups = x.data.data;
                this.$center.emit('Loading', 'hide');
                this.getFilterDataCallMethod(this.videoGroups, "videoGroup", "getPriview");
            }).catch(error => {
                //alert(error);
                this.$center.emit('Loading', 'hide');
            });
        },
        getPriview(videoGroup) {
            this.activeVideoGroup = videoGroup;
            this.openPanel("preview");
            this.documents = [];
            this.$http.get("Student/Priview/" + videoGroup.id).then(x => {
                this.documents = x.data.data;
                this.urlReplace();
            }).catch(error => {
                //alert(error);
                this.$center.emit('Loading', 'hide');
            });
        },
        getVideoLacks(videoGroup) {
            this.lacksVideo = [];
            this.$center.emit('Loading', 'show');
            this.$http.get("Student/LackVideo/" + videoGroup.id).then(x => {
                this.lacksVideo = x.data.data;
                this.$center.emit('Loading', 'hide');
            }).catch(error => {
                //alert(error);
                this.$center.emit('Loading', 'hide');
            });
        },
        getDocuemntType(type) {
            switch (type) {
                case "Extension":
                    return "far fa-abacus";
                case "Video":
                    return "far fa-play";
                case "Document":
                    return "far fa-file-alt";
                case "Test":
                    return "far fa-calculator-alt";
            }
        },
        openWatching(elementType, elementId) {
            if (elementType != 'Test') {
                this.$router.push('/watching/' + this.activeTopic.id + '/' + this.activeVideoGroup.id + '/' + elementType.toLowerCase() + '/' + elementId);
            } else {
                this.$router.push({
                    name: "ExamPlayer",
                    params: {
                        examId: elementId
                    }
                });
            }
        },
        openPanel(index) {
            var element = null;
            switch (index) {
                case 'child':
                    element = document.getElementById("child-lesson-view");
                    break;
                case 'topic':
                    element = document.getElementById("topic-view");
                    break;
                case 'video':
                    element = document.getElementById("video-view");
                    break;
                case 'preview':
                    element = document.getElementById("preview-view");
                    break;
            }
            element.style.left = "0";
        },
        closePanel(index) {
            var element = null;
            switch (index) {
                case 'child':
                    element = document.getElementById("child-lesson-view");
                    break;
                case 'topic':
                    element = document.getElementById("topic-view");
                    break;
                case 'video':
                    element = document.getElementById("video-view");
                    break;
                case 'preview':
                    element = document.getElementById("preview-view");
                    break;
            }
            element.style.left = null;
        },
        clearQuery() {
            this.$router.replace({ query: "" });
        },
        urlReplace() {
            var query = {
                lesson: this.activeLesson.id,
                childLesson: this.activeChildLesson.id,
                topic: this.activeTopic.id,
                videoGroup: this.activeVideoGroup.id
            };
            this.$router.replace({ query: query });
        },
        getFilterDataCallMethod(data, filterKey, method) {
            var queryFilter = this.$route.query[filterKey];
            if (queryFilter != undefined) {
                var filterData = data.filter(x => x.id == queryFilter);
                if (filterData.length > 0) {
                    this[method](filterData[0]);
                }
            }
        }
    }
}
</script>
