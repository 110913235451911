<template>
<div class="content  "  style="overflow: auto;">
  <cddosonuc :cddodata="cddosonucjson" v-if="cddolength>0"  />
</div>
</template>
 

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
<script>
 import Cddosonuc from "./Survey/Cddosonuc.vue";

export default ({
  data(){
    return {
      
      cddosonucjson:{},
      cddolength:0
    }
  },    
  components: {
    "cddosonuc": Cddosonuc
  },
  setup() {
    
  },
  mounted() {
    this.getLesson();
    
  },
  methods:{
     async getLesson() {
            var surveyy = await this.$http.get("Student/GetCDDO");
            console.log(surveyy);
             this.cddosonucjson =surveyy.data.data.surveyGroups[0]
             this.cddolength=Object.keys(this.cddosonucjson).length;
            
      },
  }
})
</script>
  