<template>
<div class="auth">

    <div class="logo">
        <a href="#" v-if="appInfo.logoType=='image'">
            <img :src="appInfo.logo" alt="" />
        </a>
        <div class="title" v-if="appInfo.logoType=='text'">
            <h3>{{appInfo.name}}</h3>
        </div>
    </div>

    <div class="splash-screen">
        <img src="img/password.svg" alt="">
    </div>

    <div class="area">

        <div class="top">
            <h3>Parolanızı mı unuttunuz? 🔒</h3>
            <p>E-postanızı girin ve size şifrenizi sıfırlamanız için talimatlar gönderelim.</p>
        </div>

        <div class="box">
            <form @submit="onReset">
                <label>
                    <h5>E-posta adresiniz</h5>
                    <input type="mail" placeholder="E-posta adresinizi buraya girin" v-model="logReq.email">
                </label>
                <button type="submit">Gönder</button>
            </form>
        </div>

        <div class="bottom">
            <p>Giriş yapmak için <router-link to="login" tag="a"> burayı </router-link> kullanabilirsiniz.</p>
        </div>
    </div>

</div>
</template>

<script>
import appInfo from '@config';
export default {
    data() {
        return {
            logReq: {
                email: ""
            },
            appInfo
        }
    },
    methods: {
        onReset(e) {
            this.$center.emit('Loading', 'show');
            this.$http.post("Auth/reset_code", this.logReq).then(() => {
                this.$center.emit('Loading', 'hide');
                this.$router.push({ name: "Login" });
            }).catch(error => {
                alert(error);
                this.$center.emit('Loading', 'hide');
            });
            e.preventDefault();
        }
    }
}
</script>
