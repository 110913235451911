import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "./axios.js";
import VueCookies from "vue3-cookies";
import firebase from "firebase";
import "firebase/messaging";
import "firebase/firestore";
import "firebase/analytics";

const app = createApp(App);
var emitter = require("tiny-emitter/instance");
const firebaseConfig = {
  apiKey: "AIzaSyD6EwPwND-kDadsiNzVz2ACTFIEuuS66cs",
  authDomain: "yesapp-32708.firebaseapp.com",
  projectId: "yesapp-32708",
  storageBucket: "yesapp-32708.appspot.com",
  messagingSenderId: "839816422355",
  appId: "1:839816422355:web:c4fe38d9854cd4d3be4526",
  measurementId: "G-4P6QQC9JN2"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

navigator.serviceWorker.register('../firebase-messaging-sw.js', { scope: "./firebase-cloud-messaging-push-scope" }).then((register) => {
  if (firebase.messaging.isSupported()) {
    const messaging = firebase.messaging();
    messaging.useServiceWorker(register);
  }
}).catch(err => {
  console.log(err);
});

app.config.globalProperties.$analytics = firebase.analytics();

app.use(VueCookies);

app.config.globalProperties.$http = axios;
app.config.globalProperties.$center = emitter;

import appInfo from '@config';
axios.interceptors.request.use(config => {
  //config.withCredentials = true;
  config.decompress = true;
  config.headers["Authorization"] =
    "Bearer " + app.config.globalProperties.$cookies.get("Token");
  config.headers["Content-Type"] = "application/json";
  config.headers["ApiKey"] = appInfo.api_key;
  return config;
});


axios.interceptors.response.use(null, async error => {
  if (error.response.status == 401 || error.response.status == 423 || error.response.status == 403) {
    app.config.globalProperties.$cookies.remove("Token");
    app.config.globalProperties.$cookies.remove("UserToken");
    const appLoading = document.getElementById("loader");
    appLoading.style.display = "none";
    router.push({ name: "Login" });
    return Promise.reject("Oturum Sonlandırıldı");
  }
  return Promise.reject(error);
});

router.beforeEach((to, from, next) => {
  const userData = app.config.globalProperties.$cookies.get("Token");
  if (userData == null && to.meta.authRequire) {
    next("login");
  }
  if (to.meta.permission) {
    if (to.meta.permission != userData.userType) {
      next("error-404");
    }
  }
  firebase.analytics().logEvent("sayfa", { pageName: to.name });
  next();
});


//Langue
import i18n from './i18n.js'
app.use(i18n);

import VueApexCharts from "vue3-apexcharts";
app.use(VueApexCharts);

import "vue-neat-modal/dist/vue-neat-modal.css";

import vueVimeoPlayer from 'vue-vimeo-player'
app.use(vueVimeoPlayer);

import VCalendar from 'v-calendar';
app.use(VCalendar);

import Vue3Tour from 'vue3-tour'
import 'vue3-tour/dist/vue3-tour.css'
app.use(Vue3Tour);

import tooltip from "./directives/tooltip.js";
import "@/assets/tooltip.css";
app.directive("tooltip", tooltip);
app.config.productionTip = false

import Katex from 'vue-katex-auto-render'
app.directive('katex', Katex);

import VueA11yDialog from 'vue-a11y-dialog'
app.use(VueA11yDialog)
app.use(router);
app.mount("#app");
