<template>
<div class="alert" v-bind:class="getType()" v-bind:style="{left:ValueLeft+'%'}">
    <i :class="icon" v-if="!icon.startsWith('http')"></i>
    <img :src="icon" v-else />
    <div class="info">
        <h4>{{title}}</h4>
        <p>{{description}}</p>
    </div>
</div>
</template>

<script>
export default {
    props: {
        id: {
            type: Number,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        description: {
            type: String,
            required: true
        },
        icon: {
            type: String,
            default: 'fas fa-info'
        },
        type: {
            type: String,
            validator: function (value) {
                return ['success', 'danger', 'info', 'warning'].indexOf(value) !== -1
            },
            default: 'info'
        }
    },
    methods: {
        getType() {
            if (this.type == 'success') return 'alert-success';
            if (this.type == 'danger') return 'alert-danger';
            if (this.type == 'info') return 'alert-info';
            if (this.type == 'warning') return 'alert-warning';
        }
    },
    data() {
        return {
            ValueLeft: 0
        }
    },
    created() {
        var scoped = this;
        var interval = setInterval(function () {
            setInterval(function () {
                scoped.ValueLeft++;
                if (scoped.ValueLeft == 100) {
                    scoped.$emit("remove", scoped.id);
                    clearInterval(interval)
                }
            }, 1);
        }, 5000);
    }
}
</script>
