<template>
<div class="content quiz-detail-content" style="width:100%">

    <div class="main" @mouseenter="()=>{isFocus=true}" @mouseleave="()=>{isFocus=false}">
        <img :src="selectedQuestion.questionFile" alt="">
        <div class="options" v-if="selectedQuestion!=null && isFocus">
            <i class="fas fa-chevron-left" @click="prevQuestion" v-if="index>0"></i>
            <ul v-bind:style="{backgroundColor:getEmptyStatus(selectedQuestion)}">
                <li v-bind:style="{backgroundColor:getStatusColor(answer)}" v-for="answer in selectedQuestion.answers" :key="answer.id" @click="addExamAnswer(selectedQuestion.id,answer.id)">{{answer.text}}</li>
            </ul>
            <i class="fas fa-chevron-right" @click="nextQuestion" v-if="index < question?.length - 1"></i>
        </div>
    </div>

    <div class="sidebar">
        <ul class="lessons-collapse">
            <li v-bind:class="selectedLesson.id == lesson.id?'active':''" v-for="lesson in exam.lessons" :key="lesson.id" @click="getLessonQuestion(lesson)">{{lesson.name}}</li>
        </ul>
        <ul class="content">
            <li v-bind:style="{backgroundColor:getEmptyStatus(question)}" v-for="(question,index) in question" :key="question.id" @click="selectQuestion(index,question)">
                <p>{{(index+1)}}</p>
                <span v-bind:style="{backgroundColor:getStatusColor(answer)}" v-for="answer in question.answers" :key="answer.id" @click="addExamAnswer(question.id,answer.id)">{{answer.text}}</span>
            </li>
        </ul>
        <div class="close" @click="isOpen=true">
            <h3>{{ $t("ExamPlayer.EndExamButton") }}</h3>
        </div>
    </div>

    <Modal v-model="isOpen" :fullscreen="false" modal-transition="scale" :click-out="false" :disable-motion="false" max-width="500px" :remove-backdrop="false">
        <div class="theme-change">
            <div class="modal-header">
                <h3>{{ $t("ExamPlayer.Modal.Title") }}</h3>
            </div>
            <div class="con">
                <h4>{{ $t("ExamPlayer.Modal.Description") }}</h4>
                <div class="horver">
                    <span class="active" @click="isOpen=false">{{ $t("ExamPlayer.Modal.ContinueButton") }}</span>
                    <span class="active" @click="endExam()">{{ $t("ExamPlayer.Modal.EndExamButton") }}</span>
                </div>
            </div>
        </div>
    </Modal>

    <Modal v-model="isCheckType" :fullscreen="false" modal-transition="scale" :click-out="false" :disable-motion="false" max-width="700px" :remove-backdrop="false">
        <div class="theme-change">
            <div class="modal-header">
                <h3>{{ $t("ExamPlayer.Modal.Title") }}</h3>
            </div>
            <div class="con">
                <h4>Sınav Türü Tercihi</h4>
                <div class="horver">
                    <span v-bind:class="type.type == selectedType ? 'active' : null" v-for="type in types" :key="type.type" @click="selectedType=type.type">{{ type.label }}</span>
                </div>
              <button @click="checkExamTypeFilter()">Seç ve Başla</button>
            </div>
        </div>
    </Modal>

    <Modal v-model="isSolution" :fullscreen="false" modal-transition="scale" :click-out="true" max-width="960px" :disable-motion="false" :remove-backdrop="false">
        <div class="video-questions-modal">
            <ul>
                <li class="active">Videolu Cevap</li>
            </ul>
            <div class="question-box">
                <div class="video">
                    <vimeo-player ref="questionPlayer" v-if="isSolution" :video-url="selectedQuestion.solutionFile" />
                    <!-- <iframe v-if="isSolution" :src="selectedQuestion.solutionFile" frameborder="0" allow=" fullscreen; picture-in-picture" allowfullscreen></iframe> -->
                </div>
            </div>
        </div>
    </Modal>

</div>
</template>

<script>
import { Modal } from "vue-neat-modal";
var emitter = require('tiny-emitter/instance');
export default {
    components: {
        Modal,
    },
    data() {
        return {
            exam: [],
            selectedQuestion: {},
            selectedLesson: {},
            question: [],
            index: 0,
            isOpen: false,
            isSolution: false,
            isFocus: false,
            isCheckType:false,
            selectedType:null,
            types:[
                {
                    type:"sayisal",
                    label:"SAYISAL"
                },
                 {
                    type:"esit",
                    label:"EŞİT AĞIRLIK"
                },
                 {
                    type:"sozel",
                    label:"SÖZEL"
                }
            ]
        }
    },
    created() {
        this.getData();
        var scoped = this;
        emitter.on('ExamSolutionOpen', function () {
            scoped.isSolution = true;
        });
    },
    methods: {
        getEmptyStatus(question) {
            if (this.exam.status == "HasResulted") {
                var userAnswer = question.answers.filter(x => x.isSelected == true);
                if (userAnswer == null || userAnswer.length == 0)
                    return "#1597bb";
            }
        },
        getStatusColor(answer) {
            if (this.exam.status == "HasResulted") {
                if (answer.isTrue == true)
                    return "#17bf63";
                if (answer.isSelected == true && answer.isTrue == false)
                    return "#ff003a";
            } else {
                if (answer.isSelected == true)
                    return "#17bf63";
            }
        },
        async getData() {
            this.$center.emit('Loading', 'show');
            this.$http.get("Exam/Exam/" + this.$route.params.examId).then(x => {
                this.exam = x.data.data;
                if (this.exam.lessons != []) {
                    this.selectedLesson = this.exam.lessons[0];
                    this.getLessonQuestion(this.selectedLesson);
                }
                this.getexamType();
                var examData = {
                    title: this.exam.title,
                    time: this.exam.time,
                    status: this.exam.status
                };
                this.$center.emit('ExamDataChanged', examData);
                this.$center.emit('Loading', 'hide');
                
                if(this.exam.status!="HasResulted" && this.exam.title.includes("AYT")){
                    this.isCheckType = true;
                }
            }).catch(()=> {
                //alert(error);
                this.$center.emit('Loading', 'hide');
            });
        },
        checkExamTypeFilter(){
            this.isCheckType = false;
            if(this.selectedType=="sayisal"){
                this.exam.lessons= this.exam.lessons.filter(x=>x.name.includes("FEN") ||x.name.includes("MAT"));
            }
            if(this.selectedType == "sozel"){
                this.exam.lessons= this.exam.lessons.filter(x=>x.name.includes("EDEBİYAT") ||x.name.includes("SOSY"));
            }
            if(this.selectedType=="esit"){
                this.exam.lessons= this.exam.lessons.filter(x=>x.name.includes("EDEBİYAT") ||x.name.includes("MAT"));
            }
           this.selectedLesson = this.exam.lessons[0];
           this.getLessonQuestion(this.selectedLesson);
        },
        async getLessonQuestion(lesson) {
            this.$center.emit('Loading', 'show');
            this.selectedLesson = lesson;
            this.question = [];
            this.selectedQuestion = {};
            this.$http.get("Exam/Exam/" + this.$route.params.examId + "/" + lesson.id).then(x => {
                this.question = x.data.data;
                if (this.question != []) {
                    this.selectedQuestion = this.question[0];
                }
                this.$center.emit('Loading', 'hide');
            }).catch(() => {
                //alert(error);
                this.$center.emit('Loading', 'hide');
            });
        },
        getexamType() {
            if (this.exam.status == 'Closed') {
                this.$router.push({
                    name: "Exams"
                });
                this.$center.emit("alert", {
                    icon: 'far fa-check',
                    title: this.$t("ExamPlayer.ClosedNotifiTitle"),
                    description: this.$t("ExamPlayer.ClosedNotifiDescription"),
                    type: 'error'
                });
            }
        },
        selectQuestion(index, question) {
            this.index = index;
            this.selectedQuestion = question;
        },
        nextQuestion() {
            this.index++;
            this.selectedQuestion = this.question[this.index];
        },
        prevQuestion() {
            this.index--;
            this.selectedQuestion = this.question[this.index];
        },
        async addExamAnswer(questionId, answerId) {
            if (this.exam.status == 'Waiting') {
                var request = {
                    testId: this.exam.examId,
                    questionId: questionId,
                    answerId: answerId,
                    lessonId: this.selectedLesson.id
                };
                var addAnswer = await this.$http.post("Exam/AddExamAnswer", request);
                if (addAnswer.data.data == true) {
                    var oldAnswer = this.question.filter(x => x.id == questionId)[0].answers.filter(x => x.id == answerId)[0];
                    if (oldAnswer.isSelected == true) {
                        oldAnswer.isSelected = false;
                    } else {
                        this.question.filter(x => x.id == questionId)[0].answers.forEach(element => {
                            if (element.id == answerId)
                                element.isSelected = true;
                            else
                                element.isSelected = false;
                        });
                    }
                }
            }
        },
        async endExam() {
            this.isOpen = false;
            var endExam = await this.$http.get("Exam/EndExam/" + this.$route.params.examId);
            if (endExam.data.data == true) {
                this.$router.push({
                    name: "Exams"
                });
                this.$center.emit("alert", {
                    icon: 'far fa-check',
                    title: "Sınavınız Tamamlandı",
                    description: "Sonuçlarınız işlendiğinde haber verilecek",
                    type: 'success'
                });
            }
        }
    }
}
</script>

<style scoped>
.card {
    background-color: #fff;
    border-radius: 4px;
    padding: 24px;
}

.card h1 {
    margin-top: 0;
}
</style>
