<template>
<div class="content badges" style="overflow: auto;">

    <div class="badges-item" v-for="badge in badges" :key="badge.badge.id">
        <div class="cover">
            <img :src="badge.badge.imageUrl" alt="">
            <span class="progress" v-bind:style="badge.isReceive?'background: #17bf63;color:#fff':null">
                <i>{{badge.gain}}/{{badge.badge.gain}}</i>
            </span>
        </div>
        <div class="info">
            <h3>{{badge.badge.title}}</h3>
            <p>{{badge.badge.description}}</p>
        </div>
    </div>

</div>
</template>

<script>
export default {
    data() {
        return {
            badges: []
        }
    },
    created() {
        this.getData();
    },
    methods: {
        async getData() {
            this.$center.emit('Loading', 'show');
            var badges = await this.$http.get("Student/Badges");
            this.badges = badges.data.data;
            this.$center.emit('Loading', 'hide');
        }
    }
}
</script>
