<template>
<div class="question-answer-modal add-guidance" style="display: flex;">
    <div class="top">
        <i class="fas fa-chevron-left"></i>
        <h5>Yeni Randevu Talebi</h5>
    </div>
    <div class="conn">
        <div class="conn-item">
            <h6>Öğretmen:</h6>
            <ul>
                <li v-bind:class="addGuidenceRequest.teacherId==tc.id ?'active' : null" @click="addGuidenceRequest.teacherId=tc.id" v-for="tc in teacher" :key="tc.id">{{tc.firstName+" "+tc.lastName}}</li>
            </ul>
        </div>
        <div class="conn-item">
            <h6>Öncelik:</h6>
            <ul>
                <li v-bind:class="addGuidenceRequest.level==0 ?'active' : null" @click="addGuidenceRequest.level=0">Kritik</li>
                <li v-bind:class="addGuidenceRequest.level==1 ?'active' : null" @click="addGuidenceRequest.level=1">Yüksek</li>
                <li v-bind:class="addGuidenceRequest.level==2 ?'active' : null" @click="addGuidenceRequest.level=2">Orta</li>
                <li v-bind:class="addGuidenceRequest.level==3 ?'active' : null" @click="addGuidenceRequest.level=3">Düşük</li>
                <li v-bind:class="addGuidenceRequest.level==4 ?'active' : null" @click="addGuidenceRequest.level=4">Bilgilendirme</li>
            </ul>
        </div>
        <div class="conn-item">
            <h6>Görüşme Adı:</h6>
            <form>
                <input v-model="addGuidenceRequest.name" />
            </form>
        </div>
        <div class="conn-item">
            <h6>Görüşme Tarihi:</h6>
            <form>
                <v-date-picker v-model="addGuidenceRequest.startDate" mode="dateTime" is24hr :model-config="modelConfig" :min-date="minDate" :minute-increment="30" :min-time="10" :max-time="19">
                    <template v-slot="{ inputValue, inputEvents }">
                        <input class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300" :value="inputValue" v-on="inputEvents" />
                    </template>
                </v-date-picker>
            </form>
            <label>10:00 - 18:00 saatleri arası geçerlidir!</label>
            <label>{{status}}</label>
        </div>
        <button @click="addReply">Gönder</button>
    </div>
</div>
</template>

<script>
import appInfo from '@config';
export default {
    data() {
        return {
            selected: '',
            imageBase64: '',
            teacher: [],
            status: "",
            check: false,
            minDate: '',
            addGuidenceRequest: {
                teacherId: null,
                level: 2,
                startDate: null,
                duration: 30,
                name: ""
            },
            modelConfig: {
                type: 'string',
                mask: 'DD-MM-YYYY hh:mm',
            },
            appInfo
        }
    },
    created() {
        let date = new Date();
        date.setMinutes(0, 0, 0);
        this.minDate = date;
        //TODO : ADDTEACHER
        if (this.$cookies.get("Token") != null) {
            this.$http.get("Student/GuidanceTeacher").then(x => {
                this.teacher = x.data.data;
            }).catch(error => {
                alert(error.response.data.error.message);
            });
        }
    },
    watch: {
        'addGuidenceRequest.startDate': async function (neww) {
            if (neww != "") {
                console.log(neww);
                this.checkTeacherStatus();
            }
        },
        'addGuidenceRequest.teacherId': async function (neww) {
            this.checkTeacherStatus();
        }
    },
    methods: {
        changeDate(fullDate) {
            var date = fullDate.split(' ')[0];
            var time = fullDate.split(' ')[1];
            var splitDate = date.split('-');
            //2022-05-17T22:29:47.919Z
            return `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}T${time}:00.000Z`;
        },
        async checkTeacherStatus() {
            if (this.addGuidenceRequest.teacherId == null || this.addGuidenceRequest.startDate == null)
                return;

            try {
                var duration = this.addGuidenceRequest.startDate.split(':')[1];
                if (duration != "30" && duration != "00") {
                    this.status = "Saat/Dakika Seçiminizi Değiştirin!";
                    this.check = false;
                    return;
                }
            } catch {

            }

            this.status = "Sorgulama Yapılıyor...";
            var status = await this.$http.post("Student/CheckTeacher", {
                teacherId: this.addGuidenceRequest.teacherId,
                date: this.changeDate(this.addGuidenceRequest.startDate)
            });

            if (status.data.data) {
                this.status = "Bu tarih için randevu alabilirsiniz.";
            } else {
                this.status = "Öğretmeninizin bu tarihte başka bir görüşmesi var.";
            }

            this.check = status.data.data;
        },
        addReply() {
            this.checkTeacherStatus();
            if (this.check == false)
                return;
            this.$center.emit('Loading', 'show');
            console.log(this.addGuidenceRequest);
            this.addGuidenceRequest.startDate = this.changeDate(this.addGuidenceRequest.startDate);
            this.$http.post("Student/AddInterviews", this.addGuidenceRequest).then(() => {
                this.$center.emit('SettingsOpen', 'close');
                this.$center.emit('AddGuidance', 'close');
                this.$center.emit('alert', {
                    icon: 'far fa-check',
                    title: "Gönderildi",
                    description: "Talebiniz öğretmeninize iletildi.",
                    type: 'success'
                });
                this.check = false;
                this.status = "";
                this.$center.emit('Loading', 'hide');
            }).catch(() => {
                this.$center.emit('Loading', 'hide');
            })
        }
    }
}
</script>
