<template>
<div class="question-answer-modal report-modal" style="display: flex;">
    <div class="top">
        <i class="fas fa-chevron-left"></i>
        <h5>İstek/Hata Bildirimi</h5>
    </div>
    <div class="conn">
        <div class="conn-item">
            <h6>Tip:</h6>
            <ul>
 
                <li v-bind:class="selected=='Claim' ?'active' : null" @click="selected='Claim'">İstek</li>
                <li v-bind:class="selected=='Error' ?'active' : null" @click="selected='Error'">Hata</li>
                <li v-bind:class="selected=='Suggestion' ?'active' : null" @click="selected='Suggestion'">Öneri</li>
 
            </ul>
        </div>
        <div class="conn-item">
            <h6>Kısa açıklama (soruda bir hata var ise lütfen soru numarasını da belirtiniz):</h6>
            <form>
                <textarea v-model="definition"></textarea>
            </form>
        </div>
        <div class="conn-item">
            <h6>Ekran Görüntüsü</h6>
            <div class="conn-item-ul">
                <div class="conn-item-ul-li" @click="addImage">
                    <i class="far fa-plus"></i>
                    <input ref="fileInput" @change="handleImage" accept="image/*" type="file" style="display:none" />
                </div>
                <div class="conn-item-ul-li" v-if="imageBase64!=''">
                    <img :src="imageBase64" alt="">
                </div>
            </div>
        </div>
        <button @click="addReply">Gönder</button>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            selected: '',
            imageBase64: '',
            definition: '',
            selectedFile: {}
        }
    },
    methods: {
        getBase64(file) {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            var scoped = this;
            reader.onload = function () {
                scoped.imageBase64 = reader.result;
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
            };
            this.selectedFile = file;
        },
        addImage() {
            this.$refs.fileInput.click()
        },
        handleImage(e) {
            if (e.target.files == null)
                return;
            this.getBase64(e.target.files[0]);
        },
        addReply() {
            if(this.selected=='' || this.definition=='')
                return;
            var formData = new FormData();
            this.$center.emit('Loading', 'show');
            formData.append("type", this.selected);
            formData.append("definition", this.definition);
            formData.append("url", this.$route.fullPath);
            formData.append("file", this.selectedFile);
            this.$http.post("Student/AddContact", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(() => {
                this.$center.emit('SettingsOpen', 'close');
                this.$center.emit('alert', {
                    icon: 'far fa-check',
                    title: "Gönderildi",
                    description: "Mesajınızı Aldık En Kısa sürede Geri Dönüş Sağlayacağız",
                    type: 'success'
                });
                this.$center.emit('Loading', 'hide');
            }).catch(() => {
                this.$center.emit('Loading', 'hide');
            })

        }
    }
}
</script>
