<template>
<div class="item events">
    <div class="title">
        <h2>{{$t("Home.Events.Title")}}</h2>
    </div>
    <div class="con">
        <div class="flow">
            <div class="live" v-for="liveLesson in liveLessons.filter(x=>x.isLive==true)" :key="liveLesson.id">
                <div class="icon">
                    <i class="fas fa-video"></i>
                </div>
                <div class="left">
                    <h4>{{liveLesson.name}}</h4>
                    <p>{{liveLesson.definition}}</p>
                </div>
                <div class="right">
                    <button @click="openLiveLesson(liveLesson)">{{$t("Home.Events.JoinButton")}}</button>
                </div>
            </div>
            <div class="event" v-for="liveLesson in liveLessons.filter(x=>x.isLive==false)" :key="liveLesson.id">
                <div class="icon">
                    <i class="far fa-clock"></i>
                </div>
                <div class="info">
                    <h5>{{liveLesson.name}}</h5>
                    <p>{{liveLesson.startDate +' - '+liveLesson.endDate}}</p>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            liveLessons: [],
            timer: {}
        }
    },
    created() {
        this.getData();
    },
    methods: {
        async getData() {
            var liveLessons = await this.$http.get("Student/LiveLesson");
            this.liveLessons = liveLessons.data.data;
        },
        async openLiveLesson(liveLesson) {
            console.log(liveLesson);
            this.$http.get("Student/LiveLesson/" + liveLesson.id).then((data) => {
                window.open(data.data, "_blank");
            }).cache(() => {
                window.open(liveLesson.url, "_blank");
            });

        }
    },
    mounted() {
        var scoped = this;
        this.timer = setInterval(function () {
            scoped.getData();
        }, 180000);
    },
    unmounted() {
        clearInterval(this.timer);
    }
}
</script>
