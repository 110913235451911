<template>
<vue-cal :time="true" small resize-x resize-y :disable-views="['years']" :time-from="0 * 60" active-view="week" locale="tr" events-count-on-year-view="events-count-on-year-view" :events="events" :on-event-click="onEventClick">
    <template v-slot:no-event="">Bugün Etkinlik Yok 👌</template>
</vue-cal>
<Modal v-model="showDialog" :fullscreen="false" modal-transition="scale" :click-out="true" :disable-motion="false" max-width="500px" :remove-backdrop="false">
    <div class="theme-change" style="display: flex;max-width:500px">
        <div class="modal-header">
            <h3 class="title">{{selectedEvent.content}}</h3>
        </div>
        <div class="con">
            <h4>Başlama-Bitiş Tarihi</h4>
            <div class="horver">
                <span class="active">{{ selectedEvent.start && selectedEvent.start.format('DD/MM/YYYY HH:MM') }}</span>
                <span class="active">{{ selectedEvent.end && selectedEvent.end.format('DD/MM/YYYY HH:MM') }}</span>
            </div>
        </div>
    </div>
</Modal>
</template>

<script>
import VueCal from 'vue-cal'
import 'vue-cal/dist/i18n/tr.js'
import 'vue-cal/dist/vuecal.css'
import { Modal } from "vue-neat-modal";
export default {
    components: {
        VueCal,
        Modal
    },
    props: ["events"],
    data() {
        return {
            selectedEvent: {},
            showDialog: false,
        }
    },
    methods: {
        onEventClick(event, e) {
            console.log(event);
            this.selectedEvent = event
            this.showDialog = true

            // Prevent navigating to narrower view (default vue-cal behavior).
            e.stopPropagation()
        }
    }
}
</script>

<style>
.vuecal__event.live_lesson {
    background-color: rgba(253, 156, 66, 0.9);
    border: 1px solid rgb(233, 136, 46);
    color: #fff;
}

.vuecal__event.sport {
    background-color: rgba(255, 102, 102, 0.9);
    border: 1px solid rgb(235, 82, 82);
    color: #fff;
}

.title {
    color: var(--text-color-1);
}
</style>
