<template>
<div class="content reports">
    <div class="sidebar" style="left: 100%;">
        <div class="reports-title">
            <i class="far fa-chevron-left"></i>
            <h2>Tekrar İzle</h2>
        </div>
        <div class="reports-filter">
            <ul>
                <li>
                    <span style="flex:100%">{{selectedDate}}</span>
                    <v-date-picker v-model="selectedDate" :model-config="modelConfig">
                        <template v-slot="{ inputEvents }">
                            <i class="fad fa-clock" v-on="inputEvents"></i>
                        </template>
                    </v-date-picker>
                </li>
            </ul>
            <ul>
                <li v-for="live in livelessons" :key="live.id" @click="openPlayer(live)" v-bind:class="selected.id==live.id?'active':null">
                    <span> {{live.name}}</span>
                </li>
            </ul>
        </div>
    </div>
    <div class="main">
        <video controls :src="playUrl" v-if="playUrl!=null">
            Tarayıcınız Desteklemiyor
        </video>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            livelessons: [],
            playUrl: null,
            selected: {
                id: ''
            },
            selectedDate: new Date(),
            masks: {
                input: 'DD-MM-YYYY',
            },
            modelConfig: {
                type: 'string',
                mask: 'MM-DD-YYYY', // Uses 'iso' if missing
            },
        }
    },
    created() {
        this.getData(null);
    },
    watch: {
        selectedDate: async function (neww) {
            if (neww != "") {
                this.$center.emit('Loading', 'show');
                var livelessons = await this.$http.get("Student/ReWatch?date=" + neww);
                this.livelessons = livelessons.data.data;
                this.$center.emit('Loading', 'hide');
            }
        }
    },
    methods: {
        async getData() {
            this.$center.emit('Loading', 'show');
            var livelessons = await this.$http.get("Student/ReWatch");
            this.livelessons = livelessons.data.data;
            this.$center.emit('Loading', 'hide');
        },
        async openPlayer(liveLesson) {
            this.$center.emit('Loading', 'show');
            this.$http.get("Student/ReWatchPlayer/" + liveLesson.meetingId).then((resp) => {
                var data = resp.data.data;
                if (data.objects.length > 0) {
                    this.playUrl = data.objects[0].dataUrl;
                    this.selected = liveLesson;
                } else {
                    alert("Malesef Henüz Video Hazırlanmdı");
                }
            }).catch((error) => {
                console.log(error.response.data);
            }).finally(() => {
                this.$center.emit('Loading', 'hide');
            });

        }
    }
}
</script>

<style scoped>
video {
    display: flex;
    flex: 1;
}
</style>
