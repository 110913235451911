<template>
<div class="content targets">
    <div class="top">
        <h3>{{$t("Target.Title")}}</h3>
        <button @click="openAddTarget()">{{$t("Target.AddButton")}}</button>
    </div>

    <table>
        <tr>
            <th>#</th>
            <th>{{$t("Target.TableColumn.SchoolName")}}</th>
            <th>{{$t("Target.TableColumn.Faculty")}}</th>
            <th>{{$t("Target.TableColumn.Langue")}}</th>
            <th>{{$t("Target.TableColumn.City")}}</th>
            <th>{{$t("Target.TableColumn.Scholarship")}}</th>
            <th>{{$t("Target.TableColumn.Base")}}</th>
            <th>{{$t("Target.TableColumn.Top")}}</th>
            <th>Kontenjan</th>
            <th>{{$t("Target.TableColumn.Transactions")}}</th>
        </tr>

        <tr v-for="(target, index) in targets" :key="target.id">
            <td>#{{ index + 1 }}</td>
            <td>{{ target.school.name }}</td>
            <td>{{ target.school.faculty }}</td>
            <td>{{ target.school.langues }}</td>
            <td>{{ target.school.cities }}</td>
            <td>{{ target.school.scholarship }}</td>
            <td>{{ target.school.basePoint }}</td>
            <td>{{ target.school.topPoint }}</td>
            <td>{{ target.school.orderOfSuccess }}</td>
            <td>
                <i class="fas fa-info" @click="info(target.school)"></i>
                <i class="fad fa-trash" @click="remove( target.target.id)"></i>
            </td>
        </tr>
    </table>
    <Modal v-model="isOpen" :fullscreen="false" modal-transition="scale" :click-out="true" :disable-motion="false" max-width="480px" :remove-backdrop="false">
        <div class="target-detail">
            <div class="title">
                <i class="far fa-chevron-left"></i>
                <h3>{{selectedSchool.name}}</h3>
            </div>
            <ul>
                <li>
                    <h4>Fakülte:</h4>
                    <p>{{selectedSchool.faculty}}</p>
                </li>
                <li>
                    <h4>Eğitim Dili:</h4>
                    <p>{{selectedSchool.langues}}</p>
                </li>
                <li>
                    <h4>Şehir:</h4>
                    <p>{{selectedSchool.cities}}</p>
                </li>
                <li>
                    <h4>Burs Durumu:</h4>
                    <p>{{selectedSchool.scholarship}}</p>
                </li>
                <li>
                    <h4>Taban Puanı:</h4>
                    <p>{{selectedSchool.topPoint}}</p>
                </li>
                <li>
                    <h4>Tavan Puanı:</h4>
                    <p>{{selectedSchool.basePoint}}</p>
                </li>
                <li>
                    <h4>Kontenjan:</h4>
                    <p>{{selectedSchool.orderOfSuccess}}</p>
                </li>
            </ul>
        </div>
    </Modal>
</div>
</template>

<script>
import { Modal } from "vue-neat-modal";
export default {
    components: {
        Modal
    },
    data() {
        return {
            targets: [],
            selectedSchool: {},
            isOpen: false
        }
    },
    created() {
        this.getData();
    },
    methods: {
        async getData() {
            this.$center.emit('Loading', 'show');
            var targets = await this.$http.get("Student/Targets");
            this.targets = targets.data.data;
            this.$center.emit('Loading', 'hide');
        },
        openAddTarget() {
            this.$router.push({
                name: "AddTarget"
            });
        },
        info(school) {
            this.selectedSchool = school;
            this.isOpen = true;
        },
        async remove(targetId) {
            await this.$http.post("Student/RemoveTarget", {
                targetId
            });
            this.$center.emit('alert', {
                icon: 'far fa-check',
                title: this.$t("Target.Message.Title"),
                description: this.$t("Target.Message.SuccessRemove"),
                type: 'success'
            });
            this.getData();
        }
    }
}
</script>
